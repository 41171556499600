import * as SavaneJS from "../../node_modules/@rhinov/savane-js";

export interface MarkerJSON {
    x: number;
    y: number;
    color: string;
}

export class Marker {
    private _x: number;
    private _y: number;
    private _color: string;
    private _radius: number;


    constructor() {
        this._x = 0;
        this._y = 0;
        this._color = 'lime';
        this._radius = 40;
    }

    clone(_marker?: Marker) {
        var cloned = new Marker();
        cloned._x = this._x;
        cloned._y = this._y;
        cloned._color = this._color;
        cloned._radius = this._radius;

        return (cloned);
    }

    copyData(marker: Marker) {
        this._x = marker._x;
        this._y = marker._y;
        this._color = marker._color;
        this._radius = marker._radius;
    }

    get X(): number {
        return this._x;
    }

    set X(x: number) {
        this._x = x;
    }

    get Y(): number {
        return this._y;
    }

    set Y(y: number) {
        this._y = y;
    }

    get Color(): string {
        return this._color;
    }

    set Color(color: string) {
        this._color = color;
    }

    get Position(): SavaneJS.Math.glMatrix.vec2 {
        return SavaneJS.Math.glMatrix.vec2.fromValues(this._x, this._y);
    }

    Move(x: number, y: number) {
        this._x = x;
        this._y = y;
    }

    Draw(context: CanvasRenderingContext2D, zoom: number, active: boolean, pinned?: boolean) {
        context.beginPath();
        context.arc(this._x, this._y, this._radius / zoom, 0, 2 * Math.PI, false);
        context.strokeStyle = (pinned ? 'red' : active ? 'blue' : this._color);
        context.lineWidth = active ? 3 : 2;
        context.lineWidth /= zoom;
        context.stroke();
        context.beginPath();
        context.lineWidth = 1 / zoom;
        context.moveTo(this._x - this._radius / zoom, this._y);
        context.lineTo(this._x + this._radius / zoom, this._y);
        context.stroke();
        context.beginPath();
        context.moveTo(this._x, this._y - this._radius / zoom);
        context.lineTo(this._x, this._y + this._radius / zoom);
        context.stroke();
    }

    IsSelectable(x: number, y: number, zoom: number) {
        return Math.pow(x - this._x, 2) + Math.pow(y - this._y, 2) < Math.pow(this._radius / zoom, 2);
    }

    Distance(x: number, y: number) {
        var D = SavaneJS.Math.glMatrix.vec2.create();
        SavaneJS.Math.glMatrix.vec2.subtract(D, SavaneJS.Math.glMatrix.vec2.fromValues(x, y), SavaneJS.Math.glMatrix.vec2.fromValues(this._x, this._y));
        return SavaneJS.Math.glMatrix.vec2.length(D);
    }

    Serialize() {
        return {
            x: this._x,
            y: this._y,
            color: this._color
        }
    }

    Deserialize(json: MarkerJSON) {
        this._x = json.x;
        this._y = json.y;
        this._color = json.color;
    }
}
