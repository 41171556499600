import * as THREE from 'three';
import * as SavaneJS from '@rhinov/savane-js';

export class PartitionFactory {

    static Build(partitions: string | any[], material: THREE.MeshBasicMaterial, wireframeMaterial: THREE.LineBasicMaterial, selectedMaterial: THREE.MeshBasicMaterial) {
        if (partitions.length === 0) {
            return null;
        }

        var objects: THREE.Mesh[] = [];
        var wireframes: THREE.LineSegments[] = [];

        for (var i = 0; i < partitions.length; ++i) {
            var index = 0;
            
            var vertices = new Float32Array(6 * 3 * 6);
            var partition = partitions[i];

            var N = partition.N;
            var offset = SavaneJS.Math.glMatrix.vec3.fromValues(N[0] * partition.Thickness, N[1] * partition.Thickness, 0);

            var v1 = SavaneJS.Math.glMatrix.vec3.clone(partition.A);
            var v2 = SavaneJS.Math.glMatrix.vec3.clone(partition.A);
            v2[2] = partition.Height;
            var v3 = SavaneJS.Math.glMatrix.vec3.clone(partition.B);
            v3[2] = partition.Height;
            var v4 = SavaneJS.Math.glMatrix.vec3.clone(partition.B);

            //front partition
            vertices[index++] = v1[0]; vertices[index++] = v1[1]; vertices[index++] = v1[2];
            vertices[index++] = v3[0]; vertices[index++] = v3[1]; vertices[index++] = v3[2];
            vertices[index++] = v2[0]; vertices[index++] = v2[1]; vertices[index++] = v2[2];

            vertices[index++] = v3[0]; vertices[index++] = v3[1]; vertices[index++] = v3[2];
            vertices[index++] = v1[0]; vertices[index++] = v1[1]; vertices[index++] = v1[2];
            vertices[index++] = v4[0]; vertices[index++] = v4[1]; vertices[index++] = v4[2];
            
            var v1b = SavaneJS.Math.glMatrix.vec3.clone(partition.A);
            SavaneJS.Math.glMatrix.vec3.subtract(v1b, v1b, offset);
            var v2b = SavaneJS.Math.glMatrix.vec3.clone(partition.A);
            SavaneJS.Math.glMatrix.vec3.subtract(v2b, v2b, offset);
            v2b[2] = partition.Height;
            var v3b = SavaneJS.Math.glMatrix.vec3.clone(partition.B);
            SavaneJS.Math.glMatrix.vec3.subtract(v3b, v3b, offset);
            v3b[2] = partition.Height;
            var v4b = SavaneJS.Math.glMatrix.vec3.clone(partition.B);
            SavaneJS.Math.glMatrix.vec3.subtract(v4b, v4b, offset);

            //back partition
            vertices[index++] = v1b[0]; vertices[index++] = v1b[1]; vertices[index++] = v1b[2];
            vertices[index++] = v3b[0]; vertices[index++] = v3b[1]; vertices[index++] = v3b[2];
            vertices[index++] = v2b[0]; vertices[index++] = v2b[1]; vertices[index++] = v2b[2];

            vertices[index++] = v3b[0]; vertices[index++] = v3b[1]; vertices[index++] = v3b[2];
            vertices[index++] = v1b[0]; vertices[index++] = v1b[1]; vertices[index++] = v1b[2];
            vertices[index++] = v4b[0]; vertices[index++] = v4b[1]; vertices[index++] = v4b[2];

            //close front border
            vertices[index++] = v1[0]; vertices[index++] = v1[1]; vertices[index++] = v1[2];
            vertices[index++] = v2[0]; vertices[index++] = v2[1]; vertices[index++] = v2[2];
            vertices[index++] = v2b[0]; vertices[index++] = v2b[1]; vertices[index++] = v2b[2];

            vertices[index++] = v1[0]; vertices[index++] = v1[1]; vertices[index++] = v1[2];
            vertices[index++] = v2b[0]; vertices[index++] = v2b[1]; vertices[index++] = v2b[2];
            vertices[index++] = v1b[0]; vertices[index++] = v1b[1]; vertices[index++] = v1b[2];

            //close back border
            vertices[index++] = v4[0]; vertices[index++] = v4[1]; vertices[index++] = v4[2];
            vertices[index++] = v3[0]; vertices[index++] = v3[1]; vertices[index++] = v3[2];
            vertices[index++] = v3b[0]; vertices[index++] = v3b[1]; vertices[index++] = v3b[2];

            vertices[index++] = v4[0]; vertices[index++] = v4[1]; vertices[index++] = v4[2];
            vertices[index++] = v3b[0]; vertices[index++] = v3b[1]; vertices[index++] = v3b[2];
            vertices[index++] = v4b[0]; vertices[index++] = v4b[1]; vertices[index++] = v4b[2];

            var geometry = new THREE.BufferGeometry();
            geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
            objects.push(new THREE.Mesh(geometry, partition.selected ? selectedMaterial : material));

            var wireframeGeometry = new THREE.WireframeGeometry(geometry);
            wireframes.push(new THREE.LineSegments(wireframeGeometry, wireframeMaterial));
        }

        return {
            objects: objects,
            wireframes: wireframes
        }
    }

}
