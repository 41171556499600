import { IRootScope, IPosition, IOperation, IRouteParams } from "./UI/Externals";
import { PhotoSteps, StepOrder } from "./Photo2Savane/PhotoSteps";
import { Photo3D } from "./3D/Photo3D";
import { MarkerPair } from "./Photo2Savane/MarkerPair";
import { Marker } from "./Photo2Savane/Marker";
import * as SavaneJS from "../node_modules/@rhinov/savane-js";
import { ToolBarWidget } from "./UI/ToolBarWidget";
import { ModelSidebarWidget } from "./UI/ModelSidebarWidget";
import { JOINERY_TYPES } from "./joineryTypes";
import { QueueStep } from "./Photo2Savane/Step/QueueStep";
import { PairStep } from "./Photo2Savane/Step/PairStep";

declare var Savane;
declare var AssetManagerServices;

interface IModel {
    canopyNbPanel: number;
    dimensions: number;
    _id: string;
}

export class Photo2SavaneController {
    PHOTO2SAVANE_MODULE_PATH: string;
    scope: any;
    routeParams: IRouteParams;
    toastr: Toastr;
    rootScope: IRootScope;

    photos: any[];
    processes: PhotoSteps[];
    exterior: any;
    ctrlPressed: boolean;

    bodyClass: string;
    currentPageUrl: string;
    minimalHUD: boolean;
    currentStepInfo: string;
    goproIcon: string;
    grid: string;

    pageTitle: string;
    currentPageName: string;
    currentPageNameSubTitle: string;

    currentPhotoIndex: number;
    inside: boolean;

    hidePerspectiveHelpers: boolean;

    smartDrawPhotoEnabled: boolean;
    previousSmartDrawPhotoEnabled: boolean;

    private _currentImage?: HTMLImageElement;
    private _photo3D?: Photo3D;

    undoStack: IOperation[];
    redoStack: IOperation[];
    selectedObjectOriginalCopy?: Marker | MarkerPair;

    private _mouseImagePosition?: IPosition;
    private _canvasTransform: {
        scale: number;
        zoom: number;
        translation: {
            x: number;
            y: number;
        };
    };

    storedProcessPosition: {
        x: number;
        y: number;
    };

    OPTIMIZED_DB_FIELDS: string;

    joineryObject: {
        currentElementValue?: number;
        currentElementValue2?: number;
        currentElementValueItem?: string;
        currentElementValueMaterial?: string;
        currentElementValueThickness?: number;
        currentElementValueFrosted?: boolean;
        currentElementValueModel?: string;
        currentElementValueNbDoors?: number;
        currentElementValueSliding: boolean;
        currentElementValueRollingShutter: boolean;
        currentElementValueWallInstallType: number;
        currentElementValueOpened: boolean;
        currentElementValueTransom: boolean;
        currentElementValueTransomHeight: number;
        currentElementValueBottomTransom: boolean;
        currentElementValueBottomTransomHeight: number;
    };

    allModelsForCurrentJoinery: any;
    itemPreview: string;
    HP_CONFIG: any;
    COCOS_PLAN_MODULE_PATH: string;
    CurrentProjectService: any;
    savaneHttpManager: any;

    constructor(scope: object, routeParams: IRouteParams, toastr: Toastr,
        rootScope: IRootScope, HP_CONFIG: any, COCOS_PLAN_MODULE_PATH: string, CurrentProjectService: any, savaneHttpManager: any) {
        this.processes = [];
        this.PHOTO2SAVANE_MODULE_PATH = this.detectPhoto2SavanePath();

        this.scope = scope;
        this.routeParams = routeParams;
        this.CurrentProjectService = CurrentProjectService;
        this.toastr = toastr;
        this.rootScope = rootScope;
        this.savaneHttpManager = savaneHttpManager;
        this.HP_CONFIG = HP_CONFIG;
        this.COCOS_PLAN_MODULE_PATH = COCOS_PLAN_MODULE_PATH;

        this.photos = [];

        this.exterior = undefined;
        this.ctrlPressed = false;

        this.bodyClass = 'designer'; // Class for the body **required**
        this.currentPageUrl = '#/Photo2Savane';
        this.minimalHUD = false;
        this.currentStepInfo = "";
        this.goproIcon = this.PHOTO2SAVANE_MODULE_PATH + "img/gopro.png";
        this.grid = this.PHOTO2SAVANE_MODULE_PATH + "img/gridGuide.png";

        this.pageTitle = 'Rhinov > ' + 'Photo extraction';
        this.currentPageName = 'Photo extraction';
        this.currentPageNameSubTitle = 'Photo extraction';

        this.currentPhotoIndex = 0;
        this.inside = false;

        this.hidePerspectiveHelpers = false;
        this.draw = this.draw;

        this.smartDrawPhotoEnabled = parseInt(SavaneJS.SavaneCookie.getCookie("Rhinov-SmartDrawPhoto", "0")) === 0 ? false : true;
        this.previousSmartDrawPhotoEnabled = this.smartDrawPhotoEnabled;

        this._currentImage = undefined;
        this._photo3D = undefined;

        this.undoStack = [];
        this.redoStack = [];
        this.selectedObjectOriginalCopy = undefined;

        this._mouseImagePosition = undefined;
        this._canvasTransform = {
            scale: 1.0,
            zoom: 1.0,
            translation: {
                x: 0,
                y: 0
            }
        };

        this.storedProcessPosition = {
            x: 0,
            y: 0
        };

        // Optimization to get only the fields we want from the DB
        this.OPTIMIZED_DB_FIELDS = '&sort=created_at&fields=_id,joineryType,technicalElementType,pipeline.servicesStatus,pipeline.state,pipeline.servicesNeeded,pipeline.modelState,dimensions,canopyNbPanel';

        this.joineryObject = {
            currentElementValue: undefined,
            currentElementValue2: undefined,
            currentElementValueItem: JOINERY_TYPES && JOINERY_TYPES.length > 0 ? String(JOINERY_TYPES[0].value) : undefined,
            currentElementValueMaterial: undefined,
            currentElementValueThickness: undefined,
            currentElementValueFrosted: undefined,
            currentElementValueModel: undefined,
            currentElementValueNbDoors: 1,
            currentElementValueSliding: false,
            currentElementValueRollingShutter: false,
            currentElementValueWallInstallType: 1,
            currentElementValueOpened: false,
            currentElementValueTransom: false,
            currentElementValueTransomHeight: 250,
            currentElementValueBottomTransom: false,
            currentElementValueBottomTransomHeight: 0
        };
        this.loadJoineryAssets();

        this.startPhotosWatcher();
        this.startSmartDrawWatcher();
        this.initializeEvents();

        window.addEventListener("keyup", this.keyUpGlobal.bind(this));
        window.addEventListener("keydown", this.keyDownGlobal.bind(this));
        window.addEventListener('mousedown', this.timerTick.bind(this));
        window.addEventListener('wheel', this.timerTick.bind(this));

        this.updateRootScope();
        this.service();
        this.initProcessSavedListener();
        this.initPhotoTerminatedListener();
        this.initFinishPhoto2SavaneListener();
    }

    updateRootScope() {
        if (this.CurrentProjectService) {
            this.rootScope.isAuthenticateUser(true, function () {
                if (isNaN(this.routeParams.realPropertyId)) return;

                // Retrieve deliverable articles to know if we are before or after a CR
                this.CurrentProjectService.getArticles((this.routeParams.realPropertyId - 0), (this.routeParams.deliverableId - 0), function (_status: any, articles: any) {
                    if (!articles) return;
                    let routeParamsDeliverableId = this.routeParams.deliverableId;
                    let arts = articles.filter(function (article: { deliverables_id: number; customer_requests_id: number; payment_statuses_id: number; }) {
                        return article.deliverables_id === (routeParamsDeliverableId - 0) && article.customer_requests_id && article.payment_statuses_id === 1;
                    });
                    if (!arts.length) {
                        this.scope.isCR = false;
                        this.scope.isVisioDesignerCR = false;
                    } else {
                        if (arts.find(function (article: { customer_request: { customer_request_types_id: string; }; }) { return article.customer_request.customer_request_types_id === "CR_CLASSIC"; }) !== undefined) {
                            this.scope.isCR = true;
                        }
                        if (arts.find(function (article: { customer_request: { customer_request_types_id: string; }; }) { return article.customer_request.customer_request_types_id === "CR_VISIO_DESIGNER"; }) !== undefined) {
                            this.scope.isVisioDesignerCR = true;
                        }
                    }
                }.bind(this));

                //Retrieve project
                this.CurrentProjectService.get(this.routeParams.realPropertyId, undefined, undefined, function (_status: any, res: any) {
                    if (res === false) {
                        this.toastr.clear();
                        this.toastr.error('Impossible d\'initialiser l\'outils', 'Erreur');
                        return;
                    }
                    if (res.id === undefined) return;

                    //Find deliverable
                    for (let i = 0; i < res.deliverables.length; i++) {
                        if (res.deliverables[i].id === (this.routeParams.deliverableId - 0)) {
                            this.scope.deliverable = res.deliverables[i];
                            this.scope.isExterior = this.scope.deliverable.room_type === 73;
                        }
                    }


                    if (this.rootScope.userData.customers_groups_id === 11 && this.scope.deliverable && (this.scope.deliverable.deliverable_states.name != 'not_ordered' || res.creator == this.rootScope.userData.id)) {
                        this.scope.real_property = res;
                        //Retrieve photos to be proceed
                        this.CurrentProjectService.getPhotos(this.scope.real_property.id, this.routeParams.deliverableId, ["photo_types_id.eq=3"], function (_status: any, response: any) {
                            this.scope.photos = response;
                            if (this.scope.deliverable.rhinov_format) {
                                this.scope.world = SavaneJS.JSONImporter.importWorld(this.scope.deliverable.rhinov_format);
                            } else {
                                this.scope.world = Savane.EntityFactory.createWorld(-1);
                            }
                            for (let i = 0; i < this.scope.photos.length; ++i) {
                                let process: any;
                                //FIXME why do we get first deliverable from list ?? Should be using deliverableid from routeParams
                                if (this.scope.photos[i].photos_deliverables[0].photo_to_savane_data) {
                                    process = new PhotoSteps(0, 0, this.scope.photos[i].id);
                                    process.Deserialize(JSON.parse(this.scope.photos[i].photos_deliverables[0].photo_to_savane_data), this.scope.photos[i].id);
                                }
                                this.processes.push(process);
                            }
                        }.bind(this));
                    }
                }.bind(this));
            }.bind(this));
        } else {
            this.processes = [new PhotoSteps(this.scope.width, this.scope.height)];
            this.scope.world = this.scope.getWorld();
        }
    };

    service() {
        let project: any = {};

        project.patch = (deliverableId: string, photoID: string, data: object, callback: (arg0: boolean) => void) => {
            this.savaneHttpManager.patch(this.HP_CONFIG.APIUri + 'bo/deliverables/' + deliverableId + '/photos/' + photoID, data).then(function (res: { status: number; }) {
                if (res.status == 200) {
                    callback(true);
                } else {
                    callback(false);
                }
            }.bind(this));
        };

        return project;
    }

    detectPhoto2SavanePath() {
        let scripts = document.getElementsByTagName("script");
        for (let i = 0; i < scripts.length; ++i) {
            let s = scripts[i];
            if (s.src && s.src.includes('photo2Savane.min.js')) {
                return s.src.substring(0, s.src.lastIndexOf("/") + 1);
            }
        }
        return 'node_modules/@rhinov/photo2savane/';
    };

    magnetPosition(x: number, y: number) {
        let result = {
            x: x,
            y: y
        }

        let process = this.processes[this.currentPhotoIndex];
        if (!process) {
            return result;
        }

        if (!process.photo2savane.Photo2World.Magnet) {
            return result;
        }

        if (!process.CurrentStep) {
            return result;
        }

        if (!process.CurrentStep.SelectedElement) {
            return result;
        }

        return this.magnetMarker(x, y, process.CurrentStep.SelectedElement, process.CurrentStep.StepType);
    };

    resetTransfrom() {
        if (!this._photo3D) return;
        this._canvasTransform.zoom = 1.0;
        this._canvasTransform.translation = {
            x: 0,
            y: 0
        };

        this.draw();
        let process = this.processes[this.currentPhotoIndex];
        this._photo3D.Resize(process.Width * this._imageZoomFactor(), process.Height * this._imageZoomFactor());
        this._photo3D.Render(process.photo2savane);
    };

    magnetMarker(x: number, y: number, element: MarkerPair | Marker, stepType: string) {
        let result = {
            x: x,
            y: y
        }

        let process = this.processes[this.currentPhotoIndex];
        if (!process) {
            return result;
        }

        let magnetDistance = 10 / this._canvasTransform.zoom;
        let P: SavaneJS.math.vec3 = SavaneJS.Math.glMatrix.vec3.fromValues(x, y, 0);
        let nP: SavaneJS.math.vec3 | null = SavaneJS.Math.glMatrix.vec3.clone(P);
        let processStep = process.Steps[PhotoSteps.StepOrder.indexOf(stepType)];
        let step: QueueStep | PairStep;
        switch (stepType) {
            case PhotoSteps.StepType.Outline:
                step = processStep as QueueStep;
                let index = step.Markers.indexOf(element as unknown as Marker);
                if (index > 0 && index < step.Markers.length - 1) {
                    let previous = step.Markers[index - 1];
                    let next = step.Markers[index + 1];
                    let Fuline1 = new SavaneJS.Math.Line(SavaneJS.Math.glMatrix.vec3.fromValues(previous.X, previous.Y, 0), process.photo2savane.Photo2World.Fu);
                    let Fuline2 = new SavaneJS.Math.Line(SavaneJS.Math.glMatrix.vec3.fromValues(next.X, next.Y, 0), process.photo2savane.Photo2World.Fu);
                    let Fvline1 = new SavaneJS.Math.Line(SavaneJS.Math.glMatrix.vec3.fromValues(previous.X, previous.Y, 0), process.photo2savane.Photo2World.Fv);
                    let Fvline2 = new SavaneJS.Math.Line(SavaneJS.Math.glMatrix.vec3.fromValues(next.X, next.Y, 0), process.photo2savane.Photo2World.Fv);
                    if (Fuline1.Distance(P) < magnetDistance) {
                        nP = Fuline1.Project(P);
                    }
                    if (Fuline2.Distance(P) < magnetDistance) {
                        nP = Fuline2.Project(P);
                    }
                    if (Fvline1.Distance(P) < magnetDistance) {
                        nP = Fvline1.Project(P);
                    }
                    if (Fvline2.Distance(P) < magnetDistance) {
                        nP = Fvline2.Project(P);
                    }
                    if (Fuline1.Distance(P) < magnetDistance && Fvline2.Distance(P) < magnetDistance) {
                        nP = Fuline1.Intersect(Fvline2);
                    }
                    if (Fuline2.Distance(P) < magnetDistance && Fvline1.Distance(P) < magnetDistance) {
                        nP = Fuline2.Intersect(Fvline1);
                    }
                }
                if (index === 0 && step.Markers.length > 1) {
                    let next = step.Markers[index + 1];
                    let Fuline = new SavaneJS.Math.Line(SavaneJS.Math.glMatrix.vec3.fromValues(next.X, next.Y, 0), process.photo2savane.Photo2World.Fu);
                    let Fvline = new SavaneJS.Math.Line(SavaneJS.Math.glMatrix.vec3.fromValues(next.X, next.Y, 0), process.photo2savane.Photo2World.Fv);
                    if (Fuline.Distance(P) < magnetDistance) {
                        nP = Fuline.Project(P);
                    }
                    if (Fvline.Distance(P) < magnetDistance) {
                        nP = Fvline.Project(P);
                    }
                    if (Fuline.Distance(P) < magnetDistance && Fvline.Distance(P) < magnetDistance) {
                        nP = Fuline.Intersect(Fvline);
                    }
                }
                if (index === step.Markers.length - 1 && step.Markers.length > 1) {
                    let previous = step.Markers[index - 1];
                    let Fuline = new SavaneJS.Math.Line(SavaneJS.Math.glMatrix.vec3.fromValues(previous.X, previous.Y, 0), process.photo2savane.Photo2World.Fu);
                    let Fvline = new SavaneJS.Math.Line(SavaneJS.Math.glMatrix.vec3.fromValues(previous.X, previous.Y, 0), process.photo2savane.Photo2World.Fv);
                    if (Fuline.Distance(P) < magnetDistance) {
                        nP = Fuline.Project(P);
                    }
                    if (Fvline.Distance(P) < magnetDistance) {
                        nP = Fvline.Project(P);
                    }
                    if (Fuline.Distance(P) < magnetDistance && Fvline.Distance(P) < magnetDistance) {
                        nP = Fuline.Intersect(Fvline);
                    }
                }
                break;
            case PhotoSteps.StepType.OutlinePartition:
                if (element instanceof MarkerPair && element.SelectMode === 'B') {
                    if (!element.A || !element.B) throw new Error('Element A or B is undefined');
                    let Fuline = new SavaneJS.Math.Line(SavaneJS.Math.glMatrix.vec3.fromValues(element.A.X, element.A.Y, 0), process.photo2savane.Photo2World.Fu);
                    let Fvline = new SavaneJS.Math.Line(SavaneJS.Math.glMatrix.vec3.fromValues(element.A.X, element.A.Y, 0), process.photo2savane.Photo2World.Fv);
                    if (Fuline.Distance(P) < magnetDistance) {
                        nP = Fuline.Project(P);
                    }
                    if (Fvline.Distance(P) < magnetDistance) {
                        nP = Fvline.Project(P);
                    }

                    let magnetLine1 = new SavaneJS.Math.Line(Fuline.A, Fuline.B);
                    let magnetLine2 = new SavaneJS.Math.Line(Fvline.A, Fvline.B);
                    nP = process.photo2savane.Photo2World.PartitionFactory.Magnetize(nP, magnetLine1, magnetLine2);
                }

                if (element instanceof MarkerPair && element.SelectMode === 'A') {
                    if (!element.A || !element.B) throw new Error('Element A or B is undefined');
                    let Fuline = new SavaneJS.Math.Line(SavaneJS.Math.glMatrix.vec3.fromValues(element.B.X, element.B.Y, 0), process.photo2savane.Photo2World.Fu);
                    let Fvline = new SavaneJS.Math.Line(SavaneJS.Math.glMatrix.vec3.fromValues(element.B.X, element.B.Y, 0), process.photo2savane.Photo2World.Fv);
                    if (Fuline.Distance(P) < magnetDistance) {
                        nP = Fuline.Project(P);
                    }
                    if (Fvline.Distance(P) < magnetDistance) {
                        nP = Fvline.Project(P);
                    }

                    let magnetLine1 = new SavaneJS.Math.Line(Fuline.A, Fuline.B);
                    let magnetLine2 = new SavaneJS.Math.Line(Fvline.A, Fvline.B);
                    nP = process.photo2savane.Photo2World.PartitionFactory.Magnetize(nP, magnetLine1, magnetLine2);
                }
                break;
            case PhotoSteps.StepType.Joineries:
            case PhotoSteps.StepType.JoineriesPartition:
                step = processStep as PairStep;
                for (let i = 0; i < step.Pairs.length; ++i) {
                    let e = step.Pairs[i];
                    if (e === element) {
                        continue;
                    }

                    if (!e.A || !e.B) throw new Error('Element A or B is undefined');
                    if (SavaneJS.Math.glMatrix.vec2.distance(SavaneJS.Math.glMatrix.vec2.fromValues(e.A.X, e.A.Y), P as SavaneJS.Math.glMatrix.vec2) < magnetDistance) {
                        nP[0] = e.A.X; nP[1] = e.A.Y;
                    } else if (SavaneJS.Math.glMatrix.vec2.distance(SavaneJS.Math.glMatrix.vec2.fromValues(e.B.X, e.B.Y), P as SavaneJS.Math.glMatrix.vec2) < magnetDistance) {
                        nP[0] = e.B.X; nP[1] = e.B.Y;
                    }
                }
                break;
            default:
                return result;
        }
        if (!nP) return;
        result.x = nP[0];
        result.y = nP[1];

        return result;
    };

    /**
     * Validate the process
     * Reset the scene, then validate every process
     * Dispatch termination event
     */
    validateProcess() {
        let process = this.processes[this.currentPhotoIndex];
        //last photo clear and rebuild all
        if (this.currentPhotoIndex === this.processes.length - 1) {
            //Clear scene
            this.scope.world.children = [];
            for (let i = 0; i < this.currentPhotoIndex + 1; ++i) {
                if (!this.processes[i].Invalidate) {
                    this.processes[i].ValidateStep(this._imageZoomFactor());
                    this.processes[i].BuildSavane(this.scope.world, this.exterior, function () {
                        this.saveProcess();
                        let deliverablePatchObject = {
                            rhinov_format: SavaneJS.JSONSerializer.serializeEntity(this.scope.world)
                        };

                        Savane.eventsManager.instance.dispatch("FINISH_PHOTO2SAVANE", deliverablePatchObject);
                    }.bind(this));
                }
            }
        } else {
            process.ValidateStep(this._imageZoomFactor());
        }

        if (this.currentPhotoIndex + 1 < this.processes.length) {
            this.setCurrentPhoto(this.currentPhotoIndex + 1)
            ToolBarWidget.instance?.updateToolBarWidget();
        }
        else {
            Savane.eventsManager.instance.dispatch("PHOTO_TERMINATED", this.scope.world);
        }
    };

    /***
     * Check if process is done or skipped
     *
     * @returns {*}
     */
    isProcessEnded(): boolean {
        let process = this.processes[this.currentPhotoIndex];
        return process ? process.Finished : false;
    };

    /**
     * Clean process and restart
     */
    cleanProcess() {
        bootbox.confirm('Le travail déjà effectué va être entièrement perdu. Etes-vous vraiment sûr(e) ?', () => {
            if (!this._currentImage) return;
            let process = new PhotoSteps(this._currentImage.width, this._currentImage.height);
            //First time we enter this proces init it
            this.processes[this.currentPhotoIndex] = process;
            this.setCurrentPhoto(this.currentPhotoIndex);
            this.closeSideBar();
            ToolBarWidget.instance?.updateToolBarWidget();
        });
    };

    clearOffsets() {
        let process = this.processes[this.currentPhotoIndex];
        process.photo2savane.Photo2World.Clear();
        this.updatePhoto2Savane();
    };

    updatePhoto2Savane() {
        let process = this.processes[this.currentPhotoIndex];
        if (!process) return;

        let canvas = document.getElementById('picture-canvas');
        if (!canvas) return;

        let step = 0;
        if (process.CurrentStep) {
            step = PhotoSteps.StepOrder.indexOf(process.CurrentStep.StepType);
            process.UpdateStep(this._imageZoomFactor());
        } else {
            step = PhotoSteps.StepOrder.length;
        }

        //Rebuild 3D
        if (this._photo3D) {
            let context = (canvas as HTMLCanvasElement).getContext("2d");
            if (context) {
                context.save();
            }
            if (!this._currentImage) return;
            let tx = (canvas.clientWidth / 2 + this._canvasTransform.translation.x) - (this._currentImage.width * this._imageZoomFactor()) / 2;
            let ty = (canvas.clientHeight / 2 + this._canvasTransform.translation.y) - (this._currentImage.height * this._imageZoomFactor()) / 2;
            if (context) {
                context.transform(this._imageZoomFactor(), 0, 0, this._imageZoomFactor(), tx, ty);
            }
            if (context) {
                this._photo3D.Rebuild(process.photo2savane, step, context, this._imageZoomFactor());
            }
            this._photo3D.Render(process.photo2savane);
            if (context) {
                context.restore();
            }
        }
    }

    mouseToImage(canvas: HTMLElement, x: number, y: number): IPosition | undefined {
        //First translate to center of canvas
        if (!canvas) return;
        let convertedX = x - (canvas.clientWidth / 2 + this._canvasTransform.translation.x);
        let convertedY = y - (canvas.clientHeight / 2 + this._canvasTransform.translation.y);
        //Then translate to topLeft corner of image
        if (!this._currentImage) return;
        convertedX += (this._currentImage.width * this._imageZoomFactor()) * 0.5;
        convertedY += (this._currentImage.height * this._imageZoomFactor()) * 0.5;
        //Then scale to real image coord
        convertedX /= this._imageZoomFactor();
        convertedY /= this._imageZoomFactor();
        return {
            x: convertedX,
            y: convertedY
        };
    };

    drawLineAlong(context: CanvasRenderingContext2D, point: Float32Array<ArrayBufferLike> | number[], vanishingPoint: Float32Array<ArrayBufferLike> | number[], color: string) {
        if ((point[0] === Infinity) || (point[1] === Infinity)) return;

        if ((vanishingPoint[0] === Infinity) || (vanishingPoint[1] === Infinity)) return;

        let direction = SavaneJS.Math.glMatrix.vec2.create();
        SavaneJS.math.vec2.set(direction, (vanishingPoint[0] - point[0]) * 800, (vanishingPoint[1] - point[1]) * 800);
        while ((Math.abs(direction[0]) / 2 > context.canvas.clientWidth) && (Math.abs(direction[1]) / 2 > context.canvas.clientHeight)) {
            direction[0] /= 2;
            direction[1] /= 2;
        }

        context.beginPath();
        context.moveTo(point[0] - direction[0], point[1] - direction[1]);
        context.lineTo(point[0] + direction[0], point[1] + direction[1]);
        context.strokeStyle = color;
        context.lineWidth = 1 / this._canvasTransform.zoom;
        context.stroke();
        context.closePath();
    };

    drawHelpers(context: CanvasRenderingContext2D) {
        let process = this.processes[this.currentPhotoIndex];
        if (!process) return;

        if (!process.CurrentStep) return;

        if (!process.CurrentStep.ActiveElement) return;

        let Fu = process.photo2savane.Photo2World.VanishingPointsInverted ? process.photo2savane.Photo2World.Fv : process.photo2savane.Photo2World.Fu;
        let Fv = process.photo2savane.Photo2World.VanishingPointsInverted ? process.photo2savane.Photo2World.Fu : process.photo2savane.Photo2World.Fv;
        switch (process.CurrentStep.StepType) {
            case PhotoSteps.StepType.Camera:
                if (!this.hidePerspectiveHelpers) {
                    if (process.CurrentStep instanceof PairStep) {
                        this.drawLineAlong(context, process.photo2savane.Photo2World.Fu, process.photo2savane.Photo2World.Fv, "#ff00ff");
                        this.drawLineAlong(context, process.CurrentStep.Pairs[0].A.Position, Fu, "#00b7eb");
                        this.drawLineAlong(context, process.CurrentStep.Pairs[1].A.Position, Fu, "#00b7eb");
                        this.drawLineAlong(context, process.CurrentStep.Pairs[2].A.Position, Fv, "#ff4814");
                        this.drawLineAlong(context, process.CurrentStep.Pairs[3].A.Position, Fv, "#ff4814");
                    }
                }
                break;
            case PhotoSteps.StepType.Outline:
                if (process.CurrentStep instanceof QueueStep) {
                    if (process.CurrentStep.Markers.length < 2) return;
                    let index = process.CurrentStep.Markers.indexOf(process.CurrentStep.ActiveElement);
                    if (index > 0) {
                        this.drawLineAlong(context, process.CurrentStep.Markers[index].Position, Fv, "#ffff00");
                        this.drawLineAlong(context, process.CurrentStep.Markers[index].Position, Fu, "#00ffff");
                        this.drawLineAlong(context, process.CurrentStep.Markers[index - 1].Position, Fv, "#ffff00");
                        this.drawLineAlong(context, process.CurrentStep.Markers[index - 1].Position, Fu, "#00ffff");
                    } else {
                        this.drawLineAlong(context, process.CurrentStep.Markers[1].Position, Fv, "#ffff00");
                        this.drawLineAlong(context, process.CurrentStep.Markers[1].Position, Fu, "#00ffff");
                    }
                    if (index < process.CurrentStep.Markers.length - 1) {
                        this.drawLineAlong(context, process.CurrentStep.Markers[index + 1].Position, Fv, "#ffff00");
                        this.drawLineAlong(context, process.CurrentStep.Markers[index + 1].Position, Fu, "#00ffff");
                    }
                }
                break;
            case PhotoSteps.StepType.OutlinePartition:
                if (process.CurrentStep instanceof PairStep) {
                    if (process.CurrentStep.ActiveElement.SelectMode === 'B') {
                        this.drawLineAlong(context, process.CurrentStep.ActiveElement.A.Position, Fv, "#ffff00");
                        this.drawLineAlong(context, process.CurrentStep.ActiveElement.A.Position, Fu, "#00ffff");
                    }

                    if (process.CurrentStep.ActiveElement.SelectMode === 'A') {
                        this.drawLineAlong(context, process.CurrentStep.ActiveElement.B.Position, Fv, "#ffff00");
                        this.drawLineAlong(context, process.CurrentStep.ActiveElement.B.Position, Fu, "#00ffff");
                    }
                }
                break;
        }
    };

    draw() {
        if (!this._currentImage) return;

        let process = this.processes[this.currentPhotoIndex];
        if (!process) return;

        let canvas = document.getElementById('picture-canvas');
        if (!canvas) return;

        let context = (canvas as HTMLCanvasElement).getContext("2d");
        if (!context) return;
        //Clear canvas
        context.resetTransform();
        context.fillStyle = "#00000";
        context.fillRect(0, 0, canvas.clientWidth, canvas.clientHeight);
        context.save();

        //Draw picture (from center to simplify translation and zoom handling)
        let tx = (canvas.clientWidth / 2 + this._canvasTransform.translation.x) - (this._currentImage.width * this._imageZoomFactor()) / 2;
        let ty = (canvas.clientHeight / 2 + this._canvasTransform.translation.y) - (this._currentImage.height * this._imageZoomFactor()) / 2;
        let imagePosition = this.mouseToImage(canvas, tx, ty)
        if (this._photo3D) {
            if (!imagePosition) return;
            this._photo3D.Translate(imagePosition.x + tx, - imagePosition.y + ty);
        }
        context.transform(this._imageZoomFactor(), 0, 0, this._imageZoomFactor(), tx, ty);
        context.drawImage(this._currentImage, 0, 0, this._currentImage.width, this._currentImage.height);
        this.drawHelpers(context);
        process.Draw(context, this._imageZoomFactor());
        context.restore();
    };

    _imageZoomFactor() {
        return this._canvasTransform.scale * this._canvasTransform.zoom;
    };

    setCurrentPhoto(index: number) {
        if (this.processes.length === 0) return;
        this.currentPhotoIndex = index;

        let process = this.processes[this.currentPhotoIndex];

        //Reset canvas to initial state
        this._canvasTransform = {
            scale: 0.1,
            zoom: 1.0,
            translation: {
                x: 0,
                y: 0
            }
        };

        //Load photos
        let currentPhoto = this.photos[this.currentPhotoIndex];
        if (currentPhoto !== undefined && currentPhoto !== undefined) {
            this._currentImage = new Image();
            if (!this._currentImage) return;
            this._currentImage.crossOrigin = 'Anonymous';//Mandatory to be allowed to draw in canvas
            if (currentPhoto.path !== undefined && currentPhoto.path !== undefined &&
                currentPhoto.filename !== undefined && currentPhoto.filename !== undefined) {
                this._currentImage.src = currentPhoto.path + currentPhoto.filename;
            }
            else {
                this._currentImage.src = currentPhoto;
            } this._currentImage.onload = () => {
                //resize canvas and context
                let canvas = document.getElementById('picture-canvas') as HTMLCanvasElement;
                if (!canvas) return;

                let height = canvas.clientHeight;
                let width = canvas.clientWidth;

                let context = canvas.getContext("2d");
                if (!context) return;
                canvas.width = width;
                const contextWidth = canvas.clientWidth;
                const contextHeight = canvas.clientHeight;
                canvas.height = height;

                //compute image scale to fit viewport
                if (!this._currentImage) return;
                if ((this._currentImage.width / contextWidth) > (this._currentImage.height / contextHeight)) {
                    this._canvasTransform.scale = contextWidth / this._currentImage.width;
                } else {
                    this._canvasTransform.scale = contextHeight / this._currentImage.height;
                }

                if (!process) {
                    process = new PhotoSteps(this._currentImage.width, this._currentImage.height, this.photos[index].id);
                    this.processes[this.currentPhotoIndex] = process;
                }

                this._canvasTransform.scale = Math.min(
                    canvas.clientWidth / this._currentImage.width,
                    canvas.clientHeight / this._currentImage.height
                );

                //Init threejs renderer
                if (!this._photo3D) {
                    let container = document.getElementById('photo2savane-canvas-container');
                    if (container)
                        this._photo3D = new Photo3D(process.Width * this._canvasTransform.scale, process.Height * this._canvasTransform.scale, container);
                }

                this._photo3D?.Resize(process.Width * this._imageZoomFactor(), process.Height * this._imageZoomFactor());
                this.initStepValue();
                this.draw();
                this.updatePhoto2Savane();
                ToolBarWidget.instance?.updatePhotoList();
            };
        }
    };

    //Photo2Savane listener /////////
    extractContainerListener() {
        let extractContainer = document.getElementById('extract-container');
        if (!extractContainer) return;
        extractContainer.addEventListener('keyup', (event) => {
            this.keyUpGlobal(event);
        });
    };
    ////////////////////////////////

    //ToolBarWidget listeners ///////
    addPhotosListeners() {
        this.photos.forEach((_, i) => {
            let photoDiv = document.getElementById(`photo-item-${i}`);
            if (!photoDiv) return;
            photoDiv.addEventListener("click", function () {
                this.setCurrentPhoto(i);
            }.bind(this));
        });
    };

    addInputValueDistanceListener() {
        let inputValueDistance = document.getElementById("input-value-distance");
        if (!inputValueDistance) return;
        inputValueDistance.addEventListener("input", (event) => {
            let target = event.target as HTMLInputElement | null;
            let value = target && !isNaN(target.valueAsNumber) ? target.valueAsNumber : 0;
            ToolBarWidget.instance?.inputValueDistanceOnChange(value);
        });
    };

    addInputValueHeightListener() {
        let inputValueHeight = document.getElementById("input-value-height");
        if (!inputValueHeight) return;
        inputValueHeight.addEventListener("input", (event) => {
            let target = event.target as HTMLInputElement | null;
            let value = target && !isNaN(target.valueAsNumber) ? target.valueAsNumber : 0;
            ToolBarWidget.instance?.inputValueHeightOnChange(value);
        });
    };

    addJoinerySelectorListeners() {
        let joinType = document.getElementById("selector_joinType");
        if (!joinType) return;
        (joinType as HTMLSelectElement).value = this.joineryObject.currentElementValueItem || '';
        joinType.addEventListener("change", (event) => {
            if (!event.target) return;
            const target = event.target as HTMLInputElement;
            ToolBarWidget.instance?.selectorJoinTypeOnChange(target.value);
            ModelSidebarWidget.instance?.updateModelSidebar();
        });
        let flipJoinery = document.getElementById("flipJoinery");
        if (!flipJoinery) return;
        flipJoinery.addEventListener("click", () => {
            ToolBarWidget.instance?.flipJoineryOnClick();
        });
    };

    addForceRenormalizeListener() {
        let forceRenormalizeCheckbox = document.getElementById("force_renormalize");
        if (!forceRenormalizeCheckbox) return
        forceRenormalizeCheckbox.addEventListener("change", (event) => {
            if (!event.currentTarget) return;
            const target = event.currentTarget as HTMLInputElement;
            ToolBarWidget.instance?.forceRenormalizeOnChange(target.checked);
        });
    };

    bindFinishEvents() {
        let finishButton = document.getElementById("finish");
        if (finishButton) {
            finishButton.addEventListener("click", this.validateProcess.bind(this));
        }
    };

    bindPerspectiveHelpersEvent() {
        let checkbox = document.getElementById("perspective_helpers") as HTMLInputElement;
        if (!checkbox) return;

        checkbox.addEventListener("change", () => {
            this.hidePerspectiveHelpers = checkbox.checked;
        });

        checkbox.checked = this.hidePerspectiveHelpers;
    };

    bindSmartDrawPhotoEvent() {
        let checkbox = document.getElementById("smart_draw_photo") as HTMLInputElement;

        if (!checkbox) return;
        checkbox.addEventListener("change", () => {
            let isChecked = checkbox.checked;

            SavaneJS.SavaneCookie.setCookie("Rhinov-SmartDrawPhoto", isChecked ? "1" : "0");

            this.smartDrawPhotoEnabled = isChecked;

            ToolBarWidget.instance?.updateToolBarWidget();
        });

        checkbox.checked = this.smartDrawPhotoEnabled;
    };

    bindCeilDrawEvent() {
        let checkbox = document.getElementById("ceil_draw") as HTMLInputElement;

        if (checkbox) {
            checkbox.addEventListener("change", () => {
                let process = this.processes[this.currentPhotoIndex];
                if (!process) return;

                process.photo2savane.Photo2World.CeilDraw = checkbox.checked;
            });

            let process = this.processes[this.currentPhotoIndex];
            if (process) {
                checkbox.checked = process.photo2savane.Photo2World.CeilDraw;
            }
        }
    };

    bindCeilPartitionDrawEvent() {
        let checkbox = document.getElementById("ceil_partition_draw") as HTMLInputElement;

        if (checkbox) {
            checkbox.addEventListener("change", () => {
                let process = this.processes[this.currentPhotoIndex];
                if (!process) return;

                process.photo2savane.Photo2World.CeilPartitionDraw = checkbox.checked;
            });

            let process = this.processes[this.currentPhotoIndex];
            if (process) {
                checkbox.checked = process.photo2savane.Photo2World.CeilPartitionDraw;
            }
        }
    };
    ////////////////////////////////

    //ModelSidebarWidget listeners//
    bindTransomMaxHeightEvent(method: (this: HTMLElement, ev: Event) => any) {
        let inputShowTransomMaxHeight = document.getElementById("inputShowTransomMaxHeight");
        if (!inputShowTransomMaxHeight) return;
        inputShowTransomMaxHeight.addEventListener('input', method);
    };

    bindShowBottomTransomMinHeightEvent(method: (this: HTMLElement, ev: Event) => any) {
        let inputShowBottomTransomMinHeight = document.getElementById("inputShowBottomTransomMinHeight");
        if (!inputShowBottomTransomMinHeight) return;
        inputShowBottomTransomMinHeight.addEventListener('input', method);
    };

    bindMaterialTypeChangeEvent() {
        let materialType = document.getElementById("propertyMaterialType");
        if (!materialType) return;
        materialType.addEventListener("change", (event) => {
            if (!event.currentTarget) return;
            const target = event.currentTarget as HTMLSelectElement;
            ModelSidebarWidget.instance?.propertyMaterialTypeOnChange(target.value);
        });
    };

    bindModelImageEvents() {
        for (let i = 0; i < this.allModelsForCurrentJoinery.length; i++) {
            let img = document.getElementById(`model-image-${i}`);
            if (!img) return;
            img.addEventListener("click", function () {
                ModelSidebarWidget.instance?.modelImageOnClick(i);
            });
        }
    };
    ////////////////////////////////

    bindEvents() {
        //Register canvas events
        let canvas = document.getElementById('picture-canvas');
        if (!canvas) return;
        canvas.addEventListener('mousedown', this.mouseDownCanvas.bind(this));
        canvas.addEventListener('mouseup', this.mouseUpCanvas.bind(this));
        canvas.addEventListener('mousemove', this.mouseMoveCanvas.bind(this));
        canvas.addEventListener('wheel', this.mouseScrollCanvas.bind(this));
        canvas.addEventListener('mouseenter', this.enter.bind(this));
        canvas.addEventListener('mouseleave', this.leave.bind(this));
        document.addEventListener('mouseup', function (event) {
            if (!event.target) return;
            if ((event.target as HTMLElement).classList.contains('btn')) {
                (event.target as HTMLElement).blur();
            }
        });
    };

    initializeEvents() {
        setTimeout(() => {
            this.bindEvents();
            document.body.classList.add('photo2savane-stop-scrolling');
        }, 0);
    };

    /**
     * -------------MOUSE EVENTS
     * @param event
     */
    mouseDownCanvas(event: { preventDefault: () => void; clientX: number; clientY: number; button: number; }) {
        event.preventDefault();

        if (!this._currentImage) return;

        let process = this.processes[this.currentPhotoIndex];
        if (!process) return;

        let canvas = document.getElementById('picture-canvas');
        if (!canvas) return;
        let rect = canvas.getBoundingClientRect();
        let mouseXPos = (event.clientX - rect.left);
        let mouseYPos = (event.clientY - rect.top);
        this._mouseImagePosition = this.mouseToImage(canvas, mouseXPos, mouseYPos);
        let markerSelected: boolean;
        switch (event.button) {
            case 0:
                if (!this._mouseImagePosition) return;
                markerSelected = process.Select(this._mouseImagePosition.x, this._mouseImagePosition.y, this._imageZoomFactor());
                if (markerSelected) {
                    // Undo/Redo
                    if (process.CurrentStep instanceof PairStep && process.CurrentStep.SelectedPair) {
                        this.selectedObjectOriginalCopy = process.CurrentStep.SelectedPair.clone();
                    }
                    if (process.CurrentStep instanceof QueueStep && process.CurrentStep.SelectedMarker) {
                        this.selectedObjectOriginalCopy = process.CurrentStep.SelectedMarker instanceof Marker ? process.CurrentStep.SelectedMarker.clone() : undefined;
                    }

                    //update dropdown
                    switch (process.CurrentStep.StepType) {
                        case PhotoSteps.StepType.Joineries:
                        case PhotoSteps.StepType.JoineriesPartition:
                            if (process.CurrentStep.ActiveElement instanceof MarkerPair) {
                                if (process.CurrentStep.ActiveElement.Value !== undefined) {
                                    this.initJoinery();
                                    this.openSideBar();
                                } else {
                                    this.joineryObject.currentElementValueItem = '-1';
                                }
                            }
                            break;
                        case PhotoSteps.StepType.Camera:
                            if (process.CurrentStep instanceof PairStep) {
                                if (process.CurrentStep.ActiveElementIndex < 2) {
                                    let rotationCenter = process.photo2savane.Photo2World.VanishingPointsInverted ? process.photo2savane.Photo2World.Fv : process.photo2savane.Photo2World.Fu;
                                    process.CurrentStep.ActiveElement.RotationCenter = [rotationCenter[0], rotationCenter[1]];
                                } else if (process.CurrentStep.ActiveElementIndex < 4) {
                                    let rotationCenter = process.photo2savane.Photo2World.VanishingPointsInverted ? process.photo2savane.Photo2World.Fu : process.photo2savane.Photo2World.Fv;
                                    process.CurrentStep.ActiveElement.RotationCenter = [rotationCenter[0], rotationCenter[1]];
                                }
                            }
                            break;
                    }
                } else {
                    this.closeSideBar();
                }
                break;
            case 2:
                if (!this._mouseImagePosition) return;
                markerSelected = process.Select(this._mouseImagePosition.x, this._mouseImagePosition.y, this._imageZoomFactor());
                if (markerSelected) {
                    switch (process.CurrentStep.StepType) {
                        case PhotoSteps.StepType.Slope:
                        case PhotoSteps.StepType.SlopePartition:
                        case PhotoSteps.StepType.Joineries:
                            if (process.CurrentStep.ActiveElement instanceof MarkerPair) {
                                if (process.CurrentStep.ActiveElement.SelectMode === 'A') {
                                    process.CurrentStep.ActiveElement.IsPinnedA = !process.CurrentStep.ActiveElement.IsPinnedA;
                                } else if (process.CurrentStep.ActiveElement.SelectMode === 'B') {
                                    process.CurrentStep.ActiveElement.IsPinnedB = !process.CurrentStep.ActiveElement.IsPinnedB;
                                }
                            }
                            break;
                    }
                }
                break;
        }
    };

    mouseUpCanvas(_event: MouseEvent) {
        if (!this._currentImage) return;

        let process = this.processes[this.currentPhotoIndex];
        if (!process) return;

        if (process.CurrentStep) {
            if (this.selectedObjectOriginalCopy) {
                if (process.CurrentStep instanceof PairStep) {
                    if (process.CurrentStep.SelectedPair) {
                        this.addUndo({
                            originalValue: this.selectedObjectOriginalCopy,
                            finalValue: process.CurrentStep.SelectedPair.clone(),
                            object: process.CurrentStep.SelectedPair
                        });
                    }
                }
                if (process.CurrentStep instanceof QueueStep) {
                    if (process.CurrentStep.SelectedMarker) {
                        this.addUndo({
                            originalValue: this.selectedObjectOriginalCopy,
                            finalValue: process.CurrentStep.SelectedMarker.clone(),
                            object: process.CurrentStep.SelectedMarker
                        });
                    }
                }
            }
        }

        process.Unselect();
        process.UpdateStep();
        this.draw();
        this.updatePhoto2Savane();
    };

    /**
     * We enter here everytime we scroll on the canvas
     * @param event
     */
    mouseMoveCanvas(event: { preventDefault: () => void; clientX: number; clientY: number; buttons: number; shiftKey: boolean; }) {
        event.preventDefault();

        let canvas = document.getElementById('picture-canvas');
        if (canvas === undefined || this._currentImage === undefined) return;

        let process = this.processes[this.currentPhotoIndex];
        if (process === undefined) return;
        if (!canvas) return;
        let rect = canvas.getBoundingClientRect();
        let mouseXPos = (event.clientX - rect.left);
        let mouseYPos = (event.clientY - rect.top);
        let position = this.mouseToImage(canvas, mouseXPos, mouseYPos);

        if (!position) return;
        position = this.magnetPosition(position.x, position.y);
        if (!position) return;
        process.Move(position.x, position.y);

        switch (event.buttons) {
            case 2:
                if (this._mouseImagePosition) {
                    if (event.shiftKey) {
                        let offset = 2 * (position.y - this._mouseImagePosition.y) * this._imageZoomFactor() / this._currentImage.height;
                        process.photo2savane.Photo2World.FocalLengthOffset -= offset;
                    } else {
                        let shift = (position.y - this._mouseImagePosition.y) * this._imageZoomFactor() / this._currentImage.height;
                        process.photo2savane.Photo2World.VerticalShiftOffset -= shift;
                    }
                    this._mouseImagePosition = position;
                }
                break;
            //Pan image
            case 4:
                if (this._mouseImagePosition) {
                    this._canvasTransform.translation.x += (position.x - this._mouseImagePosition.x) * this._imageZoomFactor();
                    this._canvasTransform.translation.y += (position.y - this._mouseImagePosition.y) * this._imageZoomFactor();
                }
                break;
        }

        this.storedProcessPosition = position;
        this.draw();
        this.updatePhoto2Savane();
    };

    /**
     * We enter here everytime we scroll on the canvas
     * @param event
     */
    mouseScrollCanvas(event: { wheelDelta: number; detail: number; }) {
        if (!this._currentImage) return;

        let process = this.processes[this.currentPhotoIndex];
        if (!process) return;

        if (!this._photo3D) return;

        let delta = Math.max(-1, Math.min(1, (event.wheelDelta || -event.detail)));
        let factor = 1.05;
        if (delta < 0) {
            factor = 0.95;
        }
        this._canvasTransform.zoom *= factor;

        this._photo3D.Resize(process.Width * this._imageZoomFactor(), process.Height * this._imageZoomFactor());
        this.draw();
        this.updatePhoto2Savane();
    };

    leave(_event: MouseEvent) {
        this.inside = false;
    };

    enter(_event: MouseEvent) {
        this.inside = true;
    };

    /**
     * Remove an element from current step of process
     */
    removeElement() {
        let process = this.processes[this.currentPhotoIndex];
        process.RemoveElement();

        this.initStepValue();
        this.closeSideBar();

        this.draw();
        this.updatePhoto2Savane();
    };

    /**
     * Add an element to current step of process
     */
    addElement(coord?: IPosition, before?: number) {
        let process = this.processes[this.currentPhotoIndex];

        if (coord && coord.y > 0) {
            process.AddElement(coord.x, coord.y, before);
        } else {
            process.AddElement();
        }

        this.closeSideBar();
        this.initStepValue();

        this.draw();
        this.updatePhoto2Savane();
    };

    detectLabels(force?: boolean) {
        if (this.smartDrawPhotoEnabled === false) return;

        let process = this.processes[this.currentPhotoIndex];
        if (!process || !process.CurrentStep) {
            return "";
        }
        if (process.CurrentStep.StepType === PhotoSteps.StepType.Camera) {
            this.detectCamera(force);
            return 'Camera'
        } else if (process.CurrentStep.StepType === PhotoSteps.StepType.Outline) {
            this.detectOutlines(force);
            return 'Outline'
        } else if (process.CurrentStep.StepType === PhotoSteps.StepType.Joineries) {
            this.detectJoineries(force);
            return 'Joineries'
        }
    };

    detectOutlines(force?: boolean) {
        if (this.smartDrawPhotoEnabled === false) return;

        console.log(this.processes[this.currentPhotoIndex]);
        let step: QueueStep = this.processes[this.currentPhotoIndex].Steps[StepOrder.indexOf(PhotoSteps.StepType.Outline)] as QueueStep;
        if (step.OutlinesDetected && !force) return;
        step.OutlinesDetected = true;
        (this.processes[this.currentPhotoIndex].Steps[StepOrder.indexOf(PhotoSteps.StepType.Camera)] as PairStep).CameraDetected = true;

        let width = this.processes[this.currentPhotoIndex].Width
        let height = this.processes[this.currentPhotoIndex].Height

        this.getAiDetections()
            .then((data) => {
                if (!data) return;
                // smart-replacer-back returns a `result`-type, with 2 variants, `ok` and `err`
                if ("err" in data.wallsAndPartitions) {
                    console.error(data.wallsAndPartitions.err);
                    return;
                }

                let wallMarkers = data.wallsAndPartitions.ok.wallChain.map(({ x, y }) => {
                    let marker = new Marker()
                    marker.X = x * width
                    marker.Y = y * height
                    return marker
                });
                wallMarkers.sort((a: { _x: number; }, b: { _x: number; }) => a._x - b._x);

                // Only keep walls that arent part of the chain
                let partitionsMarkers = data.wallsAndPartitions.ok.partitions
                    .map(({ a, b, width: value, height: value2 }) => {
                        let marker = new MarkerPair()
                        marker.A = new Marker()
                        marker.A.X = a.x * width
                        marker.A.Y = a.y * height
                        marker.B = new Marker()
                        marker.B.X = b.x * width
                        marker.B.Y = b.y * height
                        marker.Value = value
                        marker.Value2 = value2
                        return marker
                    });

                (this.processes[this.currentPhotoIndex].Steps[StepOrder.indexOf(PhotoSteps.StepType.OutlinePartition)] as PairStep).Pairs = partitionsMarkers;

                (this.processes[this.currentPhotoIndex].Steps[StepOrder.indexOf(PhotoSteps.StepType.Outline)] as QueueStep).Markers = wallMarkers;

                this.draw();
                this.updatePhoto2Savane();
            })
    };

    detectJoineries(force?: boolean) {
        if (this.smartDrawPhotoEnabled === false) return;

        console.log(this.processes[this.currentPhotoIndex]);

        if ((this.processes[this.currentPhotoIndex].Steps[StepOrder.indexOf(PhotoSteps.StepType.Joineries)] as PairStep).JoineriesDetected && !force) return;
        (this.processes[this.currentPhotoIndex].Steps[StepOrder.indexOf(PhotoSteps.StepType.Joineries)] as PairStep).JoineriesDetected = true;
        (this.processes[this.currentPhotoIndex].Steps[StepOrder.indexOf(PhotoSteps.StepType.Camera)] as PairStep).CameraDetected = true;
        (this.processes[this.currentPhotoIndex].Steps[StepOrder.indexOf(PhotoSteps.StepType.Outline)] as QueueStep).OutlinesDetected = true;
        //! New code
        let width = this.processes[this.currentPhotoIndex].Width
        let height = this.processes[this.currentPhotoIndex].Height

        this.getAiDetections()
            .then(data => {
                (this.processes[this.currentPhotoIndex].Steps[StepOrder.indexOf(PhotoSteps.StepType.Joineries)] as PairStep).Pairs = []

                // smart-replacer-back returns a `result`-type, with 2 variants, `ok` and `err`
                if ("err" in data.joineries) {
                    console.error(data.joineries.err);
                    return;
                }

                let pairs: MarkerPair[] = data.joineries.ok.map((joinery: { value: number; modelId: string; dimensions: number; nbDoors: number; a: { x: number; y: number; }; b: { x: number; y: number; }; }) => {
                    let marker = new MarkerPair();

                    marker.Value = joinery.value;
                    marker.Model = joinery.modelId;
                    if (joinery.dimensions) {
                        marker.Dimensions = joinery.dimensions;
                    }
                    if (joinery.nbDoors) {
                        marker.NbDoors = joinery.nbDoors;
                    }
                    // marker.itemPreview = scope.getJoineryPreviewUrl(model);

                    let a = new Marker();
                    a.X = joinery.a.x * width;
                    a.Y = joinery.a.y * height;
                    let b = new Marker();
                    b.X = joinery.b.x * width;
                    b.Y = joinery.b.y * height;
                    marker.A = a
                    marker.B = b
                    return marker
                });

                (this.processes[this.currentPhotoIndex].Steps[StepOrder.indexOf(PhotoSteps.StepType.Joineries)] as PairStep).Pairs = pairs;

                this.draw();
                this.updatePhoto2Savane();
            });
    };

    getAllModelsForCurrentJoinery() {
        return (JOINERY_TYPES[this.getCurrentElementArrayIndex()].assetList);
    };

    isTabShown() {
        let val = parseInt(this.joineryObject.currentElementValueItem);
        return (val >= 0);
    };

    initJoinery() {
        let process = this.processes[this.currentPhotoIndex];
        if (process.CurrentStep instanceof PairStep) {
            this.joineryObject.currentElementValueItem = '' + process.CurrentStep.ActiveElement.Value;
            this.joineryObject.currentElementValueMaterial = '' + process.CurrentStep.ActiveElement.MaterialType;
            this.joineryObject.currentElementValueFrosted = process.CurrentStep.ActiveElement.Frosted;
            this.joineryObject.currentElementValueThickness = process.CurrentStep.ActiveElement.Thickness;
            this.joineryObject.currentElementValueModel = process.CurrentStep.ActiveElement.Model;
            this.joineryObject.currentElementValueOpened = process.CurrentStep.ActiveElement.Opened;
            this.joineryObject.currentElementValueRollingShutter = process.CurrentStep.ActiveElement.RollingShutter;
            this.joineryObject.currentElementValueSliding = process.CurrentStep.ActiveElement.Sliding;
            this.joineryObject.currentElementValueWallInstallType = process.CurrentStep.ActiveElement.WallInstallType;
            this.joineryObject.currentElementValueNbDoors = process.CurrentStep.ActiveElement.NbDoors;
            this.joineryObject.currentElementValueTransom = process.CurrentStep.ActiveElement.Transom;
            this.joineryObject.currentElementValueTransomHeight = process.CurrentStep.ActiveElement.TransomHeight;
            this.joineryObject.currentElementValueBottomTransom = process.CurrentStep.ActiveElement.BottomTransom;
            this.joineryObject.currentElementValueBottomTransomHeight = process.CurrentStep.ActiveElement.BottomTransomHeight;
            this.allModelsForCurrentJoinery = this.getAllModelsForCurrentJoinery();
            this.itemPreview = this.getJoineryPreviewUrl({ _id: this.joineryObject.currentElementValueModel });
        }
    };

    getCurrentElementArrayIndex() {
        let intVal = parseInt(this.joineryObject.currentElementValueItem || '0');
        let index = JOINERY_TYPES.findIndex(x => x.value === intVal);
        return index;
    };

    getJoineryPreviewUrl(modele: { _id: string }) {
        if (modele !== undefined) {
            let url = AssetManagerServices.getUrl() + // AssetManagerServices is not defined
                'api/v1/' +
                JOINERY_TYPES[this.getCurrentElementArrayIndex()].assetType +
                '/' +
                modele._id +
                '/medias/previews/preview1-sm.jpg?apiKey=' +
                AssetManagerServices.getToken();

            return (url);
        }
        else {
            return (this.COCOS_PLAN_MODULE_PATH + "cocos/resources/loading.gif");
        }
    };

    updateJoineryModeleType(modele: IModel) {
        let step = this.processes[this.currentPhotoIndex].CurrentStep;
        if (step.ActiveElement instanceof MarkerPair) {
            step.ActiveElement.Model = modele._id;
            if (modele.dimensions) {
                step.ActiveElement.Dimensions = modele.dimensions;
            }
            if (modele.canopyNbPanel) {
                step.ActiveElement.NbDoors = modele.canopyNbPanel;
            }
            this.itemPreview = this.getJoineryPreviewUrl(modele);
        }
    };

    updateJoineryMaterialType(value: string | number) {
        let step = this.processes[this.currentPhotoIndex].CurrentStep;
        if (step.ActiveElement instanceof MarkerPair)
            step.ActiveElement.MaterialType = parseInt(value.toString());
    };

    updateJoineryFrosted(value: boolean) {
        let step = this.processes[this.currentPhotoIndex].CurrentStep;
        if (step.ActiveElement instanceof MarkerPair)
            step.ActiveElement.Frosted = value;
    };

    updateJoineryOpened(value: boolean) {
        let step = this.processes[this.currentPhotoIndex].CurrentStep;
        if (step.ActiveElement instanceof MarkerPair)
            step.ActiveElement.Opened = value;
    };

    updateJoineryRollingShutter(value: boolean) {
        let step = this.processes[this.currentPhotoIndex].CurrentStep;
        if (step.ActiveElement instanceof MarkerPair)
            step.ActiveElement.RollingShutter = value;
    };

    updateJoineryNbDoors(value: number) {
        let step = this.processes[this.currentPhotoIndex].CurrentStep;
        if (value < 1) {
            this.joineryObject.currentElementValueNbDoors = 1;
        }
        if (step.ActiveElement instanceof MarkerPair)
            step.ActiveElement.NbDoors = value;
    };

    updateJoineryTransom(value: boolean) {
        let step = this.processes[this.currentPhotoIndex].CurrentStep;
        if (step.ActiveElement instanceof MarkerPair)
            step.ActiveElement.Transom = value;
        this.updatePhoto2Savane();
    };

    updateJoineryBottomTransom(value: boolean) {
        let step = this.processes[this.currentPhotoIndex].CurrentStep;
        if (step.ActiveElement instanceof MarkerPair)
            step.ActiveElement.BottomTransom = value;
        this.updatePhoto2Savane();
    };

    updateJoineryTransomHeight(value: number) {
        let step = this.processes[this.currentPhotoIndex].CurrentStep;
        if (step.ActiveElement instanceof MarkerPair)
            step.ActiveElement.TransomHeight = value;
        this.updatePhoto2Savane();
    };

    updateJoineryBottomTransomHeight(value: number) {
        let step = this.processes[this.currentPhotoIndex].CurrentStep;
        if (step.ActiveElement instanceof MarkerPair)
            step.ActiveElement.BottomTransomHeight = value;
        this.updatePhoto2Savane();
    };

    updateJoineryThickness(value: number) {
        let step = this.processes[this.currentPhotoIndex].CurrentStep;
        if (step.ActiveElement instanceof MarkerPair)
            step.ActiveElement.Thickness = value;
    };

    updateJoinerySliding(value: boolean) {
        let step = this.processes[this.currentPhotoIndex].CurrentStep;
        let joineryType = parseInt(this.joineryObject.currentElementValueItem || '0');

        if (value) {
            switch (joineryType) {
                // Door
                case 0:
                    this.joineryObject.currentElementValueModel = SavaneJS.SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelSlidingDoor", SavaneJS.SceneConstants.DefaultJoineryType.slidingDoor);
                    break;
                // Double door
                case 1:
                    this.joineryObject.currentElementValueModel = SavaneJS.SceneConstants.DefaultJoineryType.slidingDoubleDoor;
                    break;
                // Double window
                case 5:
                    this.joineryObject.currentElementValueModel = SavaneJS.SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelPictureWindow", SavaneJS.SceneConstants.DefaultJoineryType.pictureWindow);
                    break;
            }
        }
        else {
            switch (joineryType) {
                // Door
                case 0:
                    this.joineryObject.currentElementValueModel = SavaneJS.SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelDoor", SavaneJS.SceneConstants.DefaultJoineryType.door);
                    break;
                // Double door
                case 1:
                    this.joineryObject.currentElementValueModel = SavaneJS.SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelDoubleDoor", SavaneJS.SceneConstants.DefaultJoineryType.doubleDoor);
                    break;
                // Double window
                case 5:
                    this.joineryObject.currentElementValueModel = SavaneJS.SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelDoubleWindow", SavaneJS.SceneConstants.DefaultJoineryType.doubleWindow);
                    break;
            }
        }

        if (step.ActiveElement instanceof MarkerPair)
            step.ActiveElement.Model = this.joineryObject.currentElementValueModel;
        this.itemPreview = this.getJoineryPreviewUrl({ _id: this.joineryObject.currentElementValueModel });
        if (step.ActiveElement instanceof MarkerPair)
            step.ActiveElement.Sliding = value;
    };

    updateJoineryWallInstallType(value: number) {
        let step = this.processes[this.currentPhotoIndex].CurrentStep;
        this.joineryObject.currentElementValueWallInstallType = value;
        if (step.ActiveElement instanceof MarkerPair)
            step.ActiveElement.WallInstallType = value;
    };

    showProperty(propertyName: string) {
        let index = this.getCurrentElementArrayIndex();
        let currentJoineryType = JOINERY_TYPES[index];
        return currentJoineryType ? currentJoineryType[propertyName] : undefined;
    };

    getDropdownData() {
        let joineryIndex = JOINERY_TYPES[this.getCurrentElementArrayIndex()];
        return (joineryIndex.dropDownMaterialList);
    };

    openSideBar() {
        // check if the sidebar is already open
        let sidebar = document.getElementById("ModelSidebar");
        if (!sidebar) return;
        sidebar.style.width = "300px";
        ToolBarWidget.instance?.updateToolBarWidget();
        ModelSidebarWidget.instance?.updateModelSidebar();
    };

    closeSideBar() {
        let sidebar = document.getElementById("ModelSidebar");
        if (!sidebar) return;
        sidebar.style.width = "0";
    };

    flipJoinery() {
        let process = this.processes[this.currentPhotoIndex];
        if (!process) return;

        if (!process.CurrentStep) return;

        if (!process.CurrentStep.ActiveElement) return;

        if (process.CurrentStep.ActiveElement instanceof MarkerPair)
            process.CurrentStep.ActiveElement.Invert = !process.CurrentStep.ActiveElement.Invert;
        this.updatePhoto2Savane();
    };

    initStepValue() {
        let process = this.processes[this.currentPhotoIndex];
        if (!process) return;

        if (!process.CurrentStep) return;

        switch (process.CurrentStep.StepType) {
            case PhotoSteps.StepType.Outline:
                this.joineryObject.currentElementValue = process.photo2savane.Photo2World.WallHeight / 10;
                break;
            case PhotoSteps.StepType.Camera:
                this.joineryObject.currentElementValue = process.photo2savane.Photo2World.WorldHeight / 10;
                break;
            case PhotoSteps.StepType.OutlinePartition:
                if (process.CurrentStep.ActiveElement && process.CurrentStep.ActiveElement instanceof MarkerPair) {
                    this.joineryObject.currentElementValue = process.CurrentStep.ActiveElement.Value;
                    this.joineryObject.currentElementValue2 = process.CurrentStep.ActiveElement.Value2;
                }
                break;
            case PhotoSteps.StepType.Joineries:
            case PhotoSteps.StepType.JoineriesPartition:
                this.joineryObject.currentElementValueItem = String(JOINERY_TYPES[0].value);
                if (process.CurrentStep.ActiveElement && process.CurrentStep.ActiveElement instanceof MarkerPair) {
                    this.joineryObject.currentElementValue = process.CurrentStep.ActiveElement.Value;
                    let found = false;
                    for (let i = 0; i < JOINERY_TYPES.length; ++i) {
                        if (JOINERY_TYPES[i].value == this.joineryObject.currentElementValue) {
                            // Fill joinery model here
                            this.joineryObject.currentElementValueItem = '' + JOINERY_TYPES[i].value;
                            found = true;
                            this.initJoinery();
                            this.openSideBar();
                            break;
                        }
                    }
                    if (!found) {
                        this.joineryObject.currentElementValueItem = '-1';
                    }
                } else {
                    this.joineryObject.currentElementValueItem = '-1';
                }
                break;
            default:
                this.joineryObject.currentElementValue = undefined;
                this.joineryObject.currentElementValue2 = undefined;
                this.joineryObject.currentElementValueItem = '' + JOINERY_TYPES[0].value;
                break;
        }
    };

    /**
     * A utility function that fetches an image and resolves with it base64 encoded
     *
     * Useful because smart-replacer-back takes `b64image` as its input.
     */
    async toDataURL(url: string | URL | Request) {
        return fetch(url)
            .then(response => response.blob())
            .then(blob => new Promise((resolve, reject) => {
                let reader = new FileReader()
                reader.onloadend = () => resolve(reader.result)
                reader.onerror = reject
                reader.readAsDataURL(blob)
            }))
    };

    /**
     * This function fetches all AI detections and caches them, on a per-photo basis.
     *
     * The first time it is called it will fetch the results and resolve with them,
     * all subsequent times it will simply resolve with cached results
     */
    async getAiDetections() {
        if (this.processes[this.currentPhotoIndex].aiDetections) {
            return this.processes[this.currentPhotoIndex].aiDetections;
        }

        let currentPhoto = this.photos[this.currentPhotoIndex];
        let file = undefined;
        if (currentPhoto) {
            file = currentPhoto.path + currentPhoto.filename;
        } else {
            console.error("No current photo found.");
            return null;
        }

        let INFERENCE_BASE_URL = (typeof this.HP_CONFIG !== 'undefined') ? this.HP_CONFIG.SMART_DRAWPHOTO_URI : '//replacer-api.rhinovplanner.com';
        let API_URL = INFERENCE_BASE_URL + '/predict';


        // try {
        //     let b64image = await this.toDataURL(file);
        //     let response = await fetch(API_URL, {
        //         method: "POST",
        //         headers: { 'Content-Type': 'application/json' },
        //         body: JSON.stringify({ b64image: (b64image as string).split(',')[1] })
        //     });
        //     let data = await response.json();
        //     this.processes[this.currentPhotoIndex].aiDetections = data;
        //     console.log(data);
        //     return data;
        // } catch (err) {
        //     console.error(err);
        //     return null;
        // }

        return new Promise((resolve, reject) => {
            if (file) {
                this.toDataURL(file).then(b64image => {
                    var body = JSON.stringify({ b64image: (b64image as string).split(',')[1] });
                    fetch(API_URL, { method: "POST", headers: { 'Content-Type': 'application/json' }, body: body })
                        .then(response => response.json())
                        .then(data => {
                            this.processes[this.currentPhotoIndex].aiDetections = data;
                            console.log(data)
                            resolve(data);
                        })
                }
                )
                    .catch((...err) => reject(...err))
            } else {
                var body = JSON.stringify({ b64image: currentPhoto.split(',')[1] });
                fetch(API_URL, { method: "POST", headers: { 'Content-Type': 'application/json' }, body: body })
                    .then(response => response.json())
                    .then(data => {
                        this.processes[this.currentPhotoIndex].aiDetections = data;
                        console.log(data)
                        resolve(data);
                    })
            }
        });
    }

    detectCamera(force?: boolean) {
        if (this.smartDrawPhotoEnabled === false) return;

        if ((this.processes[this.currentPhotoIndex].Steps[StepOrder.indexOf(PhotoSteps.StepType.Camera)] as PairStep).CameraDetected && !force) return;
        (this.processes[this.currentPhotoIndex].Steps[StepOrder.indexOf(PhotoSteps.StepType.Camera)] as PairStep).CameraDetected = true;

        let currentPhoto = this.photos[this.currentPhotoIndex];
        let file = currentPhoto.path + currentPhoto.filename;

        console.log(JSON.stringify({ source: file }))
        let width = this.processes[this.currentPhotoIndex].Width;
        let height = this.processes[this.currentPhotoIndex].Height;

        this.getAiDetections()
            .then(data => {
                if (!data) {
                    console.error("Erreur : getAiDetections() a retourné null.");
                    return;
                }
                // smart-replacer-back returns a `result`-type, with 2 variants, `ok` and `err`
                if ("ok" in data.camera) {
                    let u = data.camera.ok.u;
                    let v = data.camera.ok.v;
                    this.updatePairTheo((this.processes[this.currentPhotoIndex].Steps[StepOrder.indexOf(PhotoSteps.StepType.Camera)] as PairStep).Pairs[0], u[0], width, height);
                    this.updatePairTheo((this.processes[this.currentPhotoIndex].Steps[StepOrder.indexOf(PhotoSteps.StepType.Camera)] as PairStep).Pairs[1], u[1], width, height);
                    this.updatePairTheo((this.processes[this.currentPhotoIndex].Steps[StepOrder.indexOf(PhotoSteps.StepType.Camera)] as PairStep).Pairs[2], v[0], width, height);
                    this.updatePairTheo((this.processes[this.currentPhotoIndex].Steps[StepOrder.indexOf(PhotoSteps.StepType.Camera)] as PairStep).Pairs[3], v[1], width, height);
                } else {
                    console.error("Erreur : data.camera n'a pas le format attendu", data.camera);
                }
                // smart-replacer-back returns a `result`-type, with 2 variants, `ok` and `err`
                if ("ok" in data.knownHeight) {
                    let { bottom, top, height: knownHeight } = data.knownHeight.ok;
                    this.updateHeight((this.processes[this.currentPhotoIndex].Steps[StepOrder.indexOf(PhotoSteps.StepType.Camera)] as PairStep).Pairs[4], { top, bottom }, width, height, knownHeight);
                } else {
                    console.error("Erreur : data.knownHeight n'a pas le format attendu", data.knownHeight);
                }

                this.draw();
                this.updatePhoto2Savane();
            });
    };

    /**
     * Update the current step with the predicted values
     * @param {Object} pair - The pair to update
     * @param {{
     *      a: {
     *          x: number;
     *          y: number;
     *      };
     *      b: {
     *          x: number;
     *          y: number;
     *      };
     * }} predict - The predicted values
     * @param {number} width - The width of the image
     * @param {number} height - The height of the image
     */
    updatePairTheo(pair: MarkerPair, predict: { a: { x: number; y: number; }; b: { x: number; y: number; }; }, width: number, height: number) {
        if (!pair.A || !pair.B) throw new Error("Pair is not correctly initialized");
        pair.A.X = predict.a.x * width;
        pair.A.Y = predict.a.y * height;
        pair.B.X = predict.b.x * width;
        pair.B.Y = predict.b.y * height;
    };

    updateHeight(pair: MarkerPair, { top, bottom }: { top: IPosition; bottom: IPosition; }, width: number, height: number, value: number) {
        if (!pair.A || !pair.B) throw new Error("Pair is not correctly initialized");
        pair.A.X = bottom.x * width;
        pair.A.Y = bottom.y * height;
        pair.B.X = top.x * width;
        pair.B.Y = top.y * height;

        if (value) {
            pair.Value = value
        }

        let process = this.processes[this.currentPhotoIndex];
        if (process && process.CurrentStep.StepType === PhotoSteps.StepType.Camera) {
            process.photo2savane.Photo2World.WorldHeight = value * 10;
            this.joineryObject.currentElementValue = process.photo2savane.Photo2World.WorldHeight / 10;
        }
    };

    /**
     * Save the process
     */
    saveProcess() {
        let process = this.processes[this.currentPhotoIndex];
        let currentPhoto = this.photos[this.currentPhotoIndex];
        let datas = {
            photo_to_savane_data: JSON.stringify(process.Serialize()),
            photo: currentPhoto
        };

        Savane.eventsManager.instance.dispatch("PROCESS_SAVED", datas);
    };

    /**
     * Go to previous step in process
     */
    previousStep() {
        let process = this.processes[this.currentPhotoIndex];

        this.closeSideBar();
        process.PreviousStep();

        this.cleanUndoRedo();

        this.initStepValue();

        this.draw();
        this.updatePhoto2Savane();
    };

    /**
     *
     * Go to next step in process
     *
     */
    nextStep() {
        let process = this.processes[this.currentPhotoIndex];
        if (!process) return;

        if (!process.CurrentStep) return;

        //Go to next step
        if (process.ValidateStep(this._imageZoomFactor())) {
            //Save process
            this.saveProcess();
            this.cleanUndoRedo();

            this.initStepValue();
            this.closeSideBar();
            ToolBarWidget.instance?.updateToolBarWidget();
            ToolBarWidget.instance?.updatePhotoList();

            if (process.CurrentStep) { //no more step to do if CurrentStep is undefined
                this.draw();
                if (!this._photo3D) return;
                this._photo3D.Resize(process.Width * this._imageZoomFactor(), process.Height * this._imageZoomFactor());
                this.updatePhoto2Savane();
            }

            this.detectLabels();
        } else {
            this.toastr.clear();
            this.toastr.error("L'étape courante n'est pas valide");
        }
    };

    /**
     * Return name of current step
     */
    stepName() {
        let process = this.processes[this.currentPhotoIndex];
        if (!process) {
            return "";
        }

        switch (process.CurrentStep.StepType) {
            case PhotoSteps.StepType.Camera:
                return "Points de fuite et hauteur de référence";
            case PhotoSteps.StepType.Outline:
                return "Contour du sol et hauteur de la pièce";
            case PhotoSteps.StepType.Joineries:
                return "Menuiseries";
            case PhotoSteps.StepType.Slope:
                return "Sous-Pentes";
            case PhotoSteps.StepType.OutlinePartition:
                return "Cloison et épaisseur";
            case PhotoSteps.StepType.SlopePartition:
                return "Sous-Pentes de cloison";
            case PhotoSteps.StepType.JoineriesPartition:
                return "Menuiseries de cloison";
            default:
                return "Erreur";
        }
    };

    photoValue(key: string, factor: number | undefined) {
        let process = this.processes[this.currentPhotoIndex];
        if (!factor) {
            factor = 1;
        }
        return (process.photo2savane.Photo2World[key] * factor).toFixed(1);
    };

    stepElementsCount() {
        let process = this.processes[this.currentPhotoIndex];
        if (!process) {
            return 0;
        }

        return process.CurrentStep.Count;
    };

    extractCamera() {
        for (let i = 0; i < this.processes.length; ++i) {
            let process = this.processes[i];
            let scene = this.scope.world.children[i];


            let camera = process.photo2savane.World2savane.extractCamera(process.photo2savane.Photo2World, this.scope.world);
            camera.locked = false;
            camera.updateCameraNb(this.scope.world);
            scene.addEntity(camera);
        }

        this.saveProcess();
        let deliverablePatchObject = {
            rhinov_format: SavaneJS.JSONSerializer.serializeEntity(this.scope.world)
        };

        Savane.eventsManager.instance.dispatch("FINISH_PHOTO2SAVANE", deliverablePatchObject);
        Savane.eventsManager.instance.dispatch("PHOTO_TERMINATED", this.scope.world);
    };

    stepInfos() {
        let process = this.processes[this.currentPhotoIndex];
        if (process === undefined || process === undefined) {
            return "";
        }

        let infos = "";
        switch (process.CurrentStep.StepType) {
            case PhotoSteps.StepType.Camera:
                infos = "";
                break;
            case PhotoSteps.StepType.Outline:
                infos = "";
                break;
            case PhotoSteps.StepType.Joineries:
                let nbJoineries = 0;
                if (process.CurrentStep instanceof PairStep)
                    for (let i = 0; i < process.CurrentStep.Pairs.length; ++i) {
                        if (!process.CurrentStep.Pairs[i].IsFake) {
                            nbJoineries++
                        }
                    }
                infos = nbJoineries + " menuiseries";
                break;
            case PhotoSteps.StepType.Slope:
                infos = "";
                break;
            case PhotoSteps.StepType.OutlinePartition:
                infos = "";
                break;
            case PhotoSteps.StepType.SlopePartition:
                infos = "";
                break;
            case PhotoSteps.StepType.JoineriesPartition:
                infos = "";
                break;
            default:
                infos = "Erreur";
                break;
        }
        return infos;
    };

    requiredValue() {
        let process = this.processes[this.currentPhotoIndex];
        if (!process) {
            return PhotoSteps.StepValuesType.None;
        }

        return process.StepValueType;
    };

    canDrawWallFromCeiling() {
        let process = this.processes[this.currentPhotoIndex];
        if (!process) {
            return false;
        }

        return process.CanDrawWallFromCeiling;
    };

    canDrawPartitionFromCeiling() {
        let process = this.processes[this.currentPhotoIndex];
        if (!process) {
            return false;
        }

        return process.CanDrawPartitionFromCeiling;
    };

    canHidePerspectiveHelpers() {
        let process = this.processes[this.currentPhotoIndex];
        if (!process) {
            return false;
        }

        return process.IsCameraStep;
    };

    /**
     * Check if process is invalidated
     * @param index
     * @returns {boolean}
     */
    isProcessInvalid(index: number): boolean {
        if (index >= this.processes.length) {
            return false;
        }
        return this.processes[index] !== undefined && this.processes[index].Invalidate;
    };

    isProcessDone(index: number) {
        if (index >= this.processes.length) {
            return false;
        }
        return this.processes[index] !== undefined && !this.processes[index].Invalidate && this.processes[index].Finished;
    };

    setElementValue(value: string | number) {
        let intValue = parseInt(`${value}`);
        let process = this.processes[this.currentPhotoIndex];
        if (!process) return;

        if (!process.CurrentStep) return;

        if (!process.CurrentStep.ActiveElement) return;

        switch (process.CurrentStep.StepType) {
            case PhotoSteps.StepType.Camera:
                process.photo2savane.Photo2World.WorldHeight = intValue * 10;
                break;
            case PhotoSteps.StepType.Outline:
                process.photo2savane.Photo2World.WallHeight = intValue * 10;
                this.updatePhoto2Savane();
                break;
            case PhotoSteps.StepType.Joineries:
            case PhotoSteps.StepType.JoineriesPartition:
                if (process.CurrentStep.ActiveElement instanceof MarkerPair) {
                    if (intValue === -1) {
                        process.CurrentStep.ActiveElement.Value = undefined;
                        process.CurrentStep.ActiveElement.SetMarkerColor("orange");
                        return this.closeSideBar();
                    }
                    if (process.CurrentStep.ActiveElement instanceof MarkerPair)
                        process.CurrentStep.ActiveElement.Value = intValue;
                    if (process.CurrentStep.ActiveElement instanceof MarkerPair)
                        process.CurrentStep.ActiveElement.SetMarkerColor("#00ff00");
                    // Joinery needs materials
                    if (this.showProperty("showMaterials")) {
                        // Default to white PVC
                        if (this.joineryObject.currentElementValueItem !== "20") {
                            this.joineryObject.currentElementValueMaterial = "0";
                        } else {
                            this.joineryObject.currentElementValueMaterial = "7";
                        }
                        if (process.CurrentStep.ActiveElement instanceof MarkerPair)
                            process.CurrentStep.ActiveElement.MaterialType = parseInt(this.joineryObject.currentElementValueMaterial);
                    }
                    if (this.showProperty("showFrosted")) {
                        this.joineryObject.currentElementValueFrosted = false;
                        if (process.CurrentStep.ActiveElement instanceof MarkerPair)
                            process.CurrentStep.ActiveElement.Frosted = this.joineryObject.currentElementValueFrosted;
                    }
                    if (this.showProperty("showThickness")) {
                        this.joineryObject.currentElementValueThickness = 5;
                        process.CurrentStep.ActiveElement.Thickness = this.joineryObject.currentElementValueThickness;
                    }
                    // Joinery can be opened
                    if (this.showProperty("showOpened")) {
                        this.joineryObject.currentElementValueOpened = false;
                        process.CurrentStep.ActiveElement.Opened = this.joineryObject.currentElementValueOpened;
                    }
                    // Joinery can have rolling shutters
                    if (this.showProperty("showRollingShutter")) {
                        this.joineryObject.currentElementValueRollingShutter = false;
                        process.CurrentStep.ActiveElement.RollingShutter = this.joineryObject.currentElementValueRollingShutter;
                    }
                    // Joinery can be sliding
                    if (this.showProperty("showSliding")) {
                        this.joineryObject.currentElementValueSliding = false;
                        process.CurrentStep.ActiveElement.Sliding = this.joineryObject.currentElementValueSliding;
                    }
                    // Joinery can be installed inside, middle or outside
                    if (this.showProperty("showInstallType")) {
                        this.joineryObject.currentElementValueWallInstallType = 1;
                        process.CurrentStep.ActiveElement.WallInstallType = this.joineryObject.currentElementValueWallInstallType;
                    }
                    if (this.showProperty("showTransom")) {
                        this.joineryObject.currentElementValueTransom = false;
                        process.CurrentStep.ActiveElement.Transom = this.joineryObject.currentElementValueTransom;
                        this.joineryObject.currentElementValueTransomHeight = 250;
                        process.CurrentStep.ActiveElement.TransomHeight = this.joineryObject.currentElementValueTransomHeight;
                    }
                    if (this.showProperty("showBottomTransom")) {
                        this.joineryObject.currentElementValueBottomTransom = false;
                        process.CurrentStep.ActiveElement.BottomTransom = this.joineryObject.currentElementValueBottomTransom;
                        this.joineryObject.currentElementValueBottomTransomHeight = 0;
                        process.CurrentStep.ActiveElement.BottomTransomHeight = this.joineryObject.currentElementValueBottomTransomHeight;
                    }
                    // Default nb doors for joinery type
                    this.joineryObject.currentElementValueNbDoors = JOINERY_TYPES[this.getCurrentElementArrayIndex()].nbDoors;
                    process.CurrentStep.ActiveElement.NbDoors = this.joineryObject.currentElementValueNbDoors
                    // Default 3D model and possible models
                    this.joineryObject.currentElementValueModel = JOINERY_TYPES[this.getCurrentElementArrayIndex()].defaultModel;
                    this.allModelsForCurrentJoinery = this.getAllModelsForCurrentJoinery();
                    if (!this.joineryObject.currentElementValueModel) {
                        this.joineryObject.currentElementValueModel = this.allModelsForCurrentJoinery[0]._id;
                    }
                    let modele = this.allModelsForCurrentJoinery.find(function (item) {
                        return item._id === this.joineryObject.currentElementValueModel;
                    }.bind(this));
                    if (modele) {
                        process.CurrentStep.ActiveElement.Dimensions = modele.dimensions;
                    }
                    process.CurrentStep.ActiveElement.Model = this.joineryObject.currentElementValueModel;
                    // Assign preview url
                    this.itemPreview = this.getJoineryPreviewUrl({ _id: this.joineryObject.currentElementValueModel });
                    // Open sidebar to show the properties to adjust
                    this.openSideBar();
                }
                break;
            case PhotoSteps.StepType.OutlinePartition:
                if (process.CurrentStep.ActiveElement instanceof MarkerPair)
                    process.CurrentStep.ActiveElement.Value = intValue;
                this.updatePhoto2Savane();
                break;
        }
    };

    setElementValue2(value: string | number) {
        let intValue = parseInt(`${value}`);
        let process = this.processes[this.currentPhotoIndex];
        if (!process) return;

        if (!process.CurrentStep) return;

        if (!process.CurrentStep.ActiveElement) return;

        if (intValue === -1) {
            if (process.CurrentStep.StepType === PhotoSteps.StepType.Joineries && process.CurrentStep.ActiveElement instanceof MarkerPair) {
                process.CurrentStep.ActiveElement.Value2 = undefined;
                return this.closeSideBar();
            }
        }

        switch (process.CurrentStep.StepType) {
            case PhotoSteps.StepType.OutlinePartition:
                if (process.CurrentStep.ActiveElement instanceof MarkerPair)
                    process.CurrentStep.ActiveElement.Value2 = intValue;
                this.updatePhoto2Savane();
                break;
        }
    };

    addUndo(object: IOperation) {
        this.undoStack.push(object);
        this.redoStack = []
    };

    undo() {
        if (this.undoStack.length > 0) {
            let undoOperation = this.undoStack[this.undoStack.length - 1];
            this.redoStack.push(undoOperation);
            if (undoOperation.object instanceof MarkerPair && undoOperation.originalValue instanceof MarkerPair) {
                undoOperation.object.copyData(undoOperation.originalValue);
            } else if (undoOperation.object instanceof Marker && undoOperation.originalValue instanceof Marker) {
                undoOperation.object.copyData(undoOperation.originalValue);
            }
            this.undoStack.pop();
        }

    };

    redo() {
        if (this.redoStack.length > 0) {
            let redoOperation = this.redoStack[this.redoStack.length - 1];
            this.undoStack.push(redoOperation);
            if (redoOperation.object instanceof MarkerPair && redoOperation.finalValue instanceof MarkerPair) {
                redoOperation.object.copyData(redoOperation.finalValue);
            } else if (redoOperation.object instanceof Marker && redoOperation.finalValue instanceof Marker) {
                redoOperation.object.copyData(redoOperation.finalValue);
            }
            this.redoStack.pop();
        }
    };

    remagnetize() {
        let process = this.processes[this.currentPhotoIndex];
        if (!process) return;

        //Remagnet walls
        let outlineStep = process.Steps[PhotoSteps.StepOrder.indexOf(PhotoSteps.StepType.Outline)] as QueueStep;
        for (let i = 0; i < outlineStep.Markers.length; ++i) {
            let element = outlineStep.Markers[i];
            let position = this.magnetMarker(element.X, element.Y, element, PhotoSteps.StepType.Outline);
            if (!position) return;
            element.Move(position.x, position.y);
        }
    };

    keyDownGlobal(event: { keyCode: number; shiftKey: number; preventDefault: () => void; }) {
        if (event.keyCode === 17) {
            this.ctrlPressed = true;
        }
        // CTRL + Y
        if (event.keyCode === 89 && this.ctrlPressed) {
            this.redo();
        }
        // CTRL + Z
        if (event.keyCode === 90 && this.ctrlPressed) {
            this.undo();
        }

        let process = this.processes[this.currentPhotoIndex];
        if (!process) return;

        let stepType = PhotoSteps.StepType.Count;
        if (process.CurrentStep) {
            stepType = process.CurrentStep.StepType;
        }

        switch (stepType) {
            case PhotoSteps.StepType.Joineries:
            case PhotoSteps.StepType.Slope:
            case PhotoSteps.StepType.OutlinePartition:
            case PhotoSteps.StepType.Outline:
                switch (event.keyCode) {
                    case 37: //Left
                        process.photo2savane.Photo2World.OffsetFuX -= 1;
                        this.remagnetize();
                        break;
                    case 39: //Right
                        process.photo2savane.Photo2World.OffsetFuX += 1;
                        this.remagnetize();
                        break;
                    case 38: //Up
                        process.photo2savane.Photo2World.OffsetFuY -= 0.1;
                        this.remagnetize();
                        break;
                    case 40: //Down
                        process.photo2savane.Photo2World.OffsetFuY += 0.1;
                        this.remagnetize();
                        break;
                    case 90: //Z
                        process.photo2savane.Photo2World.OffsetFvY -= 0.1;
                        this.remagnetize();
                        break;
                    case 83: //S magnet
                        process.photo2savane.Photo2World.Magnet = !process.photo2savane.Photo2World.Magnet;
                        break;
                    case 81: //Q
                        process.photo2savane.Photo2World.OffsetFvX -= 1;
                        this.remagnetize();
                        break;
                    case 68: //D
                        process.photo2savane.Photo2World.OffsetFvX += 1;
                        this.remagnetize();
                        break;
                    case 80: //P
                        process.photo2savane.Photo2World.VerticalShiftOffset += 0.001;
                        break;
                    case 77: //M
                        process.photo2savane.Photo2World.VerticalShiftOffset -= 0.001;
                        break;
                    case 88: //X
                        process.photo2savane.Photo2World.OffsetFvY += 0.1;
                        this.remagnetize();
                        break;
                    case 32: //space
                        if (this.inside) {
                            this.addElement(this.storedProcessPosition, event.shiftKey);
                            ToolBarWidget.instance?.updateToolBarWidget();
                        }
                        event.preventDefault();
                        break;
                    case 8: //backspace
                    case 46:
                        if (this.inside) {
                            this.removeElement();
                            ToolBarWidget.instance?.updateToolBarWidget();
                        }
                        break;
                    case 71: //G
                        if (this.inside) {
                            this.resetTransfrom();
                        }
                        break;
                    case 13: // Entrée
                        if (!this.isProcessEnded()) {
                            this.nextStep();
                        }
                        else {
                            this.validateProcess();
                        }
                        break;
                }
                break;
            case PhotoSteps.StepType.JoineriesPartition:
                switch (event.keyCode) {
                    case 80: //P
                        process.photo2savane.Photo2World.VerticalShiftOffset += 0.001;
                        break;
                    case 77: //M
                        process.photo2savane.Photo2World.VerticalShiftOffset -= 0.001;
                        break;
                    case 37: //Left
                        if (process.CurrentStep.ActiveElement && process.CurrentStep.ActiveElement instanceof MarkerPair) {
                            let partition = process.CurrentStep.ActiveElement.WallIndex;
                            if (partition < process.photo2savane.Photo2World.Model.Partitions.length) {
                                (process.photo2savane.Photo2World.Model.Partitions as any)[partition].selected = false;
                            }
                            partition = (partition - 1 + process.photo2savane.Photo2World.Model.Partitions.length) % process.photo2savane.Photo2World.Model.Partitions.length;
                            process.CurrentStep.ActiveElement.WallIndex = partition;
                            process.photo2savane.Photo2World.BuildPartitionJoineries(process.Pixels, process.JoineriesData);
                            (process.photo2savane.Photo2World.Model.Partitions as any)[partition].selected = true;
                        }
                        break;
                    case 39: //Right
                        if (process.CurrentStep.ActiveElement && process.CurrentStep.ActiveElement instanceof MarkerPair) {
                            let partition = process.CurrentStep.ActiveElement.WallIndex;
                            if (partition < process.photo2savane.Photo2World.Model.Partitions.length) {
                                (process.photo2savane.Photo2World.Model.Partitions as any)[partition].selected = false;
                            }
                            partition = (partition + 1) % process.photo2savane.Photo2World.Model.Partitions.length;
                            process.CurrentStep.ActiveElement.WallIndex = partition;
                            process.photo2savane.Photo2World.BuildPartitionJoineries(process.Pixels, process.JoineriesData);
                            (process.photo2savane.Photo2World.Model.Partitions as any)[partition].selected = true;
                        }
                        break;
                    case 32: //space
                        if (this.inside) {
                            this.addElement(this.storedProcessPosition, event.shiftKey);
                            ToolBarWidget.instance?.updateToolBarWidget();
                        }
                        event.preventDefault();
                        break;
                    case 8: //backspace
                    case 46:
                        if (this.inside) {
                            this.removeElement();
                            ToolBarWidget.instance?.updateToolBarWidget();
                        }
                        break;
                    case 71: //G
                        if (this.inside) {
                            this.resetTransfrom();
                        }
                        break;
                    case 13: // Entrée
                        if (!this.isProcessEnded()) {
                            this.nextStep();
                        }
                        else {
                            this.validateProcess();
                        }
                        break;
                }
                break;
            case PhotoSteps.StepType.SlopePartition:
                switch (event.keyCode) {
                    case 80: //P
                        process.photo2savane.Photo2World.VerticalShiftOffset += 0.001;
                        break;
                    case 77: //M
                        process.photo2savane.Photo2World.VerticalShiftOffset -= 0.001;
                        break;
                    case 37: //Left
                        if (process.CurrentStep.ActiveElement && process.CurrentStep.ActiveElement instanceof MarkerPair) {
                            let partition = process.CurrentStep.ActiveElement.WallIndex;
                            if (partition < process.photo2savane.Photo2World.Model.Partitions.length) {
                                (process.photo2savane.Photo2World.Model.Partitions as any)[partition].selected = false;
                            }
                            partition = (partition - 1 + process.photo2savane.Photo2World.Model.Partitions.length) % process.photo2savane.Photo2World.Model.Partitions.length;
                            process.CurrentStep.ActiveElement.WallIndex = partition;
                            process.photo2savane.Photo2World.BuildSlopesPartition(process.Pixels, process.SlopesData);

                            (process.photo2savane.Photo2World.Model.Partitions as any)[partition].selected = true;
                        }
                        break;
                    case 39: //Right
                        if (process.CurrentStep.ActiveElement && process.CurrentStep.ActiveElement instanceof MarkerPair) {
                            let partition = process.CurrentStep.ActiveElement.WallIndex;
                            if (partition < process.photo2savane.Photo2World.Model.Partitions.length) {

                                (process.photo2savane.Photo2World.Model.Partitions as any)[partition].selected = false;
                            }
                            partition = (partition + 1) % process.photo2savane.Photo2World.Model.Partitions.length;
                            process.CurrentStep.ActiveElement.WallIndex = partition;
                            process.photo2savane.Photo2World.BuildSlopesPartition(process.Pixels, process.SlopesData);

                            (process.photo2savane.Photo2World.Model.Partitions as any)[partition].selected = true;
                        }
                        break;
                    case 32: //space
                        if (this.inside) {
                            this.addElement(this.storedProcessPosition, event.shiftKey);
                        }
                        event.preventDefault();
                        break;
                    case 8: //backspace
                    case 46:
                        if (this.inside) {
                            this.removeElement();
                        }
                        break;
                    case 71: //G
                        if (this.inside) {
                            this.resetTransfrom();
                        }
                        break;
                    case 13: // Entrée
                        if (!this.isProcessEnded()) {
                            this.nextStep();
                        }
                        else {
                            this.validateProcess();
                        }
                        break;
                }
                break;
            default:
                switch (event.keyCode) {
                    case 37: //Left
                        process.photo2savane.Photo2World.OffsetFuX -= 1;
                        this.remagnetize();
                        break;
                    case 39: //Right
                        process.photo2savane.Photo2World.OffsetFuX += 1;
                        this.remagnetize();
                        break;
                    case 38: //Up
                        process.photo2savane.Photo2World.OffsetFuY -= 0.1;
                        this.remagnetize();
                        break;
                    case 40: //Down
                        process.photo2savane.Photo2World.OffsetFuY += 0.1;
                        this.remagnetize();
                        break;
                    case 90: //Z
                        process.photo2savane.Photo2World.OffsetFvY -= 0.1;
                        this.remagnetize();
                        break;
                    case 88: //X
                        process.photo2savane.Photo2World.OffsetFvY += 0.1;
                        this.remagnetize();
                        break;
                    case 81: //Q
                        process.photo2savane.Photo2World.OffsetFvX -= 1;
                        this.remagnetize();
                        break;
                    case 68: //D
                        process.photo2savane.Photo2World.OffsetFvX += 1;
                        this.remagnetize();
                        break;
                    case 80: //P
                        process.photo2savane.Photo2World.VerticalShiftOffset += 0.0005;
                        break;
                    case 77: //M
                        process.photo2savane.Photo2World.VerticalShiftOffset -= 0.0005;
                        break;
                    case 71: //G
                        if (this.inside) {
                            this.resetTransfrom();
                        }
                        break;
                    case 13: // Entrée
                        if (!this.isProcessEnded()) {
                            this.nextStep();
                        }
                        else {
                            this.validateProcess();
                        }
                        break;
                }
        }

        this.draw();
        this.updatePhoto2Savane();
    };

    keyUpGlobal(event: { keyCode: number; }) {
        if (event.keyCode === 17) {
            this.ctrlPressed = false;
        }
    };

    cleanUndoRedo() {
        this.undoStack = [];
        this.redoStack = [];
    };

    startPhotosWatcher() {
        setInterval(() => {
            let scope = this.scope;
            if (scope.photos) {
                if (!this.arraysEqual(scope.photos, this.photos)) {
                    this.photos = [...scope.photos];
                    this.setCurrentPhoto(this.photos.length - 1);
                    ToolBarWidget.instance?.updatePhotoList();
                }
            }
        }, 500);
    };

    arraysEqual(arr1: any, arr2: any[]) {
        return JSON.stringify(arr1) === JSON.stringify(arr2);
    };

    startSmartDrawWatcher() {
        setInterval(() => {
            if (this.smartDrawPhotoEnabled !== this.previousSmartDrawPhotoEnabled) {
                this.previousSmartDrawPhotoEnabled = this.smartDrawPhotoEnabled;
                this.onSmartDrawPhotoEnabledChange();
            }
        }, 500);
    };

    onSmartDrawPhotoEnabledChange() {
        SavaneJS.SavaneCookie.setCookie("Rhinov-SmartDrawPhoto", this.smartDrawPhotoEnabled ? "1" : "0");
        this.detectLabels();
    };

    loadJoineryAssets() {
        let joineryRequests = [
            { type: "Door", value: 0 },
            { type: "Door_Double", value: 1 },
            { type: "Door_Front", value: 2 },
            { type: "Cupboard_Door", value: 11 },
            { type: "Garage_Door", value: 12 },
            { type: "Niche", value: 13 },
            { type: "Window", value: 4 },
            { type: "Window_Double", value: 5 },
            { type: "French_Window", value: 6 },
            { type: "French_Window_Double", value: 7 },
            { type: "Picture_Window", value: 8 },
            { type: "Fixed_Window", value: 17 },
            { type: "Velux", value: 19 },
            { type: "Canopy", value: 9 },
            { type: "opening", value: 10 },
        ];

        let technicalElementRequests = [
            { type: "stove", value: 15 },
            { type: "fireplace", value: 18 },
            { type: "radiator", value: 14 },
            { type: "air-conditioner", value: 16 },
            { type: "beam", value: 20 },
            { type: "boiler", value: 21 },
            { type: "Rosette", value: 22 },
            { type: "spotLight", value: 23 },
            { type: "circularPole", value: 24 },
            { type: "rectangularPole", value: 25 },
            { type: "electrical outlet", value: 26 },
            { type: "wallDecoration", value: 27 },
        ];

        let arrangementsRequests = [
            { type: "5df89bb3c5b06666d1a38703", value: 28 },
        ];

        this.processRequests(joineryRequests, AssetManagerServices._ASSET_TYPE.JOINERIES);
        this.processRequests(technicalElementRequests, AssetManagerServices._ASSET_TYPE.TECHNICAL_ELEMENTS);
        this.processRequests(arrangementsRequests, AssetManagerServices._ASSET_TYPE.ARRANGEMENTS, true);
    };

    processRequests(requests: { type: string; value: number; }[], assetType: any, isArrangements = false) {
        requests.forEach(({ type, value }) => {
            let query = "";

            if (isArrangements) {
                query = `&q.manufacturer.in=${type}&q.availableOnDraw.eq=true&q.pipeline.servicesStatus.in=597209833e587d2388906573`;
            } else if (assetType === AssetManagerServices._ASSET_TYPE.TECHNICAL_ELEMENTS) {
                query = `&q.technicalElementType.name.in=${type}&q.pipeline.state.eq=available&q.pipeline.modelState.eq=validated&q.medias.lk=vlp`;
            } else {
                query = `&q.joineryType.name.in=${type}&q.pipeline.state.eq=available&q.pipeline.modelState.eq=validated&q.medias.lk=vlp`;
            }

            AssetManagerServices.getAssets(assetType, this.OPTIMIZED_DB_FIELDS + query, (res) => {
                let resources = res.data.resources;
                if (resources && resources.length > 0) {
                    let index = JOINERY_TYPES.findIndex(x => x.value === value);
                    if (index !== -1) {
                        JOINERY_TYPES[index].assetList = resources;
                    }
                }
            });
        });
    };

    initProcessSavedListener() {
        this.initProcessSavedListener = Savane.eventsManager.instance.addListener("PROCESS_SAVED", (data: { userData: { photo_to_savane_data: any; photo: { id: number; }; }; }) => {
            if (this.CurrentProjectService) {
                let datas = {
                    photo_to_savane_data: data.userData.photo_to_savane_data
                };

                this.service().patch(this.routeParams.deliverableId, data.userData.photo.id, datas, (res: boolean) => {
                    this.toastr.clear();
                    if (res !== true) {
                        this.toastr.error("Impossible de sauvegarder le process");
                    } else {
                        this.toastr.success("Sauvegarde effectuée");
                    }
                });
            }
        });
    };

    initPhotoTerminatedListener() {
        this.initPhotoTerminatedListener = Savane.eventsManager.instance.addListener("PHOTO_TERMINATED", (data: { userData: any; }) => {
            if (this.CurrentProjectService) {
                this.CurrentProjectService.get(this.routeParams.realPropertyId, undefined, undefined, () => {
                    Savane.eventsManager.instance.dispatch(SavaneJS.Events.PHOTO2SAVANE_FINISHED, data.userData);
                });
            }
        });
    };

    initFinishPhoto2SavaneListener() {
        this.initFinishPhoto2SavaneListener = Savane.eventsManager.instance.addListener("FINISH_PHOTO2SAVANE", (data: { userData: any; }) => {
            if (this.CurrentProjectService) {
                let patchObj = {};
                patchObj[`deliverables[${this.routeParams.deliverableId}]`] = data.userData;

                this.CurrentProjectService.patch(this.routeParams.realPropertyId, this.routeParams.deliverableId, patchObj, (status: number) => {
                    this.toastr.clear();
                    if (status === 202) {
                        this.toastr.success('Projet sauvegardé');
                    } else {
                        this.toastr.error('Impossible de mettre à jour votre projet');
                    }
                });
            }
        });
    };

    timerTick() {
        if (this.scope.world) {
            this.scope.world.timerTick(0, this.scope.isCR, this.scope.isVisioDesignerCR);
        }
    };

    destroy() {
        document.getElementById('extract-container')?.removeEventListener('keyup', this.keyUpGlobal);
        window.removeEventListener("keyup", this.keyUpGlobal.bind(this));
        window.removeEventListener("keydown", this.keyDownGlobal.bind(this));
        window.removeEventListener('mousedown', this.timerTick.bind(this));
        window.removeEventListener('wheel', this.timerTick.bind(this));

        Savane.eventsManager.instance.removeListener(this.initProcessSavedListener);
        Savane.eventsManager.instance.removeListener(this.initPhotoTerminatedListener);
        Savane.eventsManager.instance.removeListener(this.initFinishPhoto2SavaneListener);

        document.body.classList.remove('photo2savane-stop-scrolling');

        if (this._photo3D) {
            this._photo3D.Dispose();
        }
    };
}
