import { Photo2Savane } from '@rhinov/savane-js';
import * as THREE from 'three';
import { PhotoSteps } from '../Photo2Savane/PhotoSteps';
import { WallFactory } from './Factory/WallFactory';
import { WallJoineriesFactory } from './Factory/WallJoineriesFactory';
import { SlopeFactory } from './Factory/SlopeFactory';
import { PartitionFactory } from './Factory/PartitionFactory';

export class Photo3D {
    private _width: number;
    private _height: number;
    private _container: HTMLElement;

    private _scene: THREE.Scene;
    private _camera: THREE.PerspectiveCamera;

    private _cameraHelper: THREE.CameraHelper;

    private _renderer: THREE.WebGLRenderer;

    private _red: THREE.MeshBasicMaterial;
    private _redWireframe: THREE.LineBasicMaterial;

    private _purple: THREE.MeshBasicMaterial;
    private _purpleWireframe: THREE.LineBasicMaterial;

    private _green: THREE.MeshBasicMaterial;
    private _greenWireframe: THREE.LineBasicMaterial;

    private _blue: THREE.MeshBasicMaterial;
    private _blueWireframe: THREE.LineBasicMaterial;

    private _yellow: THREE.MeshBasicMaterial;
    private _yellowWireframe: THREE.LineBasicMaterial;

    private _selected: THREE.MeshBasicMaterial;

    private _objects3D: THREE.Mesh[] = [];
    private _wireframeObjects3D: THREE.Line[] = [];

    constructor(width: number, height: number, container: HTMLElement) {
        this._width = width;
        this._height = height;
        this._container = container;

        this._scene = new THREE.Scene();
        this._camera = new THREE.PerspectiveCamera(45, this._width / this._height, 1, 10000000);

        this._cameraHelper = new THREE.CameraHelper(this._camera);
        this._scene.add(this._cameraHelper);

        this._renderer = new THREE.WebGLRenderer({alpha : true});
        this._renderer.setSize(this._width, this._height);
        container.appendChild(this._renderer.domElement);
        this._renderer.domElement.id = "webgl_container";
        this._renderer.domElement.className += "rt-overlay";

        this._red = new THREE.MeshBasicMaterial({side: THREE.DoubleSide, color: 'red', transparent: true, opacity: 0.05, depthWrite: false});
        this._redWireframe = new THREE.LineBasicMaterial({color: 'red'});

        this._purple = new THREE.MeshBasicMaterial({side: THREE.DoubleSide, color: 'fuchsia', transparent: true, opacity: 0.05, depthWrite: false});
        this._purpleWireframe = new THREE.LineBasicMaterial({color: 'fuchsia'});

        this._green = new THREE.MeshBasicMaterial({side: THREE.DoubleSide, color: 'green', transparent: true, opacity: 0.05, depthWrite: false});
        this._greenWireframe = new THREE.LineBasicMaterial({color: 'green'});

        this._blue = new THREE.MeshBasicMaterial({side: THREE.DoubleSide, color: 'blue', transparent: true, opacity: 0.05, depthWrite: false});
        this._blueWireframe = new THREE.LineBasicMaterial({color: 'blue'});

        this._yellow = new THREE.MeshBasicMaterial({side: THREE.DoubleSide, color: 'yellow', transparent: true, opacity: 0.05, depthWrite: false});
        this._yellowWireframe = new THREE.LineBasicMaterial({color: 'yellow'});

        this._selected = new THREE.MeshBasicMaterial({side: THREE.DoubleSide, color: 'cyan', transparent: true, opacity: 0.1, depthWrite: false});

        this._objects3D = [];
        this._wireframeObjects3D = [];
    }

    _disposeGeometries() {
        for (var i = 0; i < this._objects3D.length; ++i) {
            var object = this._objects3D[i];
            this._scene.remove(object);
            object.geometry.dispose();
        }

        for (var i = 0; i < this._wireframeObjects3D.length; ++i) {
            var wireframeObject = this._wireframeObjects3D[i];
            this._scene.remove(wireframeObject);
            wireframeObject.geometry.dispose();
        }
    }

    Rebuild(photo2savane: Photo2Savane, step: number, context2d: CanvasRenderingContext2D, zoom: number) {
        this._disposeGeometries();
        this._objects3D = [];
        this._wireframeObjects3D = [];

        if (step >= PhotoSteps.StepOrder.indexOf(PhotoSteps.StepType.Outline)) {
            var walls = WallFactory.Build(photo2savane.Photo2World.Model.Walls, this._red, this._redWireframe, photo2savane.Photo2World, context2d, zoom);
            if (walls) {
                this._scene.add(walls.object);
                this._scene.add(walls.wireframe);

                this._objects3D.push(walls.object);
                this._wireframeObjects3D.push(walls.wireframe);
            }
        }

        if (step >= PhotoSteps.StepOrder.indexOf(PhotoSteps.StepType.Joineries)) {
            var wallJoineries = WallJoineriesFactory.Build(photo2savane.Photo2World.Model.WallJoineries, this._green, this._greenWireframe, this._greenWireframe, this._blueWireframe, photo2savane.Photo2World, context2d, zoom);
            if (wallJoineries) {
                this._scene.add(wallJoineries.object);
                this._scene.add(wallJoineries.wireframe);
                this._scene.add(wallJoineries.middle);
                this._scene.add(wallJoineries.helper);

                this._objects3D.push(wallJoineries.object);
                this._wireframeObjects3D.push(wallJoineries.wireframe);
                this._wireframeObjects3D.push(wallJoineries.middle);
                this._wireframeObjects3D.push(wallJoineries.helper);
            }
        }

        if (step >= PhotoSteps.StepOrder.indexOf(PhotoSteps.StepType.Slope)) {
            var slopes = SlopeFactory.Build(photo2savane.Photo2World.Model.Slopes, this._blue, this._blueWireframe);
            if (slopes) {
                this._scene.add(slopes.object);
                this._scene.add(slopes.wireframe);

                this._objects3D.push(slopes.object);
                this._wireframeObjects3D.push(slopes.wireframe);
            }
        }

        if (step >= PhotoSteps.StepOrder.indexOf(PhotoSteps.StepType.SlopePartition)) {
            var slopes = SlopeFactory.Build(photo2savane.Photo2World.Model.SlopesPartition, this._yellow, this._yellowWireframe);
            if (slopes) {
                this._scene.add(slopes.object);
                this._scene.add(slopes.wireframe);

                this._objects3D.push(slopes.object);
                this._wireframeObjects3D.push(slopes.wireframe);
            }
        }

        if (step >= PhotoSteps.StepOrder.indexOf(PhotoSteps.StepType.OutlinePartition)) {
            var partitions = PartitionFactory.Build(photo2savane.Photo2World.Model.Partitions, this._green, this._greenWireframe, this._selected);
            if (partitions) {
                for (var i = 0; i < partitions.objects.length; ++i) {
                    this._scene.add(partitions.objects[i]);
                    this._scene.add(partitions.wireframes[i]);

                    this._objects3D.push(partitions.objects[i]);
                    this._wireframeObjects3D.push(partitions.wireframes[i]);
                }
            }
        }

        if (step >= PhotoSteps.StepOrder.indexOf(PhotoSteps.StepType.JoineriesPartition)) {
            var partitionJoineries = WallJoineriesFactory.Build(photo2savane.Photo2World.Model.PartitionJoineries, this._purple, this._purpleWireframe, this._greenWireframe, this._blueWireframe, photo2savane.Photo2World, context2d, zoom);
            if (partitionJoineries) {
                this._scene.add(partitionJoineries.object);
                this._scene.add(partitionJoineries.wireframe);
                this._scene.add(partitionJoineries.middle);
                this._scene.add(partitionJoineries.helper);

                this._objects3D.push(partitionJoineries.object);
                this._wireframeObjects3D.push(partitionJoineries.wireframe);
                this._wireframeObjects3D.push(partitionJoineries.middle);
                this._wireframeObjects3D.push(partitionJoineries.helper);
            }
        }
    }

    Render(photo2savane: Photo2Savane) {
        this._camera.fov = photo2savane.Photo2World.FovY;
        this._camera.updateProjectionMatrix();
        var camera2image = photo2savane.Photo2World.Camera2Image;
        this._camera.projectionMatrix.set(
            camera2image[0], camera2image[4], camera2image[8], camera2image[12],
            camera2image[1], camera2image[5], camera2image[9], camera2image[13],
            camera2image[2], camera2image[6], camera2image[10], camera2image[14],
            camera2image[3], camera2image[7], camera2image[11], camera2image[15]);

        if (this._objects3D.length === 0) {
            this._renderer.clear();
            this._cameraHelper.update();
            this._renderer.render(this._scene, this._camera);
            return;
        }

        var camera2world = photo2savane.Photo2World.Camera2World;
        this._camera.matrixAutoUpdate = false;
        this._camera.matrix.set(
            camera2world[0], camera2world[4], camera2world[8], camera2world[12],
            camera2world[1], camera2world[5], camera2world[9], camera2world[13],
            camera2world[2], camera2world[6], camera2world[10], camera2world[14],
            camera2world[3], camera2world[7], camera2world[11], camera2world[15]);
        this._camera.updateMatrixWorld(true);

        this._cameraHelper.update();

        this._renderer.render(this._scene, this._camera);
    }

    Resize(width: number, height: number) {
        this._width = width;
        this._height = height;

        this._renderer.setSize(this._width, this._height);
        this._renderer.render(this._scene, this._camera);
    }

    Translate(x: string | number, y: string | number) {
        this._renderer.domElement.style.left = x + 'px';
        this._renderer.domElement.style.top = y + 'px';
    }

    Dispose() {
        this._disposeGeometries();

        this._container.removeChild(this._renderer.domElement);
        this._renderer.dispose();
        this._red.dispose();
        this._redWireframe.dispose();
        this._purple.dispose();
        this._purpleWireframe.dispose();
        this._green.dispose();
        this._greenWireframe.dispose();
        this._blue.dispose();
        this._blueWireframe.dispose();
        this._yellow.dispose();
        this._yellowWireframe.dispose();
        this._selected.dispose();
    }

}
