import * as THREE from 'three';
import * as SavaneJS from '@rhinov/savane-js';
export class WallJoineriesFactory {

    static Build(joineries: string | any[], material: THREE.MeshBasicMaterial, wireframeMaterial: THREE.LineBasicMaterial, greenWireframe: THREE.LineBasicMaterial, blueWireframe: THREE.LineBasicMaterial, photo2world, context2d: CanvasRenderingContext2D, zoom: number) {
        if (joineries.length === 0) {
            return null;
        }

        var nbVertices = 0;
        for (var i = 0; i < joineries.length; ++i) {
            nbVertices += 6;
            if (joineries[i].Transom) {
                nbVertices += 6;
            }
            if (joineries[i].BottomTransom) {
                nbVertices += 6;
            }
        }

        var vertices = new Float32Array(nbVertices * 3 * joineries.length);
        var middleGeometryVertices = new Float32Array(2 * 3 * joineries.length);
        var helperGeometryVertices = new Float32Array(2 * 3 * joineries.length);

        var index = 0, mgIndex = 0, hgIndex = 0;
        for (var i = 0; i < joineries.length; ++i) {
            var joinery = joineries[i];

            var v1: SavaneJS.Math.glMatrix.vec3 = SavaneJS.Math.glMatrix.vec3.create(), v2: SavaneJS.Math.glMatrix.vec3 = SavaneJS.Math.glMatrix.vec3.create(), v3: SavaneJS.Math.glMatrix.vec3 = SavaneJS.Math.glMatrix.vec3.create(), v4: SavaneJS.Math.glMatrix.vec3 = SavaneJS.Math.glMatrix.vec3.create();
            var fontSize = 14 / zoom;
            switch (joinery.Type) {
                case 'WALL':
                    v1 = SavaneJS.Math.glMatrix.vec3.clone(joinery.A);
                    v1[2] = joinery.BottomHeight;
                    v2 = SavaneJS.Math.glMatrix.vec3.clone(joinery.A);
                    v2[2] = joinery.TopHeight;
                    v4 = SavaneJS.Math.glMatrix.vec3.clone(joinery.B);
                    v4[2] = joinery.BottomHeight;
                    v3 = SavaneJS.Math.glMatrix.vec3.clone(joinery.B);
                    v3[2] = joinery.TopHeight;

                    var v2v3 = SavaneJS.Math.glMatrix.vec3.create();
                    SavaneJS.Math.glMatrix.vec3.subtract(v2v3, v3, v2);
                    var topPosition2d = photo2world.Project(SavaneJS.Math.glMatrix.vec3.fromValues((v2[0] + v3[0]) / 2, (v2[1] + v3[1]) / 2, (v2[2] + v3[2]) / 2));
                    var v1v2 = SavaneJS.Math.glMatrix.vec3.create();
                    SavaneJS.Math.glMatrix.vec3.subtract(v1v2, v2, v1);
                    var sidePosition2d = photo2world.Project(SavaneJS.Math.glMatrix.vec3.fromValues((v2[0] + v1[0]) / 2, (v2[1] + v1[1]) / 2, (v2[2] + v1[2]) / 2));
                    context2d.save();
                    context2d.fillStyle = "green";
                    context2d.textAlign = "center";
                    context2d.font = fontSize + "px Arial";
                    context2d.fillText((SavaneJS.Math.glMatrix.vec3.length(v2v3) / 10).toFixed(0), topPosition2d[0], topPosition2d[1] - 5 / zoom);
                    context2d.fillText((SavaneJS.Math.glMatrix.vec3.length(v1v2) / 10).toFixed(0), sidePosition2d[0] - 15 / zoom, sidePosition2d[1]);
                    context2d.restore();
                    break;
                case 'SLOPE':
                    v1 = SavaneJS.Math.glMatrix.vec3.clone(joinery.A);
                    v2 = SavaneJS.Math.glMatrix.vec3.clone(joinery.B);
                    v4 = SavaneJS.Math.glMatrix.vec3.clone(joinery.C);
                    v3 = SavaneJS.Math.glMatrix.vec3.clone(joinery.D);

                    var v3v4 = SavaneJS.Math.glMatrix.vec3.create();
                    SavaneJS.Math.glMatrix.vec3.subtract(v3v4, v4, v3);
                    var topPosition2d = photo2world.Project(SavaneJS.Math.glMatrix.vec3.fromValues((v4[0] + v3[0]) / 2, (v4[1] + v3[1]) / 2, (v4[2] + v3[2]) / 2));
                    var v1v4 = SavaneJS.Math.glMatrix.vec3.create();
                    SavaneJS.Math.glMatrix.vec3.subtract(v1v4, v4, v1);
                    var sidePosition2d = photo2world.Project(SavaneJS.Math.glMatrix.vec3.fromValues((v4[0] + v1[0]) / 2, (v4[1] + v1[1]) / 2, (v4[2] + v1[2]) / 2));
                    context2d.save();
                    context2d.fillStyle = "green";
                    context2d.textAlign = "center";
                    context2d.font = fontSize + "px Arial";
                    context2d.fillText((SavaneJS.Math.glMatrix.vec3.length(v3v4) / 10).toFixed(0), topPosition2d[0], topPosition2d[1] - 5 / zoom);
                    context2d.fillText((SavaneJS.Math.glMatrix.vec3.length(v1v4) / 10).toFixed(0), sidePosition2d[0] - 15 / zoom, sidePosition2d[1]);
                    context2d.restore();
                    break;
                case 'GROUND':
                    v1 = SavaneJS.Math.glMatrix.vec3.clone(joinery.A);
                    v2 = SavaneJS.Math.glMatrix.vec3.clone(joinery.A);
                    v2[1] = joinery.B[1];
                    v4 = SavaneJS.Math.glMatrix.vec3.clone(joinery.B);
                    v4[1] = joinery.A[1];
                    v3 = SavaneJS.Math.glMatrix.vec3.clone(joinery.B);

                    var v2v3 = SavaneJS.Math.glMatrix.vec3.create();
                    SavaneJS.Math.glMatrix.vec3.subtract(v2v3, v3, v2);
                    var topPosition2d = photo2world.Project(SavaneJS.Math.glMatrix.vec3.fromValues((v2[0] + v3[0]) / 2, (v2[1] + v3[1]) / 2, (v2[2] + v3[2]) / 2));
                    var v1v2 = SavaneJS.Math.glMatrix.vec3.create();
                    SavaneJS.Math.glMatrix.vec3.subtract(v1v2, v2, v1);
                    var sidePosition2d = photo2world.Project(SavaneJS.Math.glMatrix.vec3.fromValues((v2[0] + v1[0]) / 2, (v2[1] + v1[1]) / 2, (v2[2] + v1[2]) / 2));
                    context2d.save();
                    context2d.fillStyle = "green";
                    context2d.textAlign = "center";
                    context2d.font = fontSize + "px Arial";
                    context2d.fillText((SavaneJS.Math.glMatrix.vec3.length(v2v3) / 10).toFixed(0), topPosition2d[0], topPosition2d[1] - 5 / zoom);
                    context2d.fillText((SavaneJS.Math.glMatrix.vec3.length(v1v2) / 10).toFixed(0), sidePosition2d[0] - 15 / zoom, sidePosition2d[1]);
                    context2d.restore();
                    break;
                default:
                    continue;
            }

            vertices[index++] = v1[0]; vertices[index++] = v1[1]; vertices[index++] = v1[2];
            vertices[index++] = v3[0]; vertices[index++] = v3[1]; vertices[index++] = v3[2];
            vertices[index++] = v2[0]; vertices[index++] = v2[1]; vertices[index++] = v2[2];

            vertices[index++] = v3[0]; vertices[index++] = v3[1]; vertices[index++] = v3[2];
            vertices[index++] = v1[0]; vertices[index++] = v1[1]; vertices[index++] = v1[2];
            vertices[index++] = v4[0]; vertices[index++] = v4[1]; vertices[index++] = v4[2];

            middleGeometryVertices[mgIndex++] = (v2[0] + v3[0]) / 2;
            middleGeometryVertices[mgIndex++] = (v2[1] + v3[1]) / 2;
            middleGeometryVertices[mgIndex++] = (v2[2] + v3[2]) / 2;
            middleGeometryVertices[mgIndex++] = (v1[0] + v4[0]) / 2;
            middleGeometryVertices[mgIndex++] = (v1[1] + v4[1]) / 2;
            middleGeometryVertices[mgIndex++] = (v1[2] + v4[2]) / 2;


            helperGeometryVertices[hgIndex++] = (v1[0] + v2[0] + v3[0] + v4[0]) / 4;
            helperGeometryVertices[hgIndex++] = (v1[1] + v2[1] + v3[1] + v4[1]) / 4;
            helperGeometryVertices[hgIndex++] = (v1[2] + v2[2] + v3[2] + v4[2]) / 4;
            helperGeometryVertices[hgIndex++] = (v3[0] + v4[0]) / 2;
            helperGeometryVertices[hgIndex++] = (v3[1] + v4[1]) / 2;
            helperGeometryVertices[hgIndex++] = (v3[2] + v4[2]) / 2;

            if (joinery.Transom) {
                var v5: SavaneJS.Math.glMatrix.vec3 = SavaneJS.Math.glMatrix.vec3.create(), v6: SavaneJS.Math.glMatrix.vec3 = SavaneJS.Math.glMatrix.vec3.create();

                v5 = SavaneJS.Math.glMatrix.vec3.clone(joinery.A);
                v5[2] = joinery.TransomHeight * 10;
                v6 = SavaneJS.Math.glMatrix.vec3.clone(joinery.B);
                v6[2] = joinery.TransomHeight * 10;

                vertices[index++] = v2[0]; vertices[index++] = v2[1]; vertices[index++] = v2[2];
                vertices[index++] = v5[0]; vertices[index++] = v5[1]; vertices[index++] = v5[2];
                vertices[index++] = v6[0]; vertices[index++] = v6[1]; vertices[index++] = v6[2];

                vertices[index++] = v3[0]; vertices[index++] = v3[1]; vertices[index++] = v3[2];
                vertices[index++] = v5[0]; vertices[index++] = v5[1]; vertices[index++] = v5[2];
                vertices[index++] = v6[0]; vertices[index++] = v6[1]; vertices[index++] = v6[2];
            }

            if (joinery.BottomTransom) {
              var v5: SavaneJS.Math.glMatrix.vec3 = SavaneJS.Math.glMatrix.vec3.create(), v6: SavaneJS.Math.glMatrix.vec3 = SavaneJS.Math.glMatrix.vec3.create();

              v5 = SavaneJS.Math.glMatrix.vec3.clone(joinery.A);
              v5[2] = joinery.BottomTransomHeight * 10;
              v6 = SavaneJS.Math.glMatrix.vec3.clone(joinery.B);
              v6[2] = joinery.BottomTransomHeight * 10;

              vertices[index++] = v1[0]; vertices[index++] = v1[1]; vertices[index++] = v1[2];
              vertices[index++] = v5[0]; vertices[index++] = v5[1]; vertices[index++] = v5[2];
              vertices[index++] = v6[0]; vertices[index++] = v6[1]; vertices[index++] = v6[2];

              vertices[index++] = v4[0]; vertices[index++] = v4[1]; vertices[index++] = v4[2];
              vertices[index++] = v5[0]; vertices[index++] = v5[1]; vertices[index++] = v5[2];
              vertices[index++] = v6[0]; vertices[index++] = v6[1]; vertices[index++] = v6[2];
            }
        }

        var geometry = new THREE.BufferGeometry();
        geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
        var object = new THREE.Mesh(geometry, material);

        var wireframeGeometry = new THREE.WireframeGeometry(geometry);
        var wireframe = new THREE.LineSegments(wireframeGeometry, wireframeMaterial);

        var middleGeometry = new THREE.BufferGeometry();
        middleGeometry.setAttribute('position', new THREE.BufferAttribute(middleGeometryVertices, 3));
        var helperGeometry = new THREE.BufferGeometry();
        helperGeometry.setAttribute('position', new THREE.BufferAttribute(helperGeometryVertices, 3));
        var middle = new THREE.LineSegments(middleGeometry, greenWireframe);
        var helper = new THREE.LineSegments(helperGeometry, blueWireframe);

        return {
            object: object,
            wireframe: wireframe,
            middle: middle,
            helper: helper
        }
    }

}
