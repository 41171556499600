export abstract class Step {
    private _done: boolean;
    private _stepType: any;

    constructor(stepType) {
        this._done = false;
        this._stepType = stepType;
    }

    get IsDone(): boolean {
        return this._done;
    }

    set isDone(value: boolean) {
        this._done = value;
    }

    Done() {
        this._done = true;
    }

    abstract Deserialize(datas: any): void;
    abstract Serialize(): any;

    get StepType(): any {
        return this._stepType;
    }

    set StepType(value) {
        this._stepType = value;
    }

}