import { ToolBarWidget } from './ToolBarWidget';
import { ModelSidebarWidget } from './ModelSidebarWidget';
import { Photo2SavaneController } from '../Photo2SavaneController';
import { IRootScope, IRouteParams } from './Externals';

export class Photo2SavaneWidget {
    parentElement?: HTMLElement;
    photo2savanecontroller?: Photo2SavaneController;

    static instantiate(parentElement: HTMLElement, scope: object, routeParams: IRouteParams, toastr: Toastr,
        rootScope: IRootScope, HP_CONFIG: any, COCOS_PLAN_MODULE_PATH: string, CurrentProjectService: any, savaneHttpManager: any) {
        return new Photo2SavaneWidget(parentElement, scope, routeParams, toastr, rootScope, HP_CONFIG,
            COCOS_PLAN_MODULE_PATH, CurrentProjectService, savaneHttpManager);
    }

    constructor(parentElement: HTMLElement, scope: object, routeParams: IRouteParams,
        toastr: Toastr, rootScope: IRootScope, HP_CONFIG: any, COCOS_PLAN_MODULE_PATH: string, CurrentProjectService: any, savaneHttpManager: any) {
        this.parentElement = parentElement;
        this.photo2savanecontroller = new Photo2SavaneController(scope, routeParams, toastr, rootScope, HP_CONFIG,
            COCOS_PLAN_MODULE_PATH, CurrentProjectService, savaneHttpManager);
        ToolBarWidget.instantiate(this.photo2savanecontroller);
        ModelSidebarWidget.instantiate(this.photo2savanecontroller);
        parentElement.innerHTML = this.html();
        setTimeout(() => {
            ToolBarWidget.instance?.bindEvents();
            ToolBarWidget.callUpdates();
            ModelSidebarWidget.instance?.updateModelSidebar();
            this.photo2savanecontroller?.extractContainerListener();
        }, 600);
    }

    html() {
        return `
            <div id="extract-container" tabindex="1">
                ${ToolBarWidget.instance?.html()}
                ${ModelSidebarWidget.instance?.html()}
                <div id="photo2savane-canvas-container" class="lateral-container">
                    <canvas id="picture-canvas" picture-canvas width="100%" height="43%" style="width:100%; height:100%;"></canvas>
                </div>
            </div>
        `;
    }

    destroy() {
        console.log("Destroying Photo2SavaneWidget...")
        if (this.photo2savanecontroller) {
            Photo2SavaneController.savedProcesses = [...this.photo2savanecontroller.processes];
            this.photo2savanecontroller.destroy();
        }

        if (ToolBarWidget.instance) {
            ToolBarWidget.instance = undefined;
        }
        if (ModelSidebarWidget.instance) {
            ModelSidebarWidget.instance = undefined;
        }

        this.parentElement!.innerHTML = "";

        this.photo2savanecontroller = undefined;
        this.parentElement = undefined;

        console.log("Photo2SavaneWidget destroyed.");
    }
}