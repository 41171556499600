import { JOINERY_TYPES } from "../joineryTypes";
import { Photo2SavaneController } from "../Photo2SavaneController";
import { ModelSidebarWidget } from "./ModelSidebarWidget";

export class ToolBarWidget {
    photo2savanecontroller: Photo2SavaneController;
    constructor(controller: Photo2SavaneController) {
        this.photo2savanecontroller = controller;
    }

    static callUpdates() {
        ToolBarWidget.instance?.updatePhotoList();
        ToolBarWidget.instance?.updateStepElementValue();
        ToolBarWidget.instance?.updateToolBarWidget();
    }

    listenerDelay = 10;

    static instance?: ToolBarWidget;
    static instantiate(controller: Photo2SavaneController) {
        if (!ToolBarWidget.instance) {
            ToolBarWidget.instance = new ToolBarWidget(controller);
        }
    }

    updateToolBarWidget() {
        this.updateExtractActions();
        this.updateExtractSteps();
        this.bindEvents();
    }

    updateExtractSteps() {
        let container = document.getElementById("extract-steps");
        if (!container) return;
        container.innerHTML = this.getExtractStepsHtml();
    }

    getExtractStepsHtml() {
        return `
        <div class="row">
            ${!this.photo2savanecontroller.isProcessEnded() ? `
            <div id="step-name" class="lateral-container">
                ${this.photo2savanecontroller.stepName()}
            </div>
            ` : ''}
            ${!this.photo2savanecontroller.isProcessEnded() ? `
            <div id="step-infos" class="lateral-container">
                ${this.photo2savanecontroller.stepInfos()}
            </div>
            ` : ''}
            <div id="perspective_helpers_show"${this.photo2savanecontroller.canHidePerspectiveHelpers() ? '' : 'style="display:none;"'}>
                <input type="checkbox" id="perspective_helpers"
                ${this.photo2savanecontroller.hidePerspectiveHelpers ? "checked" : ""}>
                <label for="perspective_helpers">Masquer les lignes de fuites</label>
            </div>
            ${this.photo2savanecontroller.canDrawWallFromCeiling() ? ` 
            <div id="ceil_draw_if">
                <input type="checkbox" id="ceil_draw" 
                ${this.photo2savanecontroller.processes[this.photo2savanecontroller.currentPhotoIndex].photo2savane.Photo2World.CeilDraw ? 'checked' : ''}>
                <label for="ceil_draw">Tracer depuis le plafond</label>
            </div>
            ` : ''}
            ${this.photo2savanecontroller.canDrawPartitionFromCeiling() ? `
            <div id="ceil_partition_draw_if">
                <input type="checkbox" id="ceil_partition_draw"
                ${this.photo2savanecontroller.processes[this.photo2savanecontroller.currentPhotoIndex].photo2savane.Photo2World.CeilPartitionDraw ? 'checked' : ''}>
                <label for="ceil_partition_draw">Tracer depuis le plafond</label>
            </div>
            ` : ''}
        </div>
        <div id="step-element-value">
            ${this.updateStepElementValue()}
        </div>
        ${!this.photo2savanecontroller.isProcessEnded() ? `
        <div id="step-actions" class="lateral-container">
            <button id="step-actions-add" class="btn btn-primary">Add</button>
            <button id="step-actions-remove" class="btn btn-primary">Remove</button>
        </div>
        ` : ''}
        ${this.photo2savanecontroller.isProcessEnded() ? `
            <div id="step-infos" class="vertical-container">
                ${this.getStepInfosHtml()}
            </div>
        ` : ""}
        <button id="clearOffsets" class="btn btn-danger" title="Réinitialiser les offsets">D</button>
        `;
    }

    getStepInfosHtml() {
        let html = `
        <div>
            Ajuster la coque pour se superposer à l'image
        </div>
        <table>
            <tr style="font-size:12px;">
                <th style="padding-right: 10px;">vShift (P/M)</th>
                <th style="padding-right: 10px;">Fu X Offset (Left/Right)</th>
                <th style="padding-right: 10px;">Fu Y Offset (Up/Down)</th>
                <th style="padding-right: 10px;">Fv X Offset (Q/D)</th>
                <th style="padding-right: 10px;">Fv Y Offset (Z/S)</th>
                <th style="padding-right: 10px;">vFov</th>
                <th style="padding-right: 10px;">hFov</th>
                <th style="padding-right: 10px;">Renormaliser</th>
            </tr>
            <tr id="photo-values-container" style="font-size:12px; text-align: center;">
                ${this.updatePhotoValuesTable()}
            </tr>
        </table>
        `;

        return html;
    }

    updateExtractActions() {
        let container = document.getElementById("extract-actions");
        if (!container) return;
        container.innerHTML = this.getExtractActionsHtml();
    }

    getExtractActionsHtml() {
        return `
            <div id="step-actions">
                <button id="cleanProcess" class="btn btn-warning" title="Recommencer le tracé"><i class="material-icons icon-Delete"></i></button>
                ${!this.photo2savanecontroller.isProcessEnded() ? `<button id="extractCamera" class="btn btn-warning" title="Extraire les paramètres de la caméra vers le plan existant"><i class="material-icons icon-Camera"></i></button>` : ''}
                <div>
                <input type="checkbox" id="smart_draw_photo" 
                ${this.photo2savanecontroller.smartDrawPhotoEnabled ? "checked" : ""}>
                <label for="smart_draw_photo"> Activer Smart Draw Photo</label>
                <i id="i_smart_draw_photo" class="fas fa-sync-alt"
                style="margin-left: 10px; cursor: pointer;${this.photo2savanecontroller.smartDrawPhotoEnabled ? '' : 'display:none;'}" title="Recalculer Smart Draw Photo"></i>
                </div>
            </div>

            <div id="step-progress">
                <button id="previousStep" class="btn btn-primary">Précédent</button>
                ${!this.photo2savanecontroller.isProcessEnded() ? `<button id="nextStep" class="btn btn-primary">Suivant</button>` : ''}
                ${this.photo2savanecontroller.isProcessEnded() ? `<button id="finish" class="btn btn-success">Terminer</button>` : ''}
            </div>
        `;
    }

    html() {
        return `
            <div id="extract-toolbar" class="tools-navigation shadowed">
                <div id="extract-photo" class="lateral-container" style="overflow-x:auto;">
                    ${this.getPhotoListHtml()}
                </div>
                <div id="extract-steps"  class="lateral-container">
                    ${this.getExtractStepsHtml()}
                </div>
                <div id="extract-actions" class="lateral-container">
                    ${this.getExtractActionsHtml()}
                </div>
            </div>
        `;
    }

    bindEvents() {
        let eventMappings = [
            { id: 'step-actions-add', event: 'click', handler: 'stepActionsAddOnClick' },
            { id: 'step-actions-remove', event: 'click', handler: 'stepActionsRemoveOnClick' },
            { id: 'clearOffsets', event: 'click', handler: 'clearOffsetsOnClick' },
            { id: 'cleanProcess', event: 'click', handler: 'cleanProcessOnClick' },
            { id: 'extractCamera', event: 'click', handler: 'extractCameraOnClick' },
            { id: 'i_smart_draw_photo', event: 'click', handler: 'iSmartDrawPhotoOnClick' },
            { id: 'previousStep', event: 'click', handler: 'previousStepOnClick' },
            { id: 'nextStep', event: 'click', handler: 'nextStepOnClick' },
            { id: 'perspective_helpers', event: 'change', handler: 'hidePerspectiveHelpersInputOnChange' },
        ];

        eventMappings.forEach(({ id, event, handler }) => {
            let element = document.getElementById(id);
            if (!element) return;
            element.addEventListener(event, this[handler].bind(this));
        });

        if (this.photo2savanecontroller.isProcessEnded()) {
            this.photo2savanecontroller.bindFinishEvents();
        }

        this.photo2savanecontroller.bindPerspectiveHelpersEvent();
        this.photo2savanecontroller.bindSmartDrawPhotoEvent();
        this.photo2savanecontroller.bindCeilDrawEvent();
        this.photo2savanecontroller.bindCeilPartitionDrawEvent();
    }

    stepActionsAddOnClick() {
        this.photo2savanecontroller.addElement();
        ToolBarWidget.instance?.updateToolBarWidget();
    }

    clearOffsetsOnClick() {
        this.photo2savanecontroller.clearOffsets();
    }

    stepActionsRemoveOnClick() {
        this.photo2savanecontroller.removeElement();
        ToolBarWidget.instance?.updateToolBarWidget();
    }

    cleanProcessOnClick() {
        this.photo2savanecontroller.cleanProcess();
    }

    extractCameraOnClick() {
        this.photo2savanecontroller.extractCamera();
    }

    iSmartDrawPhotoOnClick() {
        this.photo2savanecontroller.detectLabels(true);
    }

    previousStepOnClick() {
        this.photo2savanecontroller.previousStep();
        ToolBarWidget.instance?.updateToolBarWidget();
        if (this.photo2savanecontroller.isTabShown()) {
            ModelSidebarWidget.instance?.updateModelSidebar();
        }
    }

    nextStepOnClick() {
        this.photo2savanecontroller.nextStep();        
    }

    flipJoineryOnClick() {
        this.photo2savanecontroller.flipJoinery();
    }

    hidePerspectiveHelpersInputOnChange(event: { target: { checked: boolean; }; }) {
        this.photo2savanecontroller.hidePerspectiveHelpers = event.target.checked;
        this.photo2savanecontroller.draw();
    }

    inputValueDistanceOnChange(value: number) {
        this.photo2savanecontroller.joineryObject.currentElementValue = value;
        this.photo2savanecontroller.setElementValue(this.photo2savanecontroller.joineryObject.currentElementValue);
    }

    inputValueHeightOnChange(value: number) {
        this.photo2savanecontroller.joineryObject.currentElementValue2 = value;
        this.photo2savanecontroller.setElementValue2(this.photo2savanecontroller.joineryObject.currentElementValue2);
    }

    selectorJoinTypeOnChange(value: string) {
        this.photo2savanecontroller.joineryObject.currentElementValueItem = value;
        this.photo2savanecontroller.setElementValue(this.photo2savanecontroller.joineryObject.currentElementValueItem);
    }

    forceRenormalizeOnChange(value: boolean) {
        this.photo2savanecontroller.processes[this.photo2savanecontroller.currentPhotoIndex].photo2savane.Photo2World.ForceRenormalize = value;
    }

    getPhotoListHtml() {
        let htmlContent = ``;
        this.photo2savanecontroller.photos.forEach((photo, i) => {
            let classname = '';
            if (i === this.photo2savanecontroller.currentPhotoIndex) classname += "selected ";
            if (this.photo2savanecontroller.isProcessInvalid(i)) classname += "invalidate ";
            if (this.photo2savanecontroller.isProcessDone(i)) classname += "done";
            htmlContent += `
            <div class="photoProcess ${classname}" id="photo-item-${i}">
                <img style="max-height:30px;width:auto;" src="${photo.path ? photo.path + photo.filename : photo}" />
            </div>
            `;
        });

        setTimeout(() => {
            this.photo2savanecontroller.addPhotosListeners();
        }, 500);

        return htmlContent;
    }

    updatePhotoList() {
        let extractPhotoElement = document.getElementById("extract-photo");
        if (extractPhotoElement) {
            extractPhotoElement.innerHTML = this.getPhotoListHtml();
        }
    }

    updateStepElementValue() {
        if (this.photo2savanecontroller.isProcessEnded()) {
            return "";
        }

        let requiredValue = this.photo2savanecontroller.requiredValue();

        let htmlContent = "";

        switch (requiredValue) {
            case 0:
                htmlContent = "<div>Aucune valeur requise</div>";
                break;
            case 1:
                htmlContent = ` 
            <div class="lateral-container">
                <div>H: </div>
                <input type="number" class="form-control" id="input-value-distance"
                    value="${this.photo2savanecontroller.joineryObject.currentElementValue || ''}"
                    ${this.photo2savanecontroller.stepElementsCount() <= 0 ? "disabled" : ""}/>
                <label for="input-value-distance">cm</label>
            </div>
        `;
                setTimeout(this.photo2savanecontroller.addInputValueDistanceListener, this.listenerDelay);
                break;
            case 2:
                htmlContent = `
            <div class="lateral-container">
                <div style="margin-right: 10px;">Type: </div>
                <select id="selector_joinType" class="form-control"
                    ${this.photo2savanecontroller.stepElementsCount() <= 0 ? "disabled" : ""}>
                    ${JOINERY_TYPES.map((jType: { value: number; name: string; }) =>
                    `<option value="${jType.value}" ${String(jType.value) === this.photo2savanecontroller.joineryObject.currentElementValueItem ? "selected" : ""}>
                            ${jType.name}
                        </option>`).join('')}
                </select>
                <div id="flipJoinery" style="margin-left: 10px;" title="Inverser la menuiserie">
                    <i class="fas fa-arrow-left" aria-hidden="true"></i>
                    <i class="fas fa-arrow-right" aria-hidden="true"></i>
                </div>
            </div>
        `;
                setTimeout(this.photo2savanecontroller.addJoinerySelectorListeners.bind(this.photo2savanecontroller), this.listenerDelay);
                break;
            case 3:
                htmlContent = `
            <div class="lateral-container">
                <div>P: </div>
                <input type="number" class="form-control" id="input-value-distance" style="max-width: 100px;"
                    value="${this.photo2savanecontroller.joineryObject.currentElementValue || ''}"
                    ${this.photo2savanecontroller.stepElementsCount() <= 0 ? "disabled" : ""}/>
                <label for="input-value-distance">cm</label>
                <div style="margin-left: 20px;">H: </div>
                <input type="number" class="form-control" id="input-value-height" style="max-width: 100px;"
                    value="${this.photo2savanecontroller.joineryObject.currentElementValue2 || ''}"
                    ${this.photo2savanecontroller.stepElementsCount() <= 0 ? "disabled" : ""}/>
                <label for="input-value-height">cm</label>
            </div>
        `;
                setTimeout(() => {
                    this.photo2savanecontroller.addInputValueDistanceListener();
                    this.photo2savanecontroller.addInputValueHeightListener();
                }, this.listenerDelay);
                break;
        }
        return htmlContent;
    }

    getPhotoValues() {
        return {
            VerticalShift: this.photo2savanecontroller.photoValue('VerticalShift', 100),
            OffsetFuX: this.photo2savanecontroller.photoValue('OffsetFuX', 1),
            OffsetFuY: this.photo2savanecontroller.photoValue('OffsetFuY', 1),
            OffsetFvX: this.photo2savanecontroller.photoValue('OffsetFvX', 1),
            OffsetFvY: this.photo2savanecontroller.photoValue('OffsetFvY', 1),
            FovY: this.photo2savanecontroller.photoValue('FovY', 1),
            FovX: this.photo2savanecontroller.photoValue('FovX', 1),
        };
    }

    updatePhotoValuesTable() {
        let photoValues = this.getPhotoValues();
        let htmlContent = "";

        htmlContent = `
            <td style="padding-right: 10px;">${photoValues.VerticalShift}%</td>
            <td style="padding-right: 10px;">${photoValues.OffsetFuX}px</td>
            <td style="padding-right: 10px;">${photoValues.OffsetFuY}px</td>
            <td style="padding-right: 10px;">${photoValues.OffsetFvX}px</td>
            <td style="padding-right: 10px;">${photoValues.OffsetFvY}px</td>
            <td style="padding-right: 10px;">${photoValues.FovY}°</td>
            <td style="padding-right: 10px;">${photoValues.FovX}°</td>
            <td style="padding-right: 10px;">
                <input type="checkbox" id="force_renormalize" ${this.photo2savanecontroller.processes[this.photo2savanecontroller.currentPhotoIndex]?.photo2savane.Photo2World.ForceRenormalize ? "checked" : ""}>
            </td>
        `;

        setTimeout(() => {
            this.photo2savanecontroller.addForceRenormalizeListener();
        }, this.listenerDelay);

        return htmlContent;
    }
}