import { Step } from './Step.js';
import { IMarkerPairDatas, MarkerPair } from '../MarkerPair.js';
import { Marker } from '../Marker.js';
import { StepType } from '../PhotoSteps.js';

interface ISerializeDatas {
    markersPairs: IMarkerPairDatas[];
    type: string;
    stepType: any;
    done: boolean;
    cameraDetected: boolean;
    joineriesDetected: boolean;
}

export class PairStep extends Step {

    private _pairs: MarkerPair[];
    private _selectedPair?: MarkerPair;
    private _activePair?: MarkerPair;
    private _cameraDetected: boolean;
    private _joineriesDetected: boolean;

    constructor(stepType?: any) {
        super(stepType);

        this._pairs = [];
        this._selectedPair;
        this._activePair;
        this._cameraDetected = false;
        this._joineriesDetected = false;
    }

    get Type(): string {
        return 'PairStep';
    }

    get SelectedPair(): MarkerPair {
        return this._selectedPair;
    }

    get Pairs(): MarkerPair[] {
        return this._pairs;
    }

    set Pairs(value: MarkerPair[]) {
        this._pairs = value;
    }

    get CameraDetected(): boolean {
        return this._cameraDetected;
    }

    set CameraDetected(value: boolean) {
        this._cameraDetected = value;
    }

    get JoineriesDetected(): boolean {
        return this._joineriesDetected;
    }

    set JoineriesDetected(value: boolean) {
        this._joineriesDetected = value;
    }

    get Count(): number {
        return this._pairs.length;
    }

    get ActiveElement(): MarkerPair {
        return this._activePair;
    }

    get SelectedElement(): MarkerPair {
        return this._selectedPair;
    }

    get ActiveElementIndex(): number {
        return this.ActiveElement ? this._pairs.indexOf(this.ActiveElement) : -1;
    }

    Add(x1: number, y1: number, x2?: number, y2?: number) {
        if (this.StepType !== StepType.SlopePartition && this.StepType !== StepType.JoineriesPartition) {
            // Default case
            var A = new Marker();
            A.Move(x1, y1);
            var B = new Marker();
            B.Move(x2, y2);
            var pair = new MarkerPair(A, B);
            pair.SelectMode = 'A';
            this._pairs.push(pair);
            this._activePair = pair;
            return pair;
        }

        // Slope Partition
        if (this._pairs.length === 0) {
            var A = new Marker();
            A.Move(x1, y1);
            var B = new Marker();
            B.Move(x2, y2);
            var pair = new MarkerPair(A, B);
            pair.SelectMode = 'A';
            this._pairs.push(pair);
            this._activePair = pair;
            return pair;
        }

        let selectedWall: number;
        if (this._activePair === undefined) selectedWall = 0;
        else selectedWall = this._activePair.WallIndex;

        var A = new Marker();
        A.Move(x1, y1);
        var B = new Marker();
        B.Move(x2, y2);
        var pair = new MarkerPair(A, B);
        pair.SelectMode = 'A';
        pair.WallIndex = selectedWall;
        this._pairs.push(pair);
        this._activePair = pair;
        return pair;
    }

    Insert(x1: number, y1: number, x2: number, y2: number, index?: number) {
        var A = new Marker();
        A.Move(x1, y1);
        var B = new Marker();
        B.Move(x2, y2);
        var pair = new MarkerPair(A, B);
        pair.SelectMode = 'A';
        this._pairs.splice(index + 1, 0, pair);
        this._activePair = pair;
        return pair;
    }

    RemoveActivePair() {
        if (this._activePair === undefined) {
            return;
        }

        var index = this._pairs.indexOf(this._activePair);
        this._pairs.splice(index, 1);
        this._activePair = undefined;
    }

    Select(x: number, y: number, zoom: number) {
        var candidates: MarkerPair[] = [];
        for (var i = 0; i < this._pairs.length; ++i) {
            var pair = this._pairs[i];
            if (pair.IsSelectable(x, y, zoom)) {
                candidates.push(pair);
            }
        }

        if (candidates.length === 0) {
            this._selectedPair = undefined;
            this._activePair = undefined;
            return false;
        }

        candidates.sort(function (a, b) {
            if (a.SelectMode === 'LINE' && b.SelectMode !== 'LINE') {
                return 1;
            }

            if (a.SelectMode !== 'LINE' && b.SelectMode === 'LINE') {
                return -1;
            }

            return 0;
        });

        this._selectedPair = candidates[0];
        var minDistance = this._selectedPair.Distance(x, y);
        for (var i = 1; i < candidates.length; ++i) {
            var current = candidates[i];
            var distance = current.Distance(x, y);
            if (this._selectedPair.SelectMode !== 'LINE' && current.SelectMode === 'LINE') {
                continue;
            }
            if (distance < minDistance) {
                minDistance = distance;
                this._selectedPair = current;
            }
        }

        this._activePair = this._selectedPair;
        return true;
    }

    Unselect() {
        this._selectedPair = undefined;
    }

    Move(x: number, y: number) {
        if (this._selectedPair === undefined) return;

        this._selectedPair.Move(x, y);
    }

    Draw(context: CanvasRenderingContext2D, zoom: number) {
        for (var i = 0; i < this._pairs.length; ++i) {
            this._pairs[i].Draw(context, zoom, this._pairs[i] === this._activePair);
        }
    }

    Serialize() {
        var result: IMarkerPairDatas[] = [];
        for (var i = 0; i < this._pairs.length; ++i) {
            result.push(this._pairs[i].Serialize());
        }
        return {
            markersPairs: result,
            type: this.Type,
            stepType: this.StepType,
            done: this.IsDone,
            cameraDetected: this._cameraDetected,
            joineriesDetected: this._joineriesDetected
        }
    }

    Deserialize(datas: ISerializeDatas) {
        for (var i = 0; i < datas.markersPairs.length; ++i) {
            var pair = new MarkerPair(undefined, undefined);
            pair.Deserialize(datas.markersPairs[i]);
            this._pairs.push(pair);
        }
        this.StepType = datas.stepType;
        this.isDone = datas.done;
        this._activePair = this._pairs[this._pairs.length - 1];
        this._cameraDetected = datas.cameraDetected;
        this._joineriesDetected = datas.joineriesDetected;
    }

}