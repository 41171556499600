import * as THREE from 'three';
import * as SavaneJS from '@rhinov/savane-js';

export class SlopeFactory {

    static Build(slopes: string | any[], material: THREE.MeshBasicMaterial, wireframeMaterial: THREE.LineBasicMaterial) {
        if (slopes.length === 0) {
            return null;
        }

        var vertices = new Float32Array(6 * 3 * slopes.length);

        var index = 0;
        for (var i = 0; i < slopes.length; ++i) {
            var slope = slopes[i];
            var v1 = SavaneJS.Math.glMatrix.vec3.clone(slope.A);
            var v2 = SavaneJS.Math.glMatrix.vec3.clone(slope.B);
            var v3 = SavaneJS.Math.glMatrix.vec3.clone(slope.D);
            var v4 = SavaneJS.Math.glMatrix.vec3.clone(slope.C);

            vertices[index++] = v1[0]; vertices[index++] = v1[1]; vertices[index++] = v1[2];
            vertices[index++] = v3[0]; vertices[index++] = v3[1]; vertices[index++] = v3[2];
            vertices[index++] = v2[0]; vertices[index++] = v2[1]; vertices[index++] = v2[2];

            vertices[index++] = v3[0]; vertices[index++] = v3[1]; vertices[index++] = v3[2];
            vertices[index++] = v1[0]; vertices[index++] = v1[1]; vertices[index++] = v1[2];
            vertices[index++] = v4[0]; vertices[index++] = v4[1]; vertices[index++] = v4[2];
        }

        var geometry = new THREE.BufferGeometry();
        geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
        var object = new THREE.Mesh(geometry, material);

        var wireframeGeometry = new THREE.WireframeGeometry(geometry);
        var wireframe = new THREE.LineSegments(wireframeGeometry, wireframeMaterial);

        return {
            object: object,
            wireframe: wireframe
        }
    }

}
