import { Step } from './Step.js';
import { Marker } from '../Marker.js';

interface ISerializeDatas {
    markers: { x: number; y: number; color: string; }[];
    type: string;
    stepType: any;
    done: boolean;
    outlinesDetected: boolean;
}

export class QueueStep extends Step {

    private _markers: Marker[];
    private _selectedMarker?: Marker;
    private _activeMarker?: Marker;
    private _outlinesDetected: boolean;

    constructor(stepType?: any) {
        super(stepType);

        this._markers = [];
        this._selectedMarker;
        this._activeMarker;
        this._outlinesDetected = false;
    }

    get Type(): string {
        return 'QueueStep';
    }

    get SelectedMarker(): Marker {
        return this._selectedMarker;
    }

    get Markers(): Marker[] {
        return this._markers;
    }

    set Markers(value: Marker[]) {
        this._markers = value;
    }

    get OutlinesDetected(): boolean {
        return this._outlinesDetected;
    }

    set OutlinesDetected(value: boolean) {
        this._outlinesDetected = value;
    }

    get Count(): number {
        return this._markers.length;
    }

    get ActiveElement(): Marker {
        return this._activeMarker;
    }

    get SelectedElement(): Marker {
        return this._selectedMarker;
    }

    Add(x: number, y: number) {
        var marker = new Marker();
        marker.Move(x, y);
        this._markers.push(marker);
        this._activeMarker = marker;
        return marker;
    }

    Insert(x: number, y: number, index: number, before: number) {
        var marker = new Marker();
        marker.Move(x, y);
        this._markers.splice(index + (before ? 0 : 1), 0, marker);
        this._activeMarker = marker;
        return marker;
    }

    RemoveActiveMarker() {
        if (this._activeMarker === undefined) {
            return;
        }

        var index = this._markers.indexOf(this._activeMarker);
        this._markers.splice(index, 1);
        this._activeMarker = undefined;
    }

    Select(x: number, y: number, zoom: number) {
        var candidates: Marker[] = [];
        for (var i = 0; i < this._markers.length; ++i) {
            var marker = this._markers[i];
            if (marker.IsSelectable(x, y, zoom)) {
                candidates.push(marker);
            }
        }

        if (candidates.length === 0) {
            this._selectedMarker = undefined;
            return false;
        }

        this._selectedMarker = candidates[0];
        var minDistance = this._selectedMarker.Distance(x, y);
        for (var i = 1; i < candidates.length; ++i) {
            var current = candidates[i];
            var distance = current.Distance(x, y);
            if (distance < minDistance) {
                minDistance = distance;
                this._selectedMarker = current;
            }
        }

        this._activeMarker = this._selectedMarker;
        return true;
    }

    Unselect() {
        this._selectedMarker = undefined;
    }

    Draw(context: CanvasRenderingContext2D, zoom: number) {
        for (var i = 0; i < this._markers.length; ++i) {
            this._markers[i].Draw(context, zoom, this._markers[i] === this._activeMarker);
        }
        for (var i = 0; i < this._markers.length - 1; ++i) {
            context.beginPath();
            context.strokeStyle = 'lime';
            context.lineWidth = 1 / zoom;
            context.moveTo(this._markers[i].X, this._markers[i].Y);
            context.lineTo(this._markers[i + 1].X, this._markers[i + 1].Y);
            context.stroke();
        }
    }

    Move(x: number, y: number) {
        if (!this._selectedMarker) {
            return;
        }

        this._selectedMarker.Move(x, y);
    }

    Serialize() {
        var result: { x: number; y: number; color: string; }[] = [];
        for (var i = 0; i < this._markers.length; ++i) {
            result.push(this._markers[i].Serialize());
        }
        return {
            markers: result,
            type: this.Type,
            stepType: this.StepType,
            done: this.IsDone,
            outlinesDetected: this._outlinesDetected
        }
    }

    Deserialize(datas: ISerializeDatas) {
        for (var i = 0; i < datas.markers.length; ++i) {
            var marker = new Marker();
            marker.Deserialize((datas.markers[i]));
            this._markers.push(marker);
        }
        this.StepType = datas.stepType;
        this.isDone = datas.done;
        this._activeMarker = this._markers[this._markers.length - 1];
        this._outlinesDetected = datas.outlinesDetected;
    }

}