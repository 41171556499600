import * as SavaneJS from "../node_modules/@rhinov/savane-js";

var JOINERY_MATERIAL_TYPE_LIST = [
    { name: 'PVC Blanc', value: 0 },
    { name: 'PVC Anthracite', value: 1 },
    { name: 'LA Métal Noir', value: 6 },
    { name: 'LA Bois Clair', value: 14 },
    { name: 'LA Bois Moyen', value: 15 },
    { name: 'LA Bois Ocre', value: 16 },
    { name: 'Bois Clair', value: 2 },
    { name: 'Bois Moyen', value: 5 },
    { name: 'Bois Foncé', value: 3 },
    { name: 'Gris Alu', value: 4 },
    { name: 'Sable', value: 7 },
    { name: 'Mineral', value: 8 },
    { name: 'Taupe', value: 9 },
    { name: 'Vert Clair (6021)', value: 10 },
    { name: 'Vert Moyen (7030)', value: 11 },
    { name: 'Bordeaux', value: 12 },
    { name: 'Bleu Cobalt (5013)', value: 13 }
];

var BEAM_MATERIAL_TYPE_LIST = [
    { name: 'Blanc', value: 7 },
    { name: 'Bois Peint Blanc', value: 3 },
    { name: 'Bois Clair', value: 2 },
    { name: 'Bois Moyen', value: 13 },
    { name: 'Bois Sombre', value: 1 },
    // Ajouté
    { name: 'Bois Très Foncé', value: 14 },
    { name: 'Bois Usé Clair', value: 11 },
    // Ajouté
    { name: 'Bois Usé Moyen', value: 15 },
    // Renommé
    { name: /*'Bois Usé Noeuds'*/'Bois Usé Foncé', value: 9 },
    { name: 'Bois Brosse Foncé', value: 12 },
    { name: 'Métal Noir', value: 5 }
    // Supprimé
    //{ name: 'Neutre', value: 0 },
    // Supprimé
    //{ name: 'Bois Usé', value: 4 },
    // Supprimé
    //{ name: 'Béton', value: 6 },
    // Supprimé
    //{ name: 'Bois Usé Terne', value: 8 },
    // Supprimé
    //{ name: 'Bois Usé Fissure', value: 10 },
];

var RADIATOR_MATERIAL_TYPE_LIST = [
    { name: 'Neutre', value: 0 },
    { name: 'Bois Sombre', value: 1 },
    { name: 'Bois Moyen', value: 13 },
    { name: 'Bois Clair', value: 2 },
    { name: 'Bois Peint Blanc', value: 3 },
    { name: 'Bois Usé', value: 4 },
    { name: 'Métal Noir', value: 5 },
    { name: 'Béton', value: 6 },
    { name: 'Blanc', value: 7 },
    { name: 'Bois Usé Terne', value: 8 },
    { name: 'Bois Usé Noeuds', value: 9 },
    { name: 'Bois Usé Fissure', value: 10 },
    { name: 'Bois Usé Clair', value: 11 },
    { name: 'Bois Brosse Foncé', value: 12 }
];

export var JOINERY_TYPES = [
    { name: '', value: -1 },

    {
        name: 'Porte', value: 0, assetType: "joineries", nbDoors: 1,
        showMaterials: true, showFrosted: true, showThickness: false, showNbDoors: false, showOpened: true, showRollingShutter: false, showSliding: true, showInstallType: true, showTransom: true, showBottomTransom: false,
        dropDownMaterialList: JOINERY_MATERIAL_TYPE_LIST, assetList: [],
        defaultModel: SavaneJS.SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelDoor", SavaneJS.SceneConstants.DefaultJoineryType.door)
    },

    {
        name: 'Porte - Double', value: 1, assetType: "joineries", nbDoors: 2, showMaterials: true,
        showFrosted: true, showThickness: false, showNbDoors: false, showOpened: true, showRollingShutter: false, showSliding: true, showInstallType: true, showTransom: true, showBottomTransom: false,
        dropDownMaterialList: JOINERY_MATERIAL_TYPE_LIST, assetList: [],
        defaultModel: SavaneJS.SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelDoubleDoor", SavaneJS.SceneConstants.DefaultJoineryType.doubleDoor)
    },

    {
        name: 'Porte d\'entrée', value: 2, assetType: "joineries", nbDoors: 1,
        showMaterials: true, showFrosted: true, showThickness: false, showNbDoors: false, showOpened: true, showRollingShutter: false, showSliding: false, showInstallType: true, showTransom: true, showBottomTransom: false,
        dropDownMaterialList: JOINERY_MATERIAL_TYPE_LIST, assetList: [],
        defaultModel: SavaneJS.SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelDoorFront", SavaneJS.SceneConstants.DefaultJoineryType.frontDoor)
    },

    {
        name: 'Fenêtre', value: 4, assetType: "joineries", nbDoors: 1,
        showMaterials: true, showFrosted: true, showThickness: false, showNbDoors: false, showOpened: true, showRollingShutter: true, showSliding: true, showInstallType: true, showTransom: true, showBottomTransom: true,
        dropDownMaterialList: JOINERY_MATERIAL_TYPE_LIST, assetList: [],
        defaultModel: SavaneJS.SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelWindow", SavaneJS.SceneConstants.DefaultJoineryType.window)
    },

    {
        name: 'Fenêtre - Double', value: 5, assetType: "joineries", nbDoors: 2,
        showMaterials: true, showFrosted: true, showThickness: false, showNbDoors: false, showOpened: true, showRollingShutter: true, showSliding: true, showInstallType: true, showTransom: true, showBottomTransom: true,
        dropDownMaterialList: JOINERY_MATERIAL_TYPE_LIST, assetList: [],
        defaultModel: SavaneJS.SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelDoubleWindow", SavaneJS.SceneConstants.DefaultJoineryType.doubleWindow)
    },

    {
        name: 'Porte fenêtre', value: 6, assetType: "joineries", nbDoors: 1,
        showMaterials: true, showFrosted: true, showThickness: false, showNbDoors: false, showOpened: true, showRollingShutter: true, showSliding: false, showInstallType: true, showTransom: true, showBottomTransom: false,
        dropDownMaterialList: JOINERY_MATERIAL_TYPE_LIST, assetList: [],
        defaultModel: SavaneJS.SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelFrenchWindow", SavaneJS.SceneConstants.DefaultJoineryType.frenchWindow)
    },

    {
        name: 'Porte fenêtre - Double', value: 7, assetType: "joineries", nbDoors: 2,
        showMaterials: true, showFrosted: true, showThickness: false, showNbDoors: false, showOpened: true, showRollingShutter: true, showSliding: false, showInstallType: true, showTransom: true, showBottomTransom: false,
        dropDownMaterialList: JOINERY_MATERIAL_TYPE_LIST, assetList: [],
        defaultModel: SavaneJS.SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelDoubleFrenchWindow", SavaneJS.SceneConstants.DefaultJoineryType.doubleFrenchWindow)
    },

    {
        name: 'Baie vitrée', value: 8, assetType: "joineries", nbDoors: 2,
        showMaterials: true, showFrosted: true, showThickness: false, showNbDoors: true, showOpened: true, showRollingShutter: true, showSliding: false, showInstallType: true, showTransom: true, showBottomTransom: false,
        dropDownMaterialList: JOINERY_MATERIAL_TYPE_LIST, assetList: [],
        defaultModel: SavaneJS.SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelPictureWindow", SavaneJS.SceneConstants.DefaultJoineryType.pictureWindow)
    },

    {
        name: 'Verrière', value: 9, assetType: "joineries", nbDoors: 3,
        showMaterials: true, showFrosted: true, showThickness: false, showNbDoors: true, showOpened: false, showRollingShutter: true, showSliding: false, showInstallType: true, showTransom: false, showBottomTransom: false,
        dropDownMaterialList: JOINERY_MATERIAL_TYPE_LIST, assetList: [],
        defaultModel: SavaneJS.SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelCanopy", SavaneJS.SceneConstants.DefaultJoineryType.canopy)
    },

    {
        name: 'Ouverture', value: 10, assetType: "joineries", nbDoors: 1,
        showMaterials: false, showFrosted: false, showThickness: false, showNbDoors: false, showOpened: false, showRollingShutter: false, showSliding: false, showInstallType: false, showTransom: true, showBottomTransom: true,
        dropDownMaterialList: null, assetList: [],
        defaultModel: SavaneJS.SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelOpening", SavaneJS.SceneConstants.DefaultJoineryType.opening)
    },

    {
        name: 'Placard', value: 11, assetType: "joineries", nbDoors: 2,
        showMaterials: true, showFrosted: false, showThickness: false, showNbDoors: true, showOpened: true, showRollingShutter: false, showSliding: false, showInstallType: true, showTransom: false, showBottomTransom: false,
        dropDownMaterialList: JOINERY_MATERIAL_TYPE_LIST, assetList: [],
        defaultModel: SavaneJS.SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelCupboardDoor", SavaneJS.SceneConstants.DefaultJoineryType.cupboardDoor)
    },

    {
        name: 'Porte de garage', value: 12, assetType: "joineries", nbDoors: 1,
        showMaterials: true, showFrosted: false, showThickness: false, showNbDoors: false, showOpened: false, showRollingShutter: false, showSliding: false, showInstallType: true, showTransom: false, showBottomTransom: false,
        dropDownMaterialList: JOINERY_MATERIAL_TYPE_LIST, assetList: [],
        defaultModel: SavaneJS.SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelGarageDoor", SavaneJS.SceneConstants.DefaultJoineryType.garageDoor)
    },

    {
        name: 'Niche', value: 13, assetType: "joineries", nbDoors: 1,
        showMaterials: false, showFrosted: false, showThickness: true, showNbDoors: false, showOpened: false, showRollingShutter: false, showSliding: false, showInstallType: false, showTransom: false, showBottomTransom: false,
        dropDownMaterialList: null, assetList: [],
        defaultModel: SavaneJS.SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelNiche", SavaneJS.SceneConstants.DefaultJoineryType.niche)
    },

    {
        name: 'Radiateur', value: 14, assetType: "technicalElements", nbDoors: 1,
        showMaterials: true, showFrosted: false, showThickness: false, showNbDoors: false, showOpened: false, showRollingShutter: false, showSliding: false, showInstallType: false, showTransom: false, showBottomTransom: false,
        dropDownMaterialList: RADIATOR_MATERIAL_TYPE_LIST, assetList: [],
        defaultModel: SavaneJS.SavaneCookie.getCookie("Rhinov-Savane-DefaultTechnicalElementModelRadiator", SavaneJS.SceneConstants.DefaultTechnicalElementType.radiator)
    },

    {
        name: 'Poêle', value: 15, assetType: "technicalElements", nbDoors: 1,
        showMaterials: false, showFrosted: false, showThickness: false, showNbDoors: false, showOpened: false, showRollingShutter: false, showSliding: false, showInstallType: false, showTransom: false, showBottomTransom: false,
        dropDownMaterialList: null, assetList: [],
        defaultModel: SavaneJS.SavaneCookie.getCookie("Rhinov-Savane-DefaultTechnicalElementModelStove", SavaneJS.SceneConstants.DefaultTechnicalElementType.stove)
    },

    {
        name: 'Climatisation', value: 16, assetType: "technicalElements", nbDoors: 1,
        showMaterials: false, showFrosted: false, showThickness: false, showNbDoors: false, showOpened: false, showRollingShutter: false, showSliding: false, showInstallType: false, showTransom: false, showBottomTransom: false,
        dropDownMaterialList: null, assetList: [],
        defaultModel: SavaneJS.SavaneCookie.getCookie("Rhinov-Savane-DefaultTechnicalElementModelAirConditioner", SavaneJS.SceneConstants.DefaultTechnicalElementType.airConditioner)
    },

    {
        name: 'Châssis fixe', value: 17, assetType: "joineries", nbDoors: 1,
        showMaterials: true, showFrosted: true, showThickness: false, showNbDoors: false, showOpened: true, showRollingShutter: true, showSliding: false, showInstallType: true, showTransom: true, showBottomTransom: true,
        dropDownMaterialList: JOINERY_MATERIAL_TYPE_LIST, assetList: [],
        defaultModel: SavaneJS.SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelFixedWindow", SavaneJS.SceneConstants.DefaultJoineryType.fixedWindow)
    },

    {
        name: 'Cheminée', value: 18, assetType: "technicalElements", nbDoors: 1,
        showMaterials: false, showFrosted: false, showThickness: false, showNbDoors: false, showOpened: false, showRollingShutter: false, showSliding: false, showInstallType: false, showTransom: false, showBottomTransom: false,
        dropDownMaterialList: null, assetList: [],
        defaultModel: SavaneJS.SavaneCookie.getCookie("Rhinov-Savane-DefaultTechnicalElementModelFireplace", SavaneJS.SceneConstants.DefaultTechnicalElementType.fireplace)
    },

    {
        name: 'Velux', value: 19, assetType: "joineries", nbDoors: 1, showMaterials: true,
        showFrosted: true, showThickness: false, showNbDoors: false, showOpened: false, showRollingShutter: false, showSliding: false, showInstallType: true, showTransom: false, showBottomTransom: false,
        dropDownMaterialList: JOINERY_MATERIAL_TYPE_LIST, assetList: [],
        defaultModel: SavaneJS.SavaneCookie.getCookie("Rhinov-Savane-DefaultJoineryModelVelux", SavaneJS.SceneConstants.DefaultJoineryType.velux)
    },

    {
        name: 'Poutre', value: 20, assetType: "technicalElements", nbDoors: 1,
        showMaterials: true, showFrosted: false, showThickness: false, showNbDoors: false, showOpened: false, showRollingShutter: false, showSliding: false, showInstallType: false, showTransom: false, showBottomTransom: false,
        dropDownMaterialList: BEAM_MATERIAL_TYPE_LIST, assetList: [],
        defaultModel: SavaneJS.SavaneCookie.getCookie("Rhinov-Savane-DefaultTechnicalElementModelBeam", SavaneJS.SceneConstants.DefaultTechnicalElementType.beam)
    },

    {
        name: 'Chaudière', value: 21, assetType: "technicalElements", nbDoors: 1,
        showMaterials: false, showFrosted: false, showThickness: false, showNbDoors: false, showOpened: false, showRollingShutter: false, showSliding: false, showInstallType: false, showTransom: false, showBottomTransom: false,
        dropDownMaterialList: null, assetList: [],
        defaultModel: SavaneJS.SavaneCookie.getCookie("Rhinov-Savane-DefaultTechnicalElementModelBoiler", SavaneJS.SceneConstants.DefaultTechnicalElementType.boiler)
    },

    {
        name: 'Rosace', value: 22, assetType: "technicalElements", nbDoors: 1,
        showMaterials: false, showFrosted: false, showThickness: false, showNbDoors: false, showOpened: false, showRollingShutter: false, showSliding: false, showInstallType: false, showTransom: false, showBottomTransom: false,
        dropDownMaterialList: null, assetList: [],
        defaultModel: SavaneJS.SavaneCookie.getCookie("Rhinov-Savane-DefaultTechnicalElementModelRosette", SavaneJS.SceneConstants.DefaultTechnicalElementType.rosette)
    },

    {
        name: 'Spot', value: 23, assetType: "technicalElements", nbDoors: 1,
        showMaterials: false, showFrosted: false, showThickness: false, showNbDoors: false, showOpened: false, showRollingShutter: false, showSliding: false, showInstallType: false, showTransom: false, showBottomTransom: false,
        dropDownMaterialList: null, assetList: [],
        defaultModel: SavaneJS.SavaneCookie.getCookie("Rhinov-Savane-DefaultTechnicalElementModelSpotLight", SavaneJS.SceneConstants.DefaultTechnicalElementType.spotLight)
    },

    {
        name: 'Poteau rond', value: 24, assetType: "technicalElements", nbDoors: 1,
        showMaterials: false, showFrosted: false, showThickness: false, showNbDoors: false, showOpened: false, showRollingShutter: false, showSliding: false, showInstallType: false, showTransom: false, showBottomTransom: false,
        dropDownMaterialList: null, assetList: [],
        defaultModel: SavaneJS.SavaneCookie.getCookie("Rhinov-Savane-DefaultTechnicalElementModelCircularPole", SavaneJS.SceneConstants.DefaultTechnicalElementType.circularPole)
    },

    {
        name: 'Poteau rectangulaire', value: 25, assetType: "technicalElements", nbDoors: 1,
        showMaterials: false, showFrosted: false, showThickness: false, showNbDoors: false, showOpened: false, showRollingShutter: false, showSliding: false, showInstallType: false, showTransom: false, showBottomTransom: false,
        dropDownMaterialList: null, assetList: [],
        defaultModel: SavaneJS.SavaneCookie.getCookie("Rhinov-Savane-DefaultTechnicalElementModelRectangularPole", SavaneJS.SceneConstants.DefaultTechnicalElementType.rectangularPole)
    },

    {
        name: 'Tableau électrique', value: 26, assetType: "technicalElements", nbDoors: 1,
        showMaterials: false, showFrosted: false, showThickness: false, showNbDoors: false, showOpened: false, showRollingShutter: false, showSliding: false, showInstallType: false, showTransom: false, showBottomTransom: false,
        dropDownMaterialList: null, assetList: [],
        defaultModel: SavaneJS.SavaneCookie.getCookie("Rhinov-Savane-DefaultTechnicalElementModelSwitchBoard", SavaneJS.SceneConstants.DefaultTechnicalElementType.switchBoard)
    },

    {
        name: 'Décoration murale', value: 27, assetType: "technicalElements", nbDoors: 1,
        showMaterials: false, showFrosted: false, showThickness: false, showNbDoors: false, showOpened: false, showRollingShutter: false, showSliding: false, showInstallType: false, showTransom: false, showBottomTransom: false,
        dropDownMaterialList: null, assetList: [],
        defaultModel: SavaneJS.SavaneCookie.getCookie("Rhinov-Savane-DefaultTechnicalElementModelWallDecoration", SavaneJS.SceneConstants.DefaultTechnicalElementType.wallDecoration)
    },

    {
        name: 'Placeholders', value: 28, assetType: "assets", nbDoors: 0,
        showMaterials: false, showFrosted: false, showThickness: false, showNbDoors: false, showOpened: false, showRollingShutter: false, showSliding: false, showInstallType: false, showTransom: false, showBottomTransom: false,
        dropDownMaterialList: null, assetList: [],
        defaultModel: null
    }
];

JOINERY_TYPES.sort(function (a, b) {
    return a.name.localeCompare(b.name);
});
