import * as SavaneJS from '@rhinov/savane-js';
import { PairStep } from "../Photo2Savane/Step/PairStep";
import { QueueStep } from "../Photo2Savane/Step/QueueStep";
import { Step } from "../Photo2Savane/Step/Step";
import { MarkerPair } from './MarkerPair';

export const StepValuesType = {
    None: 0,
    Distance: 1,
    JoineryType: 2,
    DistanceHeight: 3
}

export const StepType = {
    Camera: 'CameraStep',
    Outline: 'OutlineStep',
    Slope: 'SlopeStep',
    Joineries: 'JoineriesStep',
    OutlinePartition: 'OutlinePartitionStep',
    SlopePartition: 'SlopePartitionStep',
    JoineriesPartition: 'JoineriesPartitionStep',
    Count: 'CountStep'
}

export const StepOrder = [StepType.Camera, StepType.Outline, StepType.Slope,
StepType.Joineries, StepType.OutlinePartition, StepType.SlopePartition, StepType.JoineriesPartition];

interface JoinerieData {
    value: number;
    materialType: number;
    frosted: boolean;
    thickness: number;
    model: string;
    opened: boolean;
    rollingShutter: boolean;
    sliding: boolean;
    wallInstallType: number;
    dimensions: number;
    nbDoors: number;
    transom: boolean;
    transomHeight: number;
    bottomTransom: boolean;
    bottomTransomHeight: number;
    wallIndex: number;
    isPinnedA: boolean;
    isPinnedB: boolean;
    invert: boolean;
}

interface SlopeData {
    isPinnedA: boolean;
    isPinnedB: boolean;
    wallIndex: number;
}

interface DeserializeDatas {
    steps: (PairStep | QueueStep)[];
    width: number;
    height: number;
    done: boolean;
    VerticalShiftOffset: number;
    WallHeight: number;
    WorldHeight: number;
    OffsetFuX: number;
    OffsetFuY: number;
    OffsetFvX: number;
    OffsetFvY: number;
    FocalLengthOffset: number;
    CeilDraw: boolean;
    CeilPartitionDraw: boolean;
    ForceRenormalize: boolean;
    Version: number;
}

export class PhotoSteps {
    private _width: number;
    private _height: number;
    private _steps: Step[];
    photo2savane: SavaneJS.Photo2Savane;
    private _done: boolean;
    private _invalidate: boolean;
    aiDetections?: any;
    private _currentStep!: PairStep | QueueStep;


    static get StepValuesType() {
        return StepValuesType;
    }

    static get StepType() {
        return StepType;
    }

    static get StepOrder() {
        return StepOrder;
    }

    constructor(width?: number, height?: number, camera?: number) {
        this._steps = [];
        this._width = width === undefined ? 0 : width;
        this._height = height === undefined ? 0 : height;

        if (this._width !== 0 && this._height !== 0) {
            this.photo2savane = new SavaneJS.Photo2Savane(this._width, this._height, camera ?? 0);
        }

        this._done = false;
        this._invalidate = false;
        this.aiDetections = undefined;

        this._initSteps();
    }

    get Width(): number {
        return this._width;
    }

    get Height(): number {
        return this._height;
    }

    get Steps(): Step[] {
        return this._steps;
    }

    get Invalidate(): boolean {
        return this._invalidate;
    }

    _initSteps() {
        let step: PairStep | QueueStep | undefined;
        for (const [_key, value] of Object.entries(StepType)) {
            switch (value) {
                case StepType.Camera:
                    step = new PairStep(value);
                    var pair = step.Add(this._width * 0.1, this._height * 0.1, this._width * 0.3, this._height * 0.2);
                    pair.SetMarkerColor("#00b7eb");
                    pair.LineMoveMode = 'R';
                    pair.Value = 250;

                    pair = step.Add(this._width * 0.1, this._height * 0.3, this._width * 0.3, this._height * 0.4);
                    pair.SetMarkerColor("#00b7eb");
                    pair.LineMoveMode = 'R';
                    pair.Value = 250;

                    pair = step.Add(this._width * 0.7, this._height * 0.2, this._width * 0.9, this._height * 0.1);
                    pair.SetColor('green');
                    pair.SetMarkerColor("#ff4814");
                    pair.LineMoveMode = 'R';
                    pair.Value = 250;

                    pair = step.Add(this._width * 0.7, this._height * 0.4, this._width * 0.9, this._height * 0.3);
                    pair.SetColor('green');
                    pair.SetMarkerColor("#ff4814");
                    pair.LineMoveMode = 'R';
                    pair.Value = 250;

                    pair = step.Add(this._width * 0.5, this._height * 0.1, this._width * 0.5, this._height * 0.5);
                    pair.SetColor('blue');
                    pair.SetMarkerColor('red');
                    pair.Value = 250;
                    this._steps[StepOrder.indexOf(value)] = step;
                    break;
                case StepType.Outline:
                    step = new QueueStep(value);
                    step.Add(0, this._height * 0.75);
                    step.Add(this._width * 0.25, this._height * 0.5);
                    step.Add(this._width * 0.75, this._height * 0.5);
                    this._steps[StepOrder.indexOf(value)] = step;
                    break;
                case StepType.Slope:
                    this._steps[StepOrder.indexOf(value)] = new PairStep(value);
                    break;
                case StepType.Joineries:
                    this._steps[StepOrder.indexOf(value)] = new PairStep(value);
                    break;
                case StepType.OutlinePartition:
                    this._steps[StepOrder.indexOf(value)] = new PairStep(value);
                    break;
                case StepType.SlopePartition:
                    this._steps[StepOrder.indexOf(value)] = new PairStep(value);
                    break;
                case StepType.JoineriesPartition:
                    this._steps[StepOrder.indexOf(value)] = new PairStep(value);
                    break;
            }
        }
        this.CurrentStep = this._steps[0] as PairStep | QueueStep;
    }

    UpdateStep(zoom?: number) {
        if (!this.CurrentStep) {
            return;
        }

        switch (this.CurrentStep.StepType) {
            case StepType.Camera:
                var lines = this.Lines;
                let api = {
                    A: lines[lines.length - 1].A,
                    B: lines[lines.length - 1].B
                }
                this.photo2savane.Photo2World.EstimateCamera(lines.slice(0, 4), api);
                break;
            case StepType.Outline:
                this.photo2savane.Photo2World.BuildWalls(this.Pixels);
                break;
            case StepType.Slope:
                this.photo2savane.Photo2World.BuildSlopes(this.Pixels, this.SlopesData);
                break;
            case StepType.Joineries:
                this.photo2savane.Photo2World.BuildWallJoineries(this.Pixels, this.JoineriesData);
                break;
            case StepType.OutlinePartition:
                this.photo2savane.Photo2World.BuildPartitions(this.Pixels, this.Values, this.Values2, zoom);
                break;
            case StepType.SlopePartition:
                this.photo2savane.Photo2World.BuildSlopesPartition(this.Pixels, this.SlopesData);
                for (var i = 0; i < this.photo2savane.Photo2World.Model.Partitions.length; ++i) {

                    (this.photo2savane.Photo2World.Model.Partitions as any)[i].selected = false;
                }
                if (this.CurrentStep instanceof PairStep && this.CurrentStep.Pairs.length > 0 &&
                    this.CurrentStep.ActiveElement &&
                    this.CurrentStep.ActiveElement.WallIndex < this.photo2savane.Photo2World.Model.Partitions.length) {

                    (this.photo2savane.Photo2World.Model.Partitions as any)[this.CurrentStep.ActiveElement.WallIndex].selected = true;
                }
                break;
            case StepType.JoineriesPartition:
                this.photo2savane.Photo2World.BuildPartitionJoineries(this.Pixels, this.JoineriesData);
                for (var i = 0; i < this.photo2savane.Photo2World.Model.Partitions.length; ++i) {

                    (this.photo2savane.Photo2World.Model.Partitions as any).selected = false;
                }
                if (this.CurrentStep instanceof PairStep && this.CurrentStep.Pairs.length > 0 &&
                    this.CurrentStep.ActiveElement &&
                    this.CurrentStep.ActiveElement.WallIndex < this.photo2savane.Photo2World.Model.Partitions.length) {

                    (this.photo2savane.Photo2World.Model.Partitions as any)[this.CurrentStep.ActiveElement.WallIndex].selected = true;
                }
                break;
        }
    }

    AddElement(x?: number, y?: number, before?: number) {
        var X = x === undefined ? this._width * 0.5 : x;
        var Y = y === undefined ? this._height * 0.5 : y;
        switch (this.CurrentStep.StepType) {
            case StepType.Outline:
                if (this.CurrentStep.ActiveElement) {
                    if (this.CurrentStep instanceof QueueStep)
                        var index = this.CurrentStep.Markers.indexOf(this.CurrentStep.ActiveElement);
                    this.CurrentStep.Insert(X, Y, index, before ? 1 : 0);
                } else {
                    this.CurrentStep.Add(X, Y);
                }
                this.photo2savane.Photo2World.BuildWalls(this.Pixels);
                break;
            case StepType.Slope:
            case StepType.SlopePartition:
                this.CurrentStep.Add(X, Y, X + this._width * 0.1, Y - this._height * 0.2);
                break;
            case StepType.Joineries:
                var pair = this.CurrentStep.Add(X, Y, X + this._width * 0.1, Y + this._height * 0.2);
                if (pair instanceof MarkerPair)
                    pair.SetMarkerColor('orange');
                break;
            case StepType.OutlinePartition:
                var pair = this.CurrentStep.Add(X, Y, X + this._width * 0.1, Y + this._height * 0.2);
                //default partition thickness
                if (pair instanceof MarkerPair)
                    pair.Value = 7
                if (pair instanceof MarkerPair)
                    pair.Value2 = this.photo2savane.Photo2World.WallHeight / 10;
                break;
            case StepType.JoineriesPartition:
                var pair = this.CurrentStep.Add(X, Y, X, Y + 100);
                if (pair instanceof MarkerPair)
                    pair.SetMarkerColor('orange');
                break;
        }
    }

    RemoveElement() {
        switch (this.CurrentStep.StepType) {
            case StepType.Outline:
                if (this.CurrentStep instanceof QueueStep)
                    this.CurrentStep.RemoveActiveMarker();
                break;
            case StepType.Slope:
            case StepType.SlopePartition:
                if (this.CurrentStep instanceof PairStep)
                    this.CurrentStep.RemoveActivePair();
                break;
            case StepType.Joineries:
                if (this.CurrentStep instanceof PairStep)
                    this.CurrentStep.RemoveActivePair();
                break;
            case StepType.OutlinePartition:
                if (this.CurrentStep instanceof PairStep)
                    this.CurrentStep.RemoveActivePair();
                break;
            case StepType.JoineriesPartition:
                if (this.CurrentStep instanceof PairStep)
                    this.CurrentStep.RemoveActivePair();
                break;
        }
    }

    get StepValueType(): number {
        if (!this.CurrentStep) {
            return StepValuesType.None;
        }

        switch (this.CurrentStep.StepType) {
            case StepType.JoineriesPartition:
            case StepType.Joineries:
                return StepValuesType.JoineryType;
            case StepType.Camera:
            case StepType.Outline:
                return StepValuesType.Distance;
            case StepType.OutlinePartition:
                return StepValuesType.DistanceHeight;
            default:
                return StepValuesType.None;
        }
    }

    get IsCameraStep(): boolean {
        if (!this.CurrentStep) {
            return false;
        }

        switch (this.CurrentStep.StepType) {
            case StepType.Camera:
                return true;
            default:
                return false;
        }
    }

    get CanDrawWallFromCeiling(): boolean {
        if (!this.CurrentStep) {
            return false;
        }

        switch (this.CurrentStep.StepType) {
            case StepType.Outline:
                return true;
            default:
                return false;
        }
    }

    get CanDrawPartitionFromCeiling(): boolean {
        if (!this.CurrentStep) {
            return false;
        }

        switch (this.CurrentStep.StepType) {
            case StepType.OutlinePartition:
                return true;
            default:
                return false;
        }
    }

    get Pixels(): SavaneJS.Math.glMatrix.vec2[] {
        var result: SavaneJS.Math.glMatrix.vec2[] = [];
        switch (this.CurrentStep.Type) {
            case 'PairStep':
                if (this.CurrentStep instanceof PairStep)
                    for (var i = 0; i < this.CurrentStep.Pairs.length; ++i) {
                        result.push(SavaneJS.Math.glMatrix.vec2.fromValues(this.CurrentStep.Pairs[i].A.X, this.CurrentStep.Pairs[i].A.Y));
                        result.push(SavaneJS.Math.glMatrix.vec2.fromValues(this.CurrentStep.Pairs[i].B.X, this.CurrentStep.Pairs[i].B.Y));
                    }
                break;
            case 'QueueStep':
                if (this.CurrentStep instanceof QueueStep)
                    for (var i = 0; i < this.CurrentStep.Markers.length; ++i) {
                        var v = SavaneJS.math.vec2.create();
                        SavaneJS.math.vec2.set(v, this.CurrentStep.Markers[i].X, this.CurrentStep.Markers[i].Y);
                        result.push(v);
                    }
                if (result.length > 0) {
                    if (result[0][0] > result[result.length - 1][0]) {
                        result.reverse();
                    }
                }
                break;
        }

        return result;
    }

    get Values(): number[] {
        var result: number[] = [];
        if (this.CurrentStep instanceof PairStep)
            for (var i = 0; i < this.CurrentStep.Pairs.length; ++i) {
                result.push(this.CurrentStep.Pairs[i].Value * 10);
            }

        return result;
    }

    get Values2(): number[] {
        var result: number[] = [];
        if (this.CurrentStep instanceof PairStep)
            for (var i = 0; i < this.CurrentStep.Pairs.length; ++i) {
                result.push(this.CurrentStep.Pairs[i].Value2 * 10);
            }
        return result;
    }

    get Lines(): Array<SavaneJS.Math.Line> {
        var result: Array<SavaneJS.Math.Line> = [];
        switch (this.CurrentStep.Type) {
            case 'PairStep':
                if (this.CurrentStep instanceof PairStep) {
                    for (var i = 0; i < this.CurrentStep.Pairs.length; ++i) {
                        var A = SavaneJS.Math.glMatrix.vec3.fromValues(this.CurrentStep.Pairs[i].A.X, this.CurrentStep.Pairs[i].A.Y, 0);
                        var B = SavaneJS.Math.glMatrix.vec3.fromValues(this.CurrentStep.Pairs[i].B.X, this.CurrentStep.Pairs[i].B.Y, 0);
                        result.push(new SavaneJS.Math.Line(A, B));
                    }
                }
                break;
            case 'QueueStep':
                if (this.CurrentStep instanceof QueueStep)
                    for (var i = 0; i < this.CurrentStep.Markers.length; i += 2) {
                        var A = SavaneJS.Math.glMatrix.vec3.fromValues(this.CurrentStep.Markers[i].X, this.CurrentStep.Markers[i].Y, 0);
                        var B = SavaneJS.Math.glMatrix.vec3.fromValues(this.CurrentStep.Markers[i + 1].X, this.CurrentStep.Markers[i + 1].Y, 0);
                        result.push(new SavaneJS.Math.Line(A, B));
                    }
                break;
        }

        return result;
    }

    get JoineriesData(): JoinerieData[] {
        var result: JoinerieData[] = [];
        if (this.CurrentStep instanceof PairStep)
            for (var i = 0; i < this.CurrentStep.Pairs.length; ++i) {
                var pair = this.CurrentStep.Pairs[i];
                result.push({
                    value: pair.Value,
                    materialType: pair.MaterialType,
                    frosted: pair.Frosted,
                    thickness: pair.Thickness,
                    model: pair.Model,
                    opened: pair.Opened,
                    rollingShutter: pair.RollingShutter,
                    sliding: pair.Sliding,
                    wallInstallType: pair.WallInstallType,
                    dimensions: pair.Dimensions,
                    nbDoors: pair.NbDoors,
                    transom: pair.Transom,
                    transomHeight: pair.TransomHeight,
                    bottomTransom: pair.BottomTransom,
                    bottomTransomHeight: pair.BottomTransomHeight,
                    wallIndex: pair.WallIndex,
                    isPinnedA: pair.IsPinnedA,
                    isPinnedB: pair.IsPinnedB,
                    invert: pair.Invert
                });
            }

        return result;
    }

    get SlopesData(): SlopeData[] {
        var result: SlopeData[] = [];
        if (this.CurrentStep instanceof PairStep)
            for (var i = 0; i < this.CurrentStep.Pairs.length; ++i) {
                var pair = this.CurrentStep.Pairs[i];
                result.push({
                    isPinnedA: pair.IsPinnedA,
                    isPinnedB: pair.IsPinnedB,
                    wallIndex: pair.WallIndex
                });
            }

        return result;
    }

    ValidateStep(zoom?: number) {
        if (this.CurrentStep === null) {
            return false;
        }

        var result = false;
        switch (this.CurrentStep.StepType) {
            case StepType.Camera:
                if ((this.CurrentStep as PairStep).Pairs.length === 5) {
                    var lines = this.Lines;

                    let api = {
                        A: lines[lines.length - 1].A,
                        B: lines[lines.length - 1].B
                    }
                    this.photo2savane.Photo2World.EstimateCamera(lines.slice(0, 4), api);
                    this.CurrentStep.Done();
                    result = true;
                }
                break;
            case StepType.Outline:
                if (this.CurrentStep instanceof QueueStep)
                    if (this.CurrentStep.Markers.length >= 3) {
                        this.photo2savane.Photo2World.BuildWalls(this.Pixels);
                        this.CurrentStep.Done();
                        result = true;
                    }
                break;
            case StepType.Slope:
                this.photo2savane.Photo2World.BuildSlopes(this.Pixels, this.SlopesData);
                this.CurrentStep.Done();
                result = true;
                break;
            case StepType.Joineries:
                var valid = true;
                if (this.CurrentStep instanceof PairStep)
                    for (var i = 0; i < this.CurrentStep.Pairs.length; ++i) {
                        var pair = this.CurrentStep.Pairs[i];
                        if (pair.Value === null) {
                            valid = false;
                            break;
                        }
                    }
                if (valid) {
                    this.photo2savane.Photo2World.BuildWallJoineries(this.Pixels, this.JoineriesData);
                    this.CurrentStep.Done();
                }
                result = valid;
                break;
            case StepType.OutlinePartition:
                this.photo2savane.Photo2World.BuildPartitions(this.Pixels, this.Values, this.Values2, zoom);
                this.CurrentStep.Done();
                result = true;
                break;
            case StepType.SlopePartition:
                this.photo2savane.Photo2World.BuildSlopesPartition(this.Pixels, this.SlopesData);
                this.CurrentStep.Done();
                result = true;
                break;
            case StepType.JoineriesPartition:
                var valid = true;
                if (this.CurrentStep instanceof PairStep)
                    for (var i = 0; i < this.CurrentStep.Pairs.length; ++i) {
                        var pair = this.CurrentStep.Pairs[i];
                        if (pair.Value === null) {
                            valid = false;
                            break;
                        }
                    }
                if (valid) {
                    this.photo2savane.Photo2World.BuildPartitionJoineries(this.Pixels, this.JoineriesData);
                    this.CurrentStep.Done();
                }
                result = valid;
                break;
        }

        if (result === true) { //go to next step
            var index = this._steps.indexOf(this.CurrentStep);

            if (this.CurrentStep.StepType === StepType.OutlinePartition && this.CurrentStep instanceof PairStep && this.CurrentStep.Pairs.length === 0) {
                this.CurrentStep = null;
                return true;
            }

            if (index + 1 < this._steps.length) {
                this.CurrentStep = this._steps[index + 1] as PairStep | QueueStep;
            } else {
                this.CurrentStep = null;
            }
        }

        return result;
    }

    PreviousStep() {
        var index = this._steps.indexOf(this.CurrentStep);
        if (index > 0) {
            this.CurrentStep = this._steps[index - 1] as PairStep | QueueStep;
        } else if (!this.CurrentStep) {
            let pairs = (this._steps[StepOrder.indexOf(StepType.OutlinePartition)] as PairStep).Pairs
            if (pairs.length === 0) {
                this.CurrentStep = this._steps[StepOrder.indexOf(StepType.Joineries)] as PairStep | QueueStep;
            } else {
                this.CurrentStep = this._steps[this._steps.length - 1] as PairStep | QueueStep;
            }
        }
    }

    Select(x: number, y: number, zoom: number) {
        if (!this.CurrentStep) {
            return false;
        }

        return this.CurrentStep.Select(x, y, zoom);
    }

    Move(x: number, y: number) {
        if (!this.CurrentStep) {
            return;
        }

        this.CurrentStep.Move(x, y);
    }

    Unselect() {
        if (!this.CurrentStep) {
            return;
        }

        this.CurrentStep.Unselect();
    }

    get CurrentStep(): PairStep | QueueStep {
        return this._currentStep;
    }

    set CurrentStep(step: PairStep | QueueStep) {
        this._currentStep = step;
    }

    get Finished(): boolean {
        return this.CurrentStep === null;
    }

    BuildSavane(world: any, exterior: any, callback: any) {
        this.photo2savane.BuildSavane(world, exterior, callback);
        this._done = true;
    }

    Draw(context: CanvasRenderingContext2D, zoom: number) {
        if (!this.CurrentStep) {
            return;
        }

        this.CurrentStep.Draw(context, zoom);
    }

    Serialize() {
        var result = {};
        for (var i = 0; i < this._steps.length; ++i) {
            result[this._steps[i].StepType] = this._steps[i].Serialize();
        }
        return {
            steps: result,
            width: this._width,
            height: this._height,
            done: this._done,
            //Savane datas
            VerticalShiftOffset: this.photo2savane.Photo2World.VerticalShiftOffset,
            WallHeight: this.photo2savane.Photo2World.WallHeight,
            WorldHeight: this.photo2savane.Photo2World.WorldHeight,
            OffsetFuX: this.photo2savane.Photo2World.OffsetFuX,
            OffsetFuY: this.photo2savane.Photo2World.OffsetFuY,
            OffsetFvX: this.photo2savane.Photo2World.OffsetFvX,
            OffsetFvY: this.photo2savane.Photo2World.OffsetFvY,
            FocalLengthOffset: this.photo2savane.Photo2World.FocalLengthOffset,
            CeilDraw: this.photo2savane.Photo2World.CeilDraw,
            CeilPartitionDraw: this.photo2savane.Photo2World.CeilPartitionDraw,
            ForceRenormalize: this.photo2savane.Photo2World.ForceRenormalize,
            Version: 1
        }
    }

    Deserialize(datas: DeserializeDatas, photoId: number) {
        var version = datas.Version;
        this._steps = [];
        this._width = datas.width;
        this._height = datas.height;
        this.photo2savane = new SavaneJS.Photo2Savane(this._width, this._height, photoId);
        this._done = datas.done;

        if (datas.WallHeight) {
            this.photo2savane.Photo2World.WallHeight = datas.WallHeight;
        }

        if (datas.WorldHeight) {
            this.photo2savane.Photo2World.WorldHeight = datas.WorldHeight;
        }

        if (datas.CeilDraw) {
            this.photo2savane.Photo2World.CeilDraw = datas.CeilDraw;
        }

        if (datas.CeilPartitionDraw) {
            this.photo2savane.Photo2World.CeilPartitionDraw = datas.CeilPartitionDraw;
        }

        if (version === 1) {
            for (const [key, value] of Object.entries(datas.steps)) {
                var step: Step;
                switch ((value as any).type) {
                    case 'PairStep':
                        step = new PairStep();
                        break;
                    case 'QueueStep':
                        step = new QueueStep();
                        break;
                    default:
                        console.error("Can't be unserialized, this step doesn't exist");
                        //An error occured leave serializations
                        this._initSteps();
                        return;
                }
                if (step)
                    step.Deserialize(value);
                if (step !== null) {
                    this._steps[StepOrder.indexOf(key)] = step;
                }
            }
        } else {
            for (var i = 0; i < datas.steps.length; ++i) {
                var step: Step;
                switch (datas.steps[i].Type) {
                    case 'PairStep':
                        step = new PairStep();
                        break;
                    case 'QueueStep':
                        step = new QueueStep();
                        break;
                    default:
                        console.error("Can't be unserialized, this step doesn't exist");
                        //An error occured leave serializations
                        this._initSteps();
                        return;
                }
                step.Deserialize(datas.steps[i]);

                // convert to version 1
                if (step.StepType === 5) {
                    var slopePartitionStep = new PairStep(StepType.SlopePartition);
                    slopePartitionStep.Done();
                    this._steps[StepOrder.indexOf(StepType.SlopePartition)] = slopePartitionStep;
                }

                switch (step.StepType) {
                    case 0:
                        step.StepType = StepType.Camera;
                        break;
                    case 1:
                        step.StepType = StepType.Outline;
                        break;
                    case 2:
                        step.StepType = StepType.Slope;
                        break;
                    case 3:
                        step.StepType = StepType.Joineries;
                        break;
                    case 4:
                        step.StepType = StepType.OutlinePartition;
                        break;
                    case 5:
                        step.StepType = StepType.JoineriesPartition;
                        break;
                }
                // end convert

                this._steps.push(step);
            }
        }

        for (var i = 0; i < this._steps.length; ++i) {
            if (this._steps[i].IsDone) {
                this.ValidateStep();
            }
        }

        if (datas.VerticalShiftOffset) {
            this.photo2savane.Photo2World.VerticalShiftOffset = datas.VerticalShiftOffset;
        }
        if (datas.OffsetFuX) {
            this.photo2savane.Photo2World.OffsetFuX = datas.OffsetFuX;
        }
        if (datas.OffsetFuY) {
            this.photo2savane.Photo2World.OffsetFuY = datas.OffsetFuY;
        }
        if (datas.OffsetFvX) {
            this.photo2savane.Photo2World.OffsetFvX = datas.OffsetFvX;
        }
        if (datas.OffsetFvY) {
            this.photo2savane.Photo2World.OffsetFvY = datas.OffsetFvY;
        }
        if (datas.FocalLengthOffset) {
            this.photo2savane.Photo2World.FocalLengthOffset = datas.FocalLengthOffset;
        }

        if (datas.ForceRenormalize !== undefined) {
            this.photo2savane.Photo2World.ForceRenormalize = datas.ForceRenormalize;
        }
    }
}
