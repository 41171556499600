import { Marker } from "./Marker";
import * as SavaneJS from "../../node_modules/@rhinov/savane-js";

export interface IMarkerPairDatas {
    a: { x: number; y: number; color: string; };
    b: { x: number; y: number; color: string; };
    value: number;
    color: string;
    isFake: boolean;
    isVisible: boolean;
    wallIndex?: number;
    isPinnedA: boolean;
    isPinnedB: boolean;
    invert: boolean;
    lineMoveMode?: string;
    materialType: number;
    thickness: number;
    frosted: boolean;
    model: string;
    opened: boolean;
    rollingShutter: boolean;
    sliding: boolean;
    wallInstallType: number;
    nbDoors: number;
    transom: boolean;
    transomHeight: number;
    bottomTransom: boolean;
    bottomTransomHeight: number;
    dimensions: number;
    value2: number;
}

export class MarkerPair {
    private _A?: Marker;
    private _B?: Marker;
    private _color: string;
    private _value: number;
    private _value2: number;
    private _isFake: boolean;
    private _isVisible: boolean;
    private _wallIndex: number;
    private _isPinnedA: boolean;
    private _isPinnedB: boolean;
    private _invert: boolean;
    private _dimensions: number;

    private _materialType: number;
    private _thickness: number;
    private _frosted: boolean;
    private _model: string;
    private _opened: boolean;
    private _rollingShutter: boolean;
    private _sliding: boolean;
    private _wallInstallType: number;
    private _nbDoors: number;
    private _transom: boolean;
    private _transomHeight: number;
    private _bottomTransom: boolean;
    private _bottomTransomHeight: number;

    private _initialSelectPosition: SavaneJS.Math.glMatrix.vec2;
    private _rotationCenter: SavaneJS.Math.glMatrix.vec2;
    private _lineMoveMode: string; // T for translation, R for rotation arround rotationCenter
    private _selectMode: string;

    constructor(A?: Marker, B?: Marker) {
        this._A = A;
        this._B = B;
        this._color = 'red';
        this._value;
        this._value2;
        this._isFake = false;
        this._isVisible = true;
        this._wallIndex = 0;
        this._isPinnedA = false;
        this._isPinnedB = false;
        this._invert = false;
        this._dimensions = undefined;

        this._materialType = 0;
        this._thickness = undefined;
        this._frosted = false;
        this._model = undefined;
        this._opened = undefined;
        this._rollingShutter = false;
        this._sliding = false;
        this._wallInstallType = SavaneJS.SceneConstants.WallInstallType.install_inside;
        this._nbDoors = undefined;
        this._transom = false;
        this._transomHeight = 250;
        this._bottomTransom = false;
        this._bottomTransomHeight = 0;

        this._initialSelectPosition = SavaneJS.Math.glMatrix.vec2.create();
        this._rotationCenter = SavaneJS.Math.glMatrix.vec2.create();
        this._lineMoveMode = 'T'; // T for translation, R for rotation arround rotationCenter
    }

    clone() {
        var cloned = new MarkerPair(this._A?.clone(), this._B?.clone());

        cloned._color = this._color;
        cloned._value = this._value;
        cloned._value2 = this._value2;
        cloned._isFake = this._isFake;
        cloned._isVisible = this._isVisible;
        cloned._wallIndex = this._wallIndex;
        cloned._isPinnedA = this._isPinnedA;
        cloned._isPinnedB = this._isPinnedB;
        cloned._invert = this._invert;
        cloned._dimensions = this._dimensions;

        cloned._materialType = this._materialType;
        cloned._thickness = this._thickness;
        cloned._frosted = this._frosted;
        cloned._model = this._model;
        cloned._opened = this._opened;
        cloned._rollingShutter = this._rollingShutter;
        cloned._sliding = this._sliding;
        cloned._wallInstallType = this._wallInstallType;
        cloned._nbDoors = this._nbDoors;
        cloned._transom = this._transom;
        cloned._transomHeight = this._transomHeight;
        cloned._bottomTransom = this._bottomTransom;
        cloned._bottomTransomHeight = this._bottomTransomHeight;

        cloned._initialSelectPosition = this._initialSelectPosition;
        cloned._rotationCenter = this._rotationCenter;
        cloned._lineMoveMode = this._lineMoveMode; // T for translation, R for rotation arround rotationCenter

        return (cloned);
    }

    copyData(markerPair: MarkerPair) {
        if (markerPair._A) {
            this._A?.copyData(markerPair._A);
        }
        if (markerPair._B) {
            this._B?.copyData(markerPair._B);
        }
        this._color = markerPair._color;
        this._value = markerPair._value;
        this._value2 = markerPair._value2;
        this._isFake = markerPair._isFake;
        this._isVisible = markerPair._isVisible;
        this._wallIndex = markerPair._wallIndex;
        this._isPinnedA = markerPair._isPinnedA
        this._isPinnedB = markerPair._isPinnedB
        this._invert = markerPair._invert
        this._dimensions = markerPair._dimensions

        this._materialType = markerPair._materialType
        this._thickness = markerPair._thickness
        this._frosted = markerPair._frosted;
        this._model = markerPair._model;
        this._opened = markerPair._opened;
        this._rollingShutter = markerPair._rollingShutter;
        this._sliding = markerPair._sliding;
        this._wallInstallType = markerPair._wallInstallType;
        this._nbDoors = markerPair._nbDoors;
        this._transom = markerPair._transom;
        this._transomHeight = markerPair._transomHeight;
        this._bottomTransom = markerPair._bottomTransom;
        this._bottomTransomHeight = markerPair._bottomTransomHeight;

        SavaneJS.Math.glMatrix.vec2.copy(this._initialSelectPosition, markerPair._initialSelectPosition);
        SavaneJS.Math.glMatrix.vec2.copy(this._rotationCenter, markerPair._rotationCenter);
        this._lineMoveMode = markerPair._lineMoveMode;
    }

    get Color(): string {
        return this._color;
    }

    get A(): Marker {
        return this._A;
    }

    set A(value) {
        this._A = value;
    }

    get IsPinnedA(): boolean {
        return this._isPinnedA;
    }

    set IsPinnedA(value) {
        this._isPinnedA = value;
        if (value) {
            this._isPinnedB = false;
        }
    }

    get B(): Marker {
        return this._B;
    }

    set B(value) {
        this._B = value;
    }

    get IsFake(): boolean {
        return this._isFake;
    }

    get IsPinnedB(): boolean {
        return this._isPinnedB;
    }

    set IsPinnedB(value) {
        this._isPinnedB = value;
        if (value) {
            this._isPinnedA = false;
        }
    }

    get Invert(): boolean {
        return this._invert;
    }

    set Invert(value) {
        this._invert = value;
    }

    get Value(): number {
        return this._value;
    }

    set Value(value) {
        this._value = value;
    }

    get Value2(): number {
        return this._value2;
    }

    set Value2(value) {
        this._value2 = value;
    }

    get IsVisible(): boolean {
        return this._isVisible;
    }

    set IsVisible(value) {
        this._isVisible = value;
    }

    get WallIndex(): number {
        return this._wallIndex;
    }

    set WallIndex(value) {
        this._wallIndex = value;
    }

    get MaterialType(): number {
        return this._materialType;
    }

    set MaterialType(value) {
        this._materialType = value;
    }

    get Thickness(): number {
        return this._thickness;
    }

    set Thickness(value) {
        this._thickness = value;
    }

    get Frosted(): boolean {
        return this._frosted;
    }

    set Frosted(value) {
        this._frosted = value;
    }

    get Model(): string {
        return this._model;
    }

    set Model(value) {
        this._model = value;
    }

    get Opened(): boolean {
        return this._opened;
    }

    set Opened(value) {
        this._opened = value;
    }

    get RollingShutter(): boolean {
        return this._rollingShutter;
    }

    set RollingShutter(value) {
        this._rollingShutter = value;
    }

    get Sliding(): boolean {
        return this._sliding;
    }

    set Sliding(value) {
        this._sliding = value;
    }

    get WallInstallType(): number {
        return this._wallInstallType;
    }

    set WallInstallType(value) {
        this._wallInstallType = value;
    }

    get NbDoors(): number {
        return this._nbDoors;
    }

    set NbDoors(value) {
        this._nbDoors = value;
    }

    get Transom(): boolean {
        return this._transom;
    }

    set Transom(value) {
        this._transom = value;
    }

    get TransomHeight(): number {
        return this._transomHeight;
    }

    set TransomHeight(value) {
        this._transomHeight = value;
    }

    get BottomTransom(): boolean {
        return this._bottomTransom;
    }

    set BottomTransom(value) {
        this._bottomTransom = value;
    }

    get BottomTransomHeight(): number {
        return this._bottomTransomHeight;
    }

    set BottomTransomHeight(value) {
        this._bottomTransomHeight = value;
    }

    get Dimensions(): number {
        return this._dimensions;
    }

    set Dimensions(value) {
        this._dimensions = value;
    }

    SetColor(line: string) {
        this._color = line;
    }

    SetMarkerColor(value: string) {
        if (!this._A || !this._B) {
            throw new Error("Markers A or B are undefined");
        }
        this._A.Color = value;
        this._B.Color = value;
    }

    get SelectMode(): string {
        return this._selectMode;
    }

    set SelectMode(value) {
        this._selectMode = value;
    }

    set RotationCenter(value: SavaneJS.math.vec2) {
        this._rotationCenter = value;
    }

    set LineMoveMode(value: string) {
        this._lineMoveMode = value;
    }

    Draw(context: CanvasRenderingContext2D, zoom: number, active: boolean) {
        this._A?.Draw(context, zoom, active && this._selectMode === 'A', this.IsPinnedA);
        this._B?.Draw(context, zoom, active && this._selectMode === 'B', this.IsPinnedB);
        context.beginPath();
        context.strokeStyle = active && this._selectMode === 'LINE' ? 'blue' : this._color;
        context.lineWidth = 1 / zoom;
        context.moveTo(this._A?.X, this._A?.Y);
        context.lineTo(this._B?.X, this._B?.Y);
        context.stroke();
    }

    _translate(x: number, y: number) {
        if (!this._A || !this._B) {
            throw new Error("Markers A or B are undefined");
        }
        this._A.Move(this._A.X + (x - this._initialSelectPosition[0]), this._A.Y + (y - this._initialSelectPosition[1]));
        this._B.Move(this._B.X + (x - this._initialSelectPosition[0]), this._B.Y + (y - this._initialSelectPosition[1]));
    }

    _rotate(x: number, y: number) {
        if (!isFinite(this._rotationCenter[0]) || !isFinite(this._rotationCenter[1])) {
            this._translate(x, y);
            return;
        }

        if (!this._A || !this._B) {
            throw new Error("Markers A or B are undefined");
        }

        var CP = SavaneJS.Math.glMatrix.vec3.fromValues(x - this._rotationCenter[0], y - this._rotationCenter[1], 0);
        SavaneJS.Math.glMatrix.vec3.normalize(CP, CP);
        var CA = SavaneJS.Math.glMatrix.vec3.fromValues(this._A.X - this._rotationCenter[0], this._A.Y - this._rotationCenter[1], 0);
        SavaneJS.Math.glMatrix.vec3.normalize(CA, CA);
        var angle = -SavaneJS.Math.Functions.OrientedAngle(CP, CA);

        var s = Math.sin(angle);
        var c = Math.cos(angle);

        var distance = SavaneJS.Math.glMatrix.vec2.distance(SavaneJS.Math.glMatrix.vec2.fromValues(x, y), this._rotationCenter) - SavaneJS.Math.glMatrix.vec2.distance(this._initialSelectPosition, this._rotationCenter);

        this._A.X -= this._rotationCenter[0];
        this._A.Y -= this._rotationCenter[1];
        this._A.X = this._A.X * c - this._A.Y * s;
        this._A.Y = this._A.X * s + this._A.Y * c;
        this._A.X += this._rotationCenter[0] + CP[0] * distance;
        this._A.Y += this._rotationCenter[1] + CP[1] * distance;

        this._B.X -= this._rotationCenter[0];
        this._B.Y -= this._rotationCenter[1];
        this._B.X = this._B.X * c - this._B.Y * s;
        this._B.Y = this._B.X * s + this._B.Y * c;
        this._B.X += this._rotationCenter[0] + CP[0] * distance;
        this._B.Y += this._rotationCenter[1] + CP[1] * distance;
    }

    Move(x: number, y: number) {
        switch (this._selectMode) {
            case 'A':
                this._A?.Move(x, y);
                break;
            case 'B':
                this._B?.Move(x, y);
                break;
            case 'LINE':
                if (this._lineMoveMode === 'T') {
                    this._translate(x, y);
                } else if (this._lineMoveMode === 'R') {
                    this._rotate(x, y);
                }
                break;
        }
        this._initialSelectPosition = SavaneJS.Math.glMatrix.vec2.fromValues(x, y);
    }

    IsSelectable(x: number, y: number, zoom: number) {
        if (!this._A || !this._B) {
            throw new Error("Markers A or B are undefined");
        }
        var line = new SavaneJS.Math.Line(SavaneJS.Math.glMatrix.vec3.fromValues(this._A.X, this._A.Y, 0), SavaneJS.Math.glMatrix.vec3.fromValues(this._B.X, this._B.Y, 0));
        var V = SavaneJS.Math.glMatrix.vec3.fromValues(x, y, 0);
        var P = line.Project(V);
        var VP = SavaneJS.Math.glMatrix.vec3.create();
        SavaneJS.Math.glMatrix.vec3.subtract(VP, P, V);
        var lineDistance = SavaneJS.Math.glMatrix.vec3.length(VP);
        this._selectMode = null;
        if (this._A?.IsSelectable(x, y, zoom)) {
            this._selectMode = 'A';
        }

        if (this._B?.IsSelectable(x, y, zoom)) {
            this._selectMode = 'B';
        }

        if (!this._selectMode && line.BelongToSegment(P) && lineDistance < 10) {
            this._selectMode = 'LINE';
        }
        this._initialSelectPosition = SavaneJS.Math.glMatrix.vec2.fromValues(x, y);
        return this._selectMode !== null;
    }

    Distance(x: number, y: number) {
        if (!this._A || !this._B) {
            throw new Error("Markers A or B are undefined");
        }
        var line = new SavaneJS.Math.Line(SavaneJS.Math.glMatrix.vec3.fromValues(this._A.X, this._A.Y, 0), SavaneJS.Math.glMatrix.vec3.fromValues(this._B.X, this._B.Y, 0));
        var V = SavaneJS.Math.glMatrix.vec3.fromValues(x, y, 0);
        var P = line.Project(V);
        var VP = SavaneJS.Math.glMatrix.vec3.create();
        SavaneJS.Math.glMatrix.vec3.subtract(VP, P, V);
        var lineDistance = SavaneJS.Math.glMatrix.vec3.length(VP);
        return Math.min(lineDistance, Math.min(this._A.Distance(x, y), this._B.Distance(x, y)));
    }

    Serialize() {
        var datas: IMarkerPairDatas = {
            a: this._A?.Serialize(),
            b: this._B?.Serialize(),
            value: this._value,
            color: this._color,
            isFake: this._isFake,
            isVisible: this._isVisible,
            wallIndex: this._wallIndex,
            isPinnedA: this._isPinnedA,
            isPinnedB: this._isPinnedB,
            invert: this._invert,
            lineMoveMode: this._lineMoveMode,
            materialType: this._materialType,
            thickness: this._thickness,
            frosted: this._frosted,
            model: this._model,
            opened: this._opened,
            rollingShutter: this._rollingShutter,
            sliding: this._sliding,
            wallInstallType: this._wallInstallType,
            nbDoors: this._nbDoors,
            transom: this._transom,
            transomHeight: this._transomHeight,
            bottomTransom: this._bottomTransom,
            bottomTransomHeight: this._bottomTransomHeight,
            dimensions: undefined,
            value2: this._value2
        };

        if (this._materialType !== undefined) {
            datas.materialType = this._materialType;
        }
        if (this._thickness !== undefined) {
            datas.thickness = this._thickness;
        }
        if (this._frosted !== undefined) {
            datas.frosted = this._frosted;
        }
        if (this._model !== undefined) {
            datas.model = this._model;
        }
        if (this._opened !== undefined) {
            datas.opened = this._opened;
        }
        if (this._rollingShutter !== undefined) {
            datas.rollingShutter = this._rollingShutter;
        }
        if (this._sliding !== undefined) {
            datas.sliding = this._sliding;
        }
        if (this._wallInstallType !== undefined) {
            datas.wallInstallType = this._wallInstallType;
        }
        if (this._nbDoors !== undefined) {
            datas.nbDoors = this._nbDoors;
        }
        if (this._transom !== undefined) {
            datas.transom = this._transom;
        }
        if (this._transomHeight !== undefined) {
            datas.transomHeight = this._transomHeight;
        }
        if (this._bottomTransom !== undefined) {
            datas.bottomTransom = this._bottomTransom;
        }
        if (this._bottomTransomHeight !== undefined) {
            datas.bottomTransomHeight = this._bottomTransomHeight;
        }
        if (this._dimensions !== undefined) {
            datas.dimensions = this._dimensions;
        }
        if (this._value2 !== undefined) {
            datas.value2 = this._value2;
        }

        return datas;
    }

    Deserialize(datas: IMarkerPairDatas) {
        this._A = new Marker();
        this._A.Deserialize(datas.a);
        this._B = new Marker();
        this._B.Deserialize(datas.b);
        this.Value = datas.value !== undefined ? datas.value : 0;
        this._color = datas.color;
        this._isFake = datas.isFake;
        this._isVisible = datas.isVisible;
        this._isPinnedA = datas.isPinnedA;
        this._isPinnedB = datas.isPinnedB;
        this._invert = datas.invert;
        if (datas.wallIndex !== undefined) {
            this._wallIndex = datas.wallIndex;
        }
        if (datas.materialType !== undefined) {
            this._materialType = datas.materialType;
        }
        if (datas.thickness !== undefined) {
            this._thickness = datas.thickness;
        }
        if (datas.frosted !== undefined) {
            this._frosted = datas.frosted;
        }
        if (datas.model !== undefined) {
            this._model = datas.model;
        }
        if (datas.opened !== undefined) {
            this._opened = datas.opened;
        }
        if (datas.rollingShutter !== undefined) {
            this._rollingShutter = datas.rollingShutter;
        }
        if (datas.sliding !== undefined) {
            this._sliding = datas.sliding;
        }
        if (datas.wallInstallType !== undefined) {
            this._wallInstallType = datas.wallInstallType;
        }
        if (datas.nbDoors !== undefined) {
            this._nbDoors = datas.nbDoors;
        }
        if (datas.transom !== undefined) {
            this._transom = datas.transom;
        }
        if (datas.transomHeight !== undefined) {
            this._transomHeight = datas.transomHeight;
        }
        if (datas.bottomTransom !== undefined) {
            this._bottomTransom = datas.bottomTransom;
        }
        if (datas.bottomTransomHeight !== undefined) {
            this._bottomTransomHeight = datas.bottomTransomHeight;
        }
        if (datas.dimensions !== undefined) {
            this._dimensions = datas.dimensions;
        }
        if (datas.lineMoveMode !== undefined) {
            this._lineMoveMode = datas.lineMoveMode;
        }
        if (datas.value2 !== undefined) {
            this._value2 = datas.value2;
        }
    }
}
