import { Photo2SavaneController } from "../Photo2SavaneController";

export class ModelSidebarWidget {
    photo2savanecontroller: Photo2SavaneController;
    constructor(controller: Photo2SavaneController) {
        this.photo2savanecontroller = controller;
    }

    static instance?: ModelSidebarWidget;
    static instantiate(controller: Photo2SavaneController) {
        if (!ModelSidebarWidget.instance) {
            ModelSidebarWidget.instance = new ModelSidebarWidget(controller);
        }
    }

    html() {
        return `
            <div id="ModelSidebar" class="sidenav" style="height: calc(100% - 65px);">
                ${this.photo2savanecontroller.isTabShown() ? this.getSidebarContentHtml() : ''}
            </div>
        `;
    }

    bindEvents() {
        let eventMappings = [
            { id: 'closeBtn', event: 'click', handler: 'closeBtnOnClick' },
            { id: 'radio-right', event: 'click', handler: 'radioRightOnClick' },
            { id: 'radio-middle', event: 'click', handler: 'radioMiddleOnClick' },
            { id: 'radio-left', event: 'click', handler: 'radioLeftOnClick' },
            { id: 'inputShowFrosted', event: 'click', handler: 'inputShowFrostedOnClick' },
            { id: 'inputShowOpened', event: 'click', handler: 'inputShowOpenedOnClick' },
            { id: 'inputShowSliding', event: 'click', handler: 'inputShowSlidingOnClick' },
            { id: 'inputShowRollingShutter', event: 'click', handler: 'inputShowRollingShutterOnClick' },
            { id: 'inputShowTransom', event: 'click', handler: 'inputShowTransomOnClick' },
            { id: 'inputShowBottomTransom', event: 'click', handler: 'inputShowBottomTransomOnClick' },
            { id: 'inputShowNbDoors', event: 'input', handler: 'inputShowNbDoorsOnChange' },
            { id: 'inputShowTransomMaxHeight', event: 'input', handler: 'inputShowTransomMaxHeightOnChange' },
            { id: 'inputShowBottomTransomMinHeight', event: 'input', handler: 'inputShowBottomTransomMinHeightOnChange' },
            { id: 'inputShowThickness', event: 'input', handler: 'inputShowThicknessOnChange' }
        ];

        eventMappings.forEach(({ id, event, handler }) => {
            let element = document.getElementById(id);
            if (!element) return;
            element.addEventListener(event, this[handler].bind(this));
        });

        setTimeout(() => {
            this.updateShowTransomDynamicHeight();
            if (this.photo2savanecontroller.showProperty('showBottomTransom')) {
                this.updateShowBottomTransomDynamicHeight();
            }
        }, 100);
    }

    getSidebarContentHtml() {
        setTimeout(() => {
            this.bindEvents();
        }, 100);
        return `
            <div id="isTabShown">
                <a id="closeBtn" class="closebtn">&times;</a>
                ${this.getMaterialsSectionHtml()}
                ${this.getFrostedSectionHtml()}
                ${this.getNbDoorsSectionHtml()}
                ${this.getOpenedSectionHtml()}
                ${this.getShowSlidingHtml()}
                ${this.getRollingShutterSectionHtml()}
                ${this.getShowTransomHtml()}
                ${this.getShowBottomTransomHtml()}
                ${this.photo2savanecontroller.showProperty('showInstallType') ? `
                <div id="showInstallType" class="padding_toggle">
                    ${this.getShowInstallTypeHtml()}
                </div>
                ` : ''}
                ${this.getShowThicknessHtml()}
                ${this.getPreviewSectionHtml()}
                ${this.getModelSelectionHtml()}
            </div>
        `;
    }

    getMaterialsSectionHtml() {
        return this.photo2savanecontroller.showProperty('showMaterials') ? `
            <div id="showMaterials" class="padding">
                <label>Type de matériaux</label>
                <select id="propertyMaterialType" class="form-control" placeholder="Selectionner">
                    ${this.updateMaterialDropdown()}
                </select>
            </div>
        ` : ''
    }

    getFrostedSectionHtml() {
        return this.photo2savanecontroller.showProperty('showFrosted') ? `
            <div id="showFrosted" class="padding_toggle">
                <div class="form togglebutton">
                    <label class="horizontal-toggle">
                        <div style="padding-right: 10px;float:left">Dépoli</div>
                        <input id="inputShowFrosted" type="checkbox" class="togglebutton"
                        ${this.photo2savanecontroller.joineryObject.currentElementValueFrosted ? 'checked' : ''}>
                        <span style="float:right" class="toggle"></span>
                    </label>
                </div>
            </div>
    ` : ''
    }

    getNbDoorsSectionHtml() {
        return this.photo2savanecontroller.showProperty('showNbDoors') ? `
            <div id="showNbDoors" class="padding_toggle">
                <label>Battant(s)/Vitrage(s)</label>
                <input id="inputShowNbDoors" class="form-control" type="number"
                 value="${this.photo2savanecontroller.joineryObject.currentElementValueNbDoors || ''}">
            </div>
    ` : ''
    }

    getOpenedSectionHtml() {
        return this.photo2savanecontroller.showProperty('showOpened') ? `
            <div id="showOpened" class="padding_toggle">
                <div class="form togglebutton">
                    <label class="horizontal-toggle">
                        <div style="padding-right: 10px;float:left">Ouvert</div>
                        <input id="inputShowOpened" type="checkbox" class="togglebutton"
                        ${this.photo2savanecontroller.joineryObject.currentElementValueOpened ? 'checked' : ''}>
                        <span style="float:right" class="toggle"></span>
                    </label>
                </div>
            </div>
    ` : ''
    }

    getShowSlidingHtml() {
        return this.photo2savanecontroller.showProperty('showSliding') ? `
            <div id="showSliding" class="padding_toggle">
                <div class="form togglebutton">
                    <label class="horizontal-toggle">
                        <div style="padding-right: 10px;float:left">Coulissant</div>
                        <input id="inputShowSliding" type="checkbox" class="togglebutton"
                        ${this.photo2savanecontroller.joineryObject.currentElementValueSliding ? 'checked' : ''}>
                        <span style="float:right" class="toggle"></span>
                    </label>
                </div>
            </div>
    ` : ''
    }

    getRollingShutterSectionHtml() {
        return this.photo2savanecontroller.showProperty('showRollingShutter') ? `
            <div id="showRollingShutter" class="padding_toggle">
                <div class="form togglebutton">
                    <label class="horizontal-toggle">
                        <div style="padding-right: 10px;float:left">Volets roulants</div>
                        <input id="inputShowRollingShutter" type="checkbox" class="togglebutton"
                        ${this.photo2savanecontroller.joineryObject.currentElementValueRollingShutter ? 'checked' : ''}>
                        <span style="float:right" class="toggle"></span>
                    </label>
                </div>
            </div>
    ` : ''
    }

    getShowTransomHtml() {
        let isChecked = this.photo2savanecontroller.joineryObject.currentElementValueTransom;

        return this.photo2savanecontroller.showProperty('showTransom') ? `
            <div id="showTransom" class="padding_toggle">
                <div class="form togglebutton">
                    <label class="horizontal-toggle">
                        <div style="padding-right: 10px;float:left">Imposte haut</div>
                        <input id="inputShowTransom" type="checkbox" class="togglebutton"
                        ${isChecked ? 'checked' : ''}>
                        <span style="float:right" class="toggle"></span>
                    </label>
                </div>
            </div>
            <div id="showTransomDynamicHeight">
                ${this.getShowTransomDynamicHeightHtml()}
            </div>
        ` : ''
    }

    updateShowTransomDynamicHeight() {
        let showTransomDynamicHeight = document.getElementById("showTransomDynamicHeight");
        if (!showTransomDynamicHeight) return;

        let isChecked = this.photo2savanecontroller.joineryObject.currentElementValueTransom;
        showTransomDynamicHeight.innerHTML = isChecked ? this.getShowTransomDynamicHeightHtml() : "";

        if (!isChecked) return;
        setTimeout(() => {
            let method = this.inputShowTransomMaxHeightOnChange.bind(this);
            this.photo2savanecontroller.bindTransomMaxHeightEvent(method);
        }, 10);
    }

    getShowTransomDynamicHeightHtml() {
        let heightValue = this.photo2savanecontroller.joineryObject.currentElementValueTransomHeight || '';
        return this.photo2savanecontroller.joineryObject.currentElementValueTransom ? `
            <div id="showTransomMaxHeight" class="padding_toggle">
                <label>Hauteur max</label>
                <input id="inputShowTransomMaxHeight" class="form-control" type="number"
                value="${heightValue}">
            </div>
        ` : ''
    }

    getShowBottomTransomHtml() {
        return this.photo2savanecontroller.showProperty('showBottomTransom') ? `
            <div id="showBottomTransom" class="padding_toggle">
                <div class="form togglebutton">
                    <label class="horizontal-toggle">
                        <div style="padding-right: 10px;float:left">Imposte bas</div>
                        <input id="inputShowBottomTransom" type="checkbox" class="togglebutton"
                        ${this.photo2savanecontroller.joineryObject.currentElementValueBottomTransom ? 'checked' : ''}>
                        <span style="float:right" class="toggle"></span>
                    </label>
                </div>
            </div>
            <div id="showBottomTransomDynamicHeight">
                ${this.getShowBottomTransomDynamicHeightHtml()}
            </div>
        ` : ''
    }

    updateShowBottomTransomDynamicHeight() {
        let showBottomTransomDynamicHeight = document.getElementById("showBottomTransomDynamicHeight");
        if (!showBottomTransomDynamicHeight) return;

        let isChecked = this.photo2savanecontroller.joineryObject.currentElementValueBottomTransom;
        showBottomTransomDynamicHeight.innerHTML = isChecked ? this.getShowBottomTransomDynamicHeightHtml() : "";

        if (!isChecked) return;
        setTimeout(() => {
            let method = this.inputShowBottomTransomMinHeightOnChange.bind(this);
            this.photo2savanecontroller.bindShowBottomTransomMinHeightEvent(method);
        }, 10);
    }

    getShowBottomTransomDynamicHeightHtml() {
        let heightValue = this.photo2savanecontroller.joineryObject.currentElementValueBottomTransomHeight || '0';
        return this.photo2savanecontroller.joineryObject.currentElementValueBottomTransom ? `
            <div id="showBottomTransomMinHeight" class="padding_toggle">
                <label>Hauteur min</label>
                <input id="inputShowBottomTransomMinHeight" class="form-control" type="number"
                value="${heightValue}">
            </div>
        ` : ''
    }

    updateShowInstallType() {
        let showInstallType = document.getElementById("showInstallType");
        if (!showInstallType) return;

        showInstallType.innerHTML = this.getShowInstallTypeHtml();

        setTimeout(() => {
            this.bindEvents();
        }, 100);
    }

    getShowInstallTypeHtml() {
        return `
            <label style="color: #333;">Nu Ext./Centre/Int.</label>
            <div class="form triple-toggle" style="height:28px">
                <div class="triple-toggle-radios" style="height:15px;margin-top:5px;">
                    <input type="radio" class="left" id="radio-left"
                    ${this.photo2savanecontroller.joineryObject.currentElementValueWallInstallType === -1 ? 'checked' : ''}>
                    <label for="radio-left"></label>
                    <input type="radio" class="middle" id="radio-middle"
                    ${this.photo2savanecontroller.joineryObject.currentElementValueWallInstallType === 0 ? 'checked' : ''}>
                    <label for="radio-middle"></label>
                    <input type="radio" class="right" id="radio-right"
                    ${this.photo2savanecontroller.joineryObject.currentElementValueWallInstallType === 1 ? 'checked' : ''}>
                    <label for="radio-right"></label>
                </div>
            </div>
        `
    }

    getShowThicknessHtml() {
        return this.photo2savanecontroller.showProperty('showThickness') ? `
            <div id="showThickness" class="padding_toggle">
                <label>Profondeur</label>
                <input id="inputShowThickness" class="form-control" type="number"
                value="${this.photo2savanecontroller.joineryObject.currentElementValueThickness || ''}">
            </div>
    ` : ''
    }

    getPreviewSectionHtml() {
        return `
            <div class="padding">
                <h style="font-weight:600;">Aperçu</h>
                <div>
                    <img id="itemPreview" style="width:120px;height:120px;" 
                        src="${this.photo2savanecontroller.itemPreview}"/>
                </div>
            </div>
        `;
    }

    getModelSelectionHtml() {
        return `
            <div class="padding">
                <h style="font-weight:600;">Sélectionnez le modèle 3D</h>
                <div id="model-images-container" style="margin-bottom:10px;padding:0;width:auto;height:380px;overflow-y:auto;">
                    ${this.updateModelImages()}
                </div>
            </div>
        `;
    }

    updateModelSidebar() {
        let container = document.getElementById("ModelSidebar");
        if (!container) return;
        if (!this.photo2savanecontroller.isTabShown()) return;
        container.innerHTML = this.getSidebarContentHtml();

        this.updateShowTransomDynamicHeight();
        this.updateShowBottomTransomDynamicHeight();
    }

    closeBtnOnClick() {
        this.photo2savanecontroller.closeSideBar();
    }

    inputShowFrostedOnClick() {
        let inputShowFrosted = document.getElementById("inputShowFrosted") as HTMLInputElement;
        if (inputShowFrosted) {
            this.photo2savanecontroller.updateJoineryFrosted(inputShowFrosted.checked);
        }
    }

    inputShowOpenedOnClick() {
        let inputShowOpened = document.getElementById("inputShowOpened") as HTMLInputElement;
        if (inputShowOpened) {
            this.photo2savanecontroller.updateJoineryOpened(inputShowOpened.checked);
        }
    }

    inputShowSlidingOnClick() {
        let inputShowSliding = document.getElementById("inputShowSliding") as HTMLInputElement;
        if (inputShowSliding) {
            this.photo2savanecontroller.updateJoinerySliding(inputShowSliding.checked);
        }
        this.updateItemPreview();
    }

    inputShowRollingShutterOnClick() {
        let inputShowRollingShutter = document.getElementById("inputShowRollingShutter") as HTMLInputElement;
        if (inputShowRollingShutter) {
            this.photo2savanecontroller.updateJoineryRollingShutter(inputShowRollingShutter.checked);
        }
    }

    inputShowTransomOnClick() {
        let checkbox = document.getElementById("inputShowTransom");
        if (!checkbox) return;

        let isChecked = (checkbox as HTMLInputElement).checked;
        this.photo2savanecontroller.joineryObject.currentElementValueTransom = isChecked;
        this.photo2savanecontroller.updateJoineryTransom(isChecked);

        this.updateShowTransomDynamicHeight();
    }

    inputShowBottomTransomOnClick() {
        let checkbox = document.getElementById("inputShowBottomTransom");
        if (!checkbox) return;

        let isChecked = (checkbox as HTMLInputElement).checked;
        this.photo2savanecontroller.joineryObject.currentElementValueBottomTransom = isChecked;
        this.photo2savanecontroller.updateJoineryBottomTransom(isChecked);

        this.updateShowBottomTransomDynamicHeight();
    }

    radioLeftOnClick() {
        this.photo2savanecontroller.updateJoineryWallInstallType(-1);
        this.updateShowInstallType();
    }

    radioMiddleOnClick() {
        this.photo2savanecontroller.updateJoineryWallInstallType(0);
        this.updateShowInstallType();
    }

    radioRightOnClick() {
        this.photo2savanecontroller.updateJoineryWallInstallType(1);
        this.updateShowInstallType();
    }

    updateItemPreview() {
        let img = document.getElementById("itemPreview") as HTMLImageElement;
        if (!img) return;
        img.src = this.photo2savanecontroller.itemPreview;
    }

    modelImageOnClick(index: number) {
        this.photo2savanecontroller.updateJoineryModeleType(this.photo2savanecontroller.allModelsForCurrentJoinery[index]);
        this.updateItemPreview();
    }

    propertyMaterialTypeOnChange(value: string) {
        this.photo2savanecontroller.joineryObject.currentElementValueMaterial = value;
        this.photo2savanecontroller.updateJoineryMaterialType(this.photo2savanecontroller.joineryObject.currentElementValueMaterial);
    }

    inputShowNbDoorsOnChange() {
        let inputElement = document.getElementById("inputShowNbDoors");
        if (!inputElement) return;
        let value = (inputElement as HTMLInputElement).valueAsNumber;

        if (isNaN(value) || value < 1) {
            value = 1;
            (inputElement as HTMLInputElement).value = '1';
        }
        this.photo2savanecontroller.updateJoineryNbDoors(value);
    }

    inputShowTransomMaxHeightOnChange() {
        let inputElement = document.getElementById("inputShowTransomMaxHeight");
        if (!inputElement) return;

        let value = (inputElement as HTMLInputElement).valueAsNumber;
        if (isNaN(value)) {
            value = 0;
        }

        this.photo2savanecontroller.joineryObject.currentElementValueTransomHeight = value;
        this.photo2savanecontroller.updateJoineryTransomHeight(value);
    }

    inputShowBottomTransomMinHeightOnChange() {
        let inputElement = document.getElementById("inputShowBottomTransomMinHeight");
        if (!inputElement) return;

        let value = (inputElement as HTMLInputElement).valueAsNumber;
        if (isNaN(value)) {
            value = 0;
        }

        this.photo2savanecontroller.joineryObject.currentElementValueBottomTransomHeight = value;
        this.photo2savanecontroller.updateJoineryBottomTransomHeight(value);
    }

    inputShowThicknessOnChange() {
        let inputElement = document.getElementById("inputShowThickness");
        if (!inputElement) return;
        let value = (inputElement as HTMLInputElement).valueAsNumber;

        if (isNaN(value)) {
            value = 0;
        }
        this.photo2savanecontroller.updateJoineryThickness(value);
    }

    updateMaterialDropdown() {
        let htmlContent = "";
        let options = this.photo2savanecontroller.getDropdownData();
        if (!options) return;

        htmlContent = options.map(option => {
            return `
                <option value="${option.value}" ${String(option.value) === this.photo2savanecontroller.joineryObject.currentElementValueMaterial ? "selected" : ""}>
                    ${option.name}
                </option>`;
        }).join("");

        setTimeout(() => {
            this.photo2savanecontroller.bindMaterialTypeChangeEvent();
        }, 100);

        return htmlContent;
    }

    updateModelImages() {
        let htmlContent = "";

        if (this.photo2savanecontroller.allModelsForCurrentJoinery !== undefined) {
            htmlContent = this.photo2savanecontroller.allModelsForCurrentJoinery.map((modele: { _id: string; }, index: number) => {
                return `
                <img id="model-image-${index}" style="display: inline-block; width: 120px; height: 120px; margin-right: 2px;"
                    src="${ModelSidebarWidget.instance?.photo2savanecontroller.getJoineryPreviewUrl(modele)}"/>
                `;
            }).join("");

            setTimeout(() => {
                this.photo2savanecontroller.bindModelImageEvents();
            }, 100);
        }

        return htmlContent;
    }
}

