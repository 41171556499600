import * as SavaneJS from "@rhinov/savane-js";
import * as THREE from 'three';

export class WallFactory {

    static Build(walls: string | any[], material: THREE.MeshBasicMaterial, wireframeMaterial: THREE.LineBasicMaterial, photo2world, context2d: CanvasRenderingContext2D, zoom: number) {
        if (walls.length === 0) {
            return null;
        }

        var vertices = new Float32Array(6 * 3 * walls.length);

        var index = 0;
        for (var i = 0; i < walls.length; ++i) {
            var wall = walls[i];
            var v1 = SavaneJS.Math.glMatrix.vec3.clone(wall.A);
            var v2 = SavaneJS.Math.glMatrix.vec3.clone(wall.A);
            v2[2] = wall.Height;
            var v4 = SavaneJS.Math.glMatrix.vec3.clone(wall.B);
            var v3 = SavaneJS.Math.glMatrix.vec3.clone(wall.B);
            v3[2] = wall.Height;

            vertices[index++] = v1[0]; vertices[index++] = v1[1]; vertices[index++] = v1[2];
            vertices[index++] = v3[0]; vertices[index++] = v3[1]; vertices[index++] = v3[2];
            vertices[index++] = v2[0]; vertices[index++] = v2[1]; vertices[index++] = v2[2];

            vertices[index++] = v3[0]; vertices[index++] = v3[1]; vertices[index++] = v3[2];
            vertices[index++] = v1[0]; vertices[index++] = v1[1]; vertices[index++] = v1[2];
            vertices[index++] = v4[0]; vertices[index++] = v4[1]; vertices[index++] = v4[2];

            var v1v4 = SavaneJS.Math.glMatrix.vec3.create();
            SavaneJS.Math.glMatrix.vec3.subtract(v1v4, v4, v1);
            var position2d = photo2world.Project(SavaneJS.Math.glMatrix.vec3.fromValues((v1[0] + v4[0]) / 2, (v1[1] + v4[1]) / 2, (v1[2] + v4[2]) / 2));
            context2d.save();
            context2d.fillStyle = "red";
            context2d.textAlign = "center";
            var fontSize = 14 / zoom;
            context2d.font = fontSize + "px Arial";
            context2d.fillText((SavaneJS.Math.glMatrix.vec3.length(v1v4) / 10).toFixed(0), position2d[0], position2d[1] + 16 / zoom);
            context2d.restore();
        }

        var geometry = new THREE.BufferGeometry();
        geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
        var object = new THREE.Mesh(geometry, material);

        var wireframeGeometry = new THREE.WireframeGeometry(geometry);
        var wireframe = new THREE.LineSegments(wireframeGeometry, wireframeMaterial);

        return {
            object: object,
            wireframe: wireframe
        }
    }

}
